import React, { useState } from "react";
import Logo from "../../../assets/Logo.svg";
import { Box, Button } from "@mui/material";
import { useNavigate } from "react-router";
import useAuthenticate from "../../../hooks/useAuthenticate";
import Navbar from "../../../components/Navbar";
import { ADMIN_STORAGE_KEY } from "../../../helpers/variables";

const AdminSignIn = () => {
  const navigate = useNavigate();
  const { adminLogin } = useAuthenticate();

  const [details, setDetails] = useState({
    email: "",
    password: "",
  });
  const changeHandler = (e, type) => {
    if (type) setDetails({ ...details, userType: type });
    else {
      const { name, value } = e.target;
      setDetails({ ...details, [name]: value });
    }
  };

  const submitHandler = async () => {
    const formValid = validateForm();
    if (!formValid) return alert("Please fill the form correctly!");

    try {
      const response = await adminLogin(details);
      if (response) {
        localStorage.setItem(ADMIN_STORAGE_KEY, JSON.stringify(response));
        navigate("/admin/dashboard");
      }
    } catch (err) {
      console.log(err);
    }
  };

  const validateForm = () => {
    for (const prop in details) {
      if (!details[prop]) return false;
    }
    return true;
  };

  console.log(details.userType);
  return (
    <>
      <Navbar />
      <div className='signInContainer'>
        <div className='signInContainer__header'>
          <Box className='signInContainer__header__logo'>
            <hr />
            <img src={Logo} alt='' />
          </Box>
        </div>
        <div className='signInContainer__fields'>
          <input
            type='email'
            placeholder='Username or email'
            name='email'
            className='signInContainer__fields__input'
            onChange={changeHandler}
          />
          <input
            type='password'
            name='password'
            placeholder='Password'
            className='signInContainer__fields__input'
            onChange={changeHandler}
          />
          <Button onClick={submitHandler}>Sign In</Button>
        </div>

        {/* <div className='signInContainer__note'>
          <p>Fur Demonstration hier den Usertyp auswahlen</p>
        </div> */}
      </div>
    </>
  );
};

export default AdminSignIn;
