import React, { useState } from "react";
import Navbar from "../../components/Navbar";
import { USER_STORAGE_KEY } from "../../helpers/variables";
import ClassAttendance from "./Components/ClassAttendance";
import ClassRegisterAnalysisTable from "./Components/ClassRegisterAnalysisTable";
import ClassRegisterAnalysisTeacher from "./Components/ClassRegisterAnalysisTeacher";

const getCurrentDateInput = sDate => {
  const month = ("0" + (sDate.getMonth() + 1)).slice(-2);
  const day = ("0" + sDate.getDate()).slice(-2);
  const year = sDate.getFullYear();
  const shortDate = `${year}-${month}-${day}`;
  return shortDate;
};

const ClassRegisterAnalysis = () => {
  const [date, setDate] = useState(getCurrentDateInput(new Date()));

  const user = JSON.parse(localStorage.getItem(USER_STORAGE_KEY));

  const dateChangeHandler = e => {
    const { value } = e.target;
    setDate(getCurrentDateInput(new Date(value)));
  };

  return (
    <>
      <Navbar />
      <div className='classAnalysis'>
        {user.userType === "teacher" ? (
          <ClassRegisterAnalysisTeacher />
        ) : (
          <>
            <div className='classAnalysis__row'>
              <input
                type='date'
                className='signInContainer__fields__input'
                value={date}
                onChange={dateChangeHandler}
              />
            </div>
            <ClassRegisterAnalysisTable date={date} />
            <ClassAttendance date={date} />
          </>
        )}
      </div>
    </>
  );
};

export default ClassRegisterAnalysis;
