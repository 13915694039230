import axios from "axios";
import { BACKEND_API_URL } from "../helpers/variables";
import useHandleErrors from "./useHandleErrors";

const useTalks = () => {
  const { handleErrors } = useHandleErrors();

  const getNursingPhenomenonData = async _student => {
    try {
      const response = await axios.get(
        `${BACKEND_API_URL}/teacher/talks/nursingphenomenon/${_student}`
      );

      console.log(response.data);

      return response.data;
    } catch (err) {
      handleErrors(err);
    }
  };

  const getCompetencyAssessment = async _student => {
    try {
      const response = await axios.get(
        `${BACKEND_API_URL}/teacher/talks/nursingphenomenon/competencyAssessment/${_student}`
      );

      console.log(response.data);

      return response.data;
    } catch (err) {
      handleErrors(err);
    }
  };

  const getCompetencyAssessmentTypes = async () => {
    try {
      console.log("HITTT!");
      const response = await axios.post(
        `${BACKEND_API_URL}/teacher/talks/nursingphenomenon/competencyAssessmentTypes`
      );

      console.log(response);

      return response.data;
    } catch (err) {
      handleErrors(err);
    }
  };

  const getNursingPhenomenonTests = async _option => {
    try {
      const response = await axios.get(
        `${BACKEND_API_URL}/teacher/talks/nursingphenomenon/tests/${_option}`
      );

      console.log(response.data);

      return response.data;
    } catch (err) {
      handleErrors(err);
    }
  };

  const getNursingPhenomenonTestById = async _test => {
    try {
      const response = await axios.get(
        `${BACKEND_API_URL}/teacher/talks/nursingphenomenon/testById/${_test}`
      );

      console.log(response.data);

      return response.data;
    } catch (err) {
      handleErrors(err);
    }
  };

  const getNursingPhenomenonSubTestById = async (_test, _subTest) => {
    try {
      const response = await axios.get(
        `${BACKEND_API_URL}/teacher/talks/nursingphenomenon/subtestById/${_test}/${_subTest}`
      );

      console.log(response.data);

      return response.data;
    } catch (err) {
      handleErrors(err);
    }
  };

  const createTest = async data => {
    try {
      const response = await axios.post(
        `${BACKEND_API_URL}/teacher/talks/nursingphenomenon/createTest`,
        data
      );

      console.log(response.data);

      return response.data;
    } catch (err) {
      handleErrors(err);
    }
  };

  const getCompetencyTestByCategory = async (_competencyCat, _student) => {
    try {
      const response = await axios.get(
        `${BACKEND_API_URL}/teacher/talks/nursingphenomenon/competencyTestByCategory/${_competencyCat}/${_student}`
      );

      console.log(response.data);

      return response.data;
    } catch (err) {
      handleErrors(err);
    }
  };

  return {
    getNursingPhenomenonData,
    getNursingPhenomenonTests,
    getNursingPhenomenonTestById,
    getNursingPhenomenonSubTestById,
    createTest,
    getCompetencyTestByCategory,
    getCompetencyAssessment,
    getCompetencyAssessmentTypes,
  };
};

export default useTalks;
