import React from 'react'

const MetainteractionsTable = () => {
  return (
    <section className='proofOfPractice__body__signatureTable'>
      <table className='proofOfPractice__body__signatureTable__table'>
        <thead className='proofOfPractice__body__signatureTable__table__tableHead'>
          <tr className='proofOfPractice__body__signatureTable__table__tableHead__row'>
            <th className='proofOfPractice__body__signatureTable__table__tableHead__row__head'>Date</th>
            <th className='proofOfPractice__body__signatureTable__table__tableHead__row__head'>Duration (in minutes)</th>
            <th className='proofOfPractice__body__signatureTable__table__tableHead__row__head'>Metainteractions(HpA)</th>
            <th className='proofOfPractice__body__signatureTable__table__tableHead__row__head'>Kilian task</th>
            <th className='proofOfPractice__body__signatureTable__table__tableHead__row__head'>Signature practical <br /> instructions</th>
            <th className='proofOfPractice__body__signatureTable__table__tableHead__row__head'>Signature of <br /> trainee</th>
          </tr>
        </thead>
        <tbody className='proofOfPractice__body__signatureTable__table__tableBody'>
          <tr className='proofOfPractice__body__signatureTable__table__tableBody__row'>
            <td className='proofOfPractice__body__signatureTable__table__tableBody__row__details'>

            </td>
            <td className='proofOfPractice__body__signatureTable__table__tableBody__row__details'>

            </td>
            <td className='proofOfPractice__body__signatureTable__table__tableBody__row__details'>

            </td>
            <td className='proofOfPractice__body__signatureTable__table__tableBody__row__details'>
            </td>
            <td className='proofOfPractice__body__signatureTable__table__tableBody__row__details'>
              <div className='proofOfPractice__body__signatureTable__table__tableBody__row__details__checkBox'>
                <input type="checkbox" />
                <label>PA</label>
              </div>
            </td>
            <td className='proofOfPractice__body__signatureTable__table__tableBody__row__details'>
              <div className='proofOfPractice__body__signatureTable__table__tableBody__row__details__checkBox'>
                <input type="checkbox" />
                <label>Trainee</label>
              </div>
            </td>
          </tr>
          <tr className='proofOfPractice__body__signatureTable__table__tableBody__row'>
            <td className='proofOfPractice__body__signatureTable__table__tableBody__row__details'>

            </td>
            <td className='proofOfPractice__body__signatureTable__table__tableBody__row__details'>

            </td>
            <td className='proofOfPractice__body__signatureTable__table__tableBody__row__details'>

            </td>
            <td className='proofOfPractice__body__signatureTable__table__tableBody__row__details'>
            </td>
            <td className='proofOfPractice__body__signatureTable__table__tableBody__row__details'>
              <div className='proofOfPractice__body__signatureTable__table__tableBody__row__details__checkBox'>
                <input type="checkbox" />
                <label>PA</label>
              </div>
            </td>
            <td className='proofOfPractice__body__signatureTable__table__tableBody__row__details'>
              <div className='proofOfPractice__body__signatureTable__table__tableBody__row__details__checkBox'>
                <input type="checkbox" />
                <label>Trainee</label>
              </div>
            </td>
          </tr>
          <tr className='proofOfPractice__body__signatureTable__table__tableBody__row'>
            <td className='proofOfPractice__body__signatureTable__table__tableBody__row__details'>

            </td>
            <td className='proofOfPractice__body__signatureTable__table__tableBody__row__details'>

            </td>
            <td className='proofOfPractice__body__signatureTable__table__tableBody__row__details'>

            </td>
            <td className='proofOfPractice__body__signatureTable__table__tableBody__row__details'>
            </td>
            <td className='proofOfPractice__body__signatureTable__table__tableBody__row__details'>
              <div className='proofOfPractice__body__signatureTable__table__tableBody__row__details__checkBox'>
                <input type="checkbox" />
                <label>PA</label>
              </div>
            </td>
            <td className='proofOfPractice__body__signatureTable__table__tableBody__row__details'>
              <div className='proofOfPractice__body__signatureTable__table__tableBody__row__details__checkBox'>
                <input type="checkbox" />
                <label>Trainee</label>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </section>
  )
}

export default MetainteractionsTable