import React from 'react'
import Checkbox from '../Common/Checkox';
const CareFormCheckBox = ({ text }) => {
  return (
    <div className='careFormCheckbox'>
      <div className='careFormCheckbox__containerLeft'>
        {text}
      </div>
      <div className='careFormCheckbox__containerRight'>
        <div className='careFormCheckbox__containerRight__checkboxes'>
          <Checkbox label={"Planned"} />
          <Checkbox label={"Reached"} />
          <Checkbox label={"To promote"} />
          <Checkbox label={"Not reached"} />
        </div>
        <div className='careFormCheckbox__containerRight__lastCheckbox'>
          <Checkbox label={"Reached later"} />
        </div>
      </div>
    </div>
  )
}

export default CareFormCheckBox