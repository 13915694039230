import React from "react";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import SignIn from "../../pages/Authentication/SignIn";
import SignUp from "../../pages/Authentication/SignUp";
import EvaluationForm from "../../pages/EvaluationForm";
import Home from "../../pages/Home/index";
import ReviewForm from "../../pages/ReviewForm";
import ReviewA from "../../pages/ReviewA";
import FormA from "../../pages/FormA";
import PrivateRoute from "./AdminPrivateRoute";
import Classes from "../../pages/Classes";
import Students from "../../pages/Students";
import ItemA from "../../pages/ItemA";
import AddFormA from "../../pages/AddFormA";
import Comments from "../../pages/Comments";
import ClassRegisterAnalysis from "../../pages/ClassRegisterAnalysis";
import ListingOptions from "../../pages/ListingOptions";
import OptionQuestions from "../../pages/OptionQuestions";
import OptionQuestionAnswer from "../../pages/OptionQuestionAnswers";
import CompetencyProfile from "../../pages/CompetenceProfile";
import SimpleOptions from "../../pages/SimpleOptions";
import TalksForm from "../../pages/TalksForm";
import TrainingPortfolio from "../../pages/TrainingPortfolio";
import LearningGoals from "../../pages/TrainingPortfolio/LearningGoals";
import ProofOfPractice from "../../pages/ProofOfPractice";
import RegisterAnalysis from "../../pages/RegisterAnalysis";
import AccompanyingMaterial from "../../pages/AccompanyingMaterial";
import NursingPhenomena from "../../pages/NursingPhenomena";
import NursingPhenomenaExplanation from "../../pages/NursingPhenomenaExplanation";
import KilianTasks from "../../pages/KilianTasks";
import SkillIV from "../../pages/SkillsIV";
import CareForm from "../../pages/CareForm";
import LogsReviews from "../../pages/LogsReviews";
import AdminSignIn from "../../pages/Admin/SignIn";
import Dashboard from "../../pages/Admin/Dashboard";
import NursingPhenomenonOption from "../../pages/NursingPhenomenonOption";
import NursingPhenomenonSubTests from "../../pages/NursingPhenomenonSubTests";
import NursingPhenomenonSubTestLinks from "../../pages/NursingPhenomenonSubTestLinks";
import AssessmentForm from "../../pages/AssesmentForms";
import CompetencyAssessment from "../../pages/CompetencyAssessment";
import Assessment from "../../pages/Admin/Assessment";
import AssessementCategories from "../../pages/AssessmentCategories";
import AssessmentCatTest from "../../pages/AssessmentCatTest";
import AssessmentQuestions from "../../pages/AssessmentQuestions";
import TrainingPortFolio from "../../pages/Admin/TrainingPortFolio";
import KilianUploadFolders from "../../pages/KilianUploadFolders";
import KilianUploadsFiles from "../../pages/KilianUploadsFiles";
import LFCE from "../../pages/Admin/LFCE";

const Routing = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/signin' element={<SignIn />} />
        <Route path='/' element={<SignIn />} />
        <Route path='/signup' element={<SignUp />} />

        <Route path='/admin/signin' element={<AdminSignIn />} />

        {/* ADMIN ROUTES */}
        <Route path='/admin/dashboard' element={<Dashboard />} />
        <Route path='/admin/lfce' element={<LFCE />} />
        <Route
          path='/admin/trainingportfolio'
          element={<TrainingPortFolio />}
        />
        <Route
          path='/admin/assessment/:_test/:_subTest'
          element={<Assessment />}
        />

        {/* ADMIN ROUTES */}
        <Route path='/:_student' element={<Home />} />
        <Route path='/evaluationform/:_student' element={<EvaluationForm />} />
        <Route path='/forma/:_student' element={<FormA />} />
        <Route path='/addforma/:_id' element={<AddFormA />} />
        <Route path='/reviewform/:_student' element={<ReviewForm />} />
        <Route path='/reviewa/:_review' element={<ReviewA />} />
        <Route path='/classes' element={<Classes />} />
        <Route path='/students/:_id' element={<Students />} />
        <Route path='/itema/:_item' element={<ItemA />} />
        <Route path='/comments/:_student' element={<Comments />} />
        {/* NEW PAGES */}
        <Route
          path='/classRegisterAnalysis/:_class'
          element={<ClassRegisterAnalysis />}
        />
        <Route
          path='/listingOptions/:_option/:_student'
          element={<ListingOptions />}
        />
        <Route
          path='/kilianUploads/:_student/:_competencySubTest'
          element={<KilianUploadFolders />}
        />
        <Route
          path='/kilianUploads/:_student/:_competencySubTest/:type'
          element={<KilianUploadsFiles />}
        />
        <Route path='/optionquestions/:_option' element={<OptionQuestions />} />
        <Route
          path='/optionquestionanswer/:_option'
          element={<OptionQuestionAnswer />}
        />
        <Route
          path='/competenceprofile/:_student'
          element={<CompetencyProfile />}
        />
        <Route path='/options/:_option' element={<SimpleOptions />} />
        <Route path='/talksform/:_type/:_student' element={<TalksForm />} />
        <Route path='/trainingportfolio' element={<TrainingPortfolio />} />
        <Route path='/learninggoals' element={<LearningGoals />} />
        <Route path='/proofofpractice/:_option' element={<ProofOfPractice />} />
        <Route path='/assessmentforms/:_student' element={<AssessmentForm />} />
        <Route
          path='/assessment/competency/:_student'
          element={<CompetencyAssessment />}
        />
        <Route
          path='/assessment/competency/categories/:_student/:_test/:_subTest'
          element={<AssessementCategories />}
        />
        <Route
          path='/assessment/competency/catTests/:_student/:_category/:_test/:_subTest'
          element={<AssessmentCatTest />}
        />
        <Route
          path='/assessment/competency/catTests/:_student/:_assessmentTest'
          element={<AssessmentQuestions />}
        />
        <Route
          path='/registeranalysis/:_option'
          element={<RegisterAnalysis />}
        />
        <Route
          path='/accompanyingmaterial'
          element={<AccompanyingMaterial />}
        />
        <Route
          path='/nursingphenomena/:_student'
          element={<NursingPhenomena />}
        />
        <Route
          path='/nursingphenomena/:_student/:_competencyCat/:_option'
          element={<NursingPhenomenonOption />}
        />
        <Route
          path='/nursingphenomena/subtests/:_student/:_competencyCat/:_option/:_test'
          element={<NursingPhenomenonSubTests />}
        />
        <Route
          path='/nursingphenomena/subtests/links/:_test/:_subtest'
          element={<NursingPhenomenonSubTestLinks />}
        />
        <Route
          path='/nursingphenomena/explanation/:_option'
          element={<NursingPhenomenaExplanation />}
        />
        <Route
          path='/kiliantasks/:_option/:_talkType/:_student'
          element={<KilianTasks />}
        />
        <Route path='/skillsiv' element={<SkillIV />} />
        <Route path='/careform' element={<CareForm />} />
        <Route path='/logsreviews' element={<LogsReviews />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Routing;
