import React, { useEffect, useState } from "react";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import Page from "../../components/Page";
import { useLocation, useNavigate } from "react-router";
import useStudent from "../../hooks/useStudent";

const FormA = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { getFormItems } = useStudent();

  const [_form, setForm] = useState("");
  const [items, setItems] = useState([]);

  useEffect(() => {
    if (!_form) {
      let splitted = location.pathname.split("/");
      let formId = splitted[splitted.length - 1];
      return setForm(formId);
    }
    getInitialData();
  }, [_form]);

  const getInitialData = async () => {
    const response = await getFormItems(_form);
    if (response) setItems([...response.items]);
  };

  const redirectToDesc = () => navigate(`/addforma/${_form}`);

  return (
    <Page>
      <div className='form-AContainer'>
        <img
          src={require("../../assets/homePage.png")}
          alt=''
          className='form-AContainer__image'
        />
        <div className='form-AContainer__form'>
          <h2>Bogen A</h2>
          <div className='form-AContainer__form__formBody'>
            {items.map(it => (
              <div
                className='form-AContainer__form__formBody__header'
                key={it._id}
                onClick={() => navigate(`/itema/${it._id}`)}
              >
                {/* {console.log(
                  it.points.reduce((pV, cV) => (cV.done ? pV + 1 : pV), 0)
                )} */}
                <div className='form-AContainer__form__formBody__header__heading-1'>
                  <span>{it.itemName}</span>
                </div>
                <div className='form-AContainer__form__formBody__header__border'></div>
                <div className='form-AContainer__form__formBody__header__heading-2'>
                  <span>
                    {it.points.reduce((pV, cV) => (cV.done ? pV + 1 : pV), 0)}/6
                  </span>
                </div>
              </div>
            ))}
            <div className='form-AContainer__form__formBody__addIcon'>
              <AddCircleIcon
                sx={{ fontSize: "2.5rem", cursor: "pointer" }}
                onClick={redirectToDesc}
              />
            </div>
          </div>
        </div>
      </div>
    </Page>
  );
};
export default FormA;
