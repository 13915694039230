import axios from "axios";
import { BACKEND_API_URL } from "../helpers/variables";
import useHandleErrors from "./useHandleErrors";

const useUser = () => {
  const { handleErrors } = useHandleErrors();

  const getUserById = async _user => {
    try {
      const response = await axios.get(`${BACKEND_API_URL}/user/byId/${_user}`);

      return response.data;
    } catch (err) {
      handleErrors(err);
    }
  };

  const getSupervisors = async () => {
    try {
      const response = await axios.get(
        `${BACKEND_API_URL}/user/getSupervisors`
      );

      return response.data;
    } catch (err) {
      handleErrors(err);
    }
  };

  return { getUserById, getSupervisors };
};

export default useUser;
