import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router";
import PdfLink from "../../components/Common/PdfLink";
import fileIcon from "../../assets/fileIcon.svg";
import useStudent from "../../hooks/useStudent";
import { KolibriStore } from "../../App";

const KilianTasks = () => {
  const { setKilianTask } = useContext(KolibriStore);
  const { startKilianTask, getKilianTask } = useStudent();

  const location = useLocation();
  let splittedLoc = location.pathname.split("/");
  let _student = splittedLoc[splittedLoc.length - 1];
  let kilian_type = splittedLoc[splittedLoc.length - 2];
  let option = splittedLoc[splittedLoc.length - 3];

  const [topic, setTopic] = useState("");
  const [kilian, setKilian] = useState();

  useEffect(() => {
    getKilianTaskNow();
  }, []);

  const getKilianTaskNow = async () => {
    const response = await getKilianTask(kilian_type, _student);

    if (response) {
      setTopic(response?.kilian_task?.topic);
      setKilian(response?.kilian_task);
    }

    return response.kilian_task;
  };

  const startTask = async () => {
    if (!topic || !kilian_type || !_student)
      return alert("Invalid or Missing details!");
    if (kilian) return alert("Kilian task already started!");

    const response = await startKilianTask({
      topic,
      kilian_type,
      _student,
    });
    setKilianTask(response.kilian);
    setTopic("");
    alert("Task added successfully!");
  };

  return (
    <section className='kilianTasks'>
      <div className='kilianTasks__body'>
        <header className='kilianTasks__body__header'>
          <div className='kilianTasks__body__header__logo'>
            <img src={require("../../assets/Logo.png")} alt='' />
            <h2>KILIAN Tasks</h2>
          </div>
          {option === "kilian" && (
            <div className='kilianTasks__body__header__heading'>
              <h2>KILIAN</h2>
            </div>
          )}
        </header>
        {option === "kilian" && (
          <>
            <div className='kilianTasks__body__pdfContainer'>
              <PdfLink name={"Explainer video"} path={"/www.google.com"} />
              <PdfLink name={"PDF German"} path={"/www.google.com"} />
              <PdfLink name={"Explainer video"} path={"/www.google.com"} />
              <PdfLink name={"PDF English"} path={"/www.google.com"} />
            </div>
            <div className='kilianTasks__body__task'>
              <p>Choose topic:</p>
              <input
                type='text'
                name='topic'
                value={topic}
                onChange={e => setTopic(e.target.value)}
              />
              <button onClick={startTask}>Start task</button>
            </div>
          </>
        )}
        {option !== "kilian" && (
          <div className='kilianTasks__body__uploadsContainer'>
            <div className='kilianTasks__body__uploadsContainer__left'>
              <p>Uploading</p>
              <div className='kilianTasks__body__uploadsContainer__left__files'>
                <div className='kilianTasks__body__uploadsContainer__left__files__file'>
                  <img src={fileIcon} alt='' />
                  <p>Confrontation</p>
                </div>
                <div className='kilianTasks__body__uploadsContainer__left__files__file'>
                  <img src={fileIcon} alt='' />
                  <p>Information</p>
                </div>
                <div className='kilianTasks__body__uploadsContainer__left__files__file'>
                  <img src={fileIcon} alt='' />
                  <p>Solution finding</p>
                </div>
                <div className='kilianTasks__body__uploadsContainer__left__files__file'>
                  <img src={fileIcon} alt='' />
                  <p>Implementation</p>
                </div>
                <div className='kilianTasks__body__uploadsContainer__left__files__file'>
                  <img src={fileIcon} alt='' />
                  <p>Application</p>
                </div>
                <div className='kilianTasks__body__uploadsContainer__left__files__file'>
                  <img src={fileIcon} alt='' />
                  <p>Sustainability</p>
                </div>
              </div>
            </div>
            <div className='kilianTasks__body__uploadsContainer__right'>
              <p>Explanations</p>
              <div className='kilianTasks__body__uploadsContainer__right__links'>
                <a
                  target='_blank'
                  rel='noopener noreferrer'
                  href='/www.google.com'
                  className='kilianTasks__body__uploadsContainer__right__links__pdfLink'
                >
                  <p>Explainer video</p>
                </a>
                <a
                  target='_blank'
                  rel='noopener noreferrer'
                  href='/www.google.com'
                  className='kilianTasks__body__uploadsContainer__right__links__pdfLink'
                >
                  <p>PDT to German</p>
                </a>
                <a
                  target='_blank'
                  rel='noopener noreferrer'
                  href='/www.google.com'
                  className='kilianTasks__body__uploadsContainer__right__links__pdfLink'
                >
                  <p>Explanation video</p>
                </a>
                <a
                  target='_blank'
                  rel='noopener noreferrer'
                  href='/www.google.com'
                  className='kilianTasks__body__uploadsContainer__right__links__pdfLink'
                >
                  <p>PDT to English</p>
                </a>
              </div>
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

export default KilianTasks;
