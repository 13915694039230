import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import Page from "../../components/Page";
import useAuthenticate from "../../hooks/useAuthenticate";
import useStudent from "../../hooks/useStudent";

const ReviewForm = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { getReviews } = useStudent();
  const { isAuthenticated } = useAuthenticate();
  let user = isAuthenticated(false, ["teacher", "student"]);

  const [_student, setStudent] = useState("");
  const [reviews, setReviews] = useState([]);

  useEffect(() => {
    if (!_student) {
      let splitted = location.pathname.split("/");
      let studentId = splitted[splitted.length - 1];
      return setStudent(studentId);
    }
    getInitialData();
  }, [_student]);

  const getInitialData = async () => {
    const response = await getReviews(_student);
    if (response) setReviews([...response.reviews]);
  };

  return (
    <Page>
      <div className='reviewFormContainer'>
        <div className='reviewFormContainer__top'></div>
        <div className='reviewFormContainer__boxes-R1'>
          {reviews.map(rw => {
            console.log(
              rw.rType === "comments" && user?.user?.userType !== "student"
            );
            return rw.rType === "comments" &&
              user?.user?.userType !== "student" ? (
              <div
                className='reviewFormContainer__boxes-R1__leftContainer'
                onClick={() => navigate(`/comments/${_student}`)}
              >
                <p>{rw.rName}</p>
                <img src={require("../../assets/homePage.png")} alt='' />
              </div>
            ) : (
              rw.rType === "review" && (
                <div
                  className='reviewFormContainer__boxes-R1__leftContainer'
                  onClick={() => navigate(`/reviewa/${rw._id}`)}
                >
                  <p>{rw.rName}</p>
                  <img src={require("../../assets/homePage.png")} alt='' />
                </div>
              )
            );
          })}
        </div>
      </div>
    </Page>
  );
};

export default ReviewForm;
