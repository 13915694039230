import React from 'react';
import Navbar from "../Navbar/index";

const Page = ({ children }) => {
  return (
    <>
      {<Navbar />}
      {children}
    </>
  );
}

export default Page;
