import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import Loading from "../../components/Common/Loading";
import CSmButton from "../../components/Form/CSmButton";
import { USER_STORAGE_KEY } from "../../helpers/variables";
import useAdmin from "../../hooks/useAdmin";
import useTalks from "../../hooks/useTalks";

const NursingPhenomenonSubTests = () => {
  const location = useLocation();
  const splittedLoc = location.pathname.split("/");
  const _test = splittedLoc[splittedLoc.length - 1];
  const _option = splittedLoc[splittedLoc.length - 2];
  const _competencyCat = splittedLoc[splittedLoc.length - 3];
  const _student = splittedLoc[splittedLoc.length - 4];

  const [test, setTest] = useState();
  const [subtests, setSubTests] = useState([]);
  const [competencyTest, setCompetencyTest] = useState();
  const [selectedSubTest, setSelectedSubTest] = useState();
  const [loading, setLoading] = useState(true);

  const {
    getNursingPhenomenonTests,
    getNursingPhenomenonTestById,
    createTest,
    getCompetencyTestByCategory,
  } = useTalks();

  const { getSubTests } = useAdmin();

  const user = JSON.parse(localStorage.getItem(USER_STORAGE_KEY));

  useEffect(() => {
    getInitialData();
  }, []);

  const getInitialData = async () => {
    const [test, subtests, competencyTest] = await Promise.all([
      getNursingPhenomenonTestById(_test),
      getSubTests(_test),
      getCompetencyTestByCategory(_competencyCat, _student),
    ]);

    if (test) setTest(test?.test);
    if (subtests) setSubTests(subtests?.tests);
    if (competencyTest) setCompetencyTest(competencyTest.test);
    setLoading(false);
  };

  const createTestNow = async () => {
    if (!selectedSubTest) return alert("Please select a subtest!");
    const clonedCompetencyTest = JSON.parse(JSON.stringify(competencyTest));

    const data = {
      _student,
      _competencyCat,
      _categoryOption: _option,
      _test,
      _subTest: selectedSubTest,
    };

    const response = await createTest(data);
    if (response) alert("Test submitted successfully!");
    else setCompetencyTest({ ...clonedCompetencyTest });
  };

  if (loading) return <Loading />;

  return (
    <section className='nursingPhenomena'>
      <div className='nursingPhenomena__body'>
        <header className='nursingPhenomena__body__header'>
          <img src={require("../../assets/Logo.png")} alt='' />
          <h2>{test?.title}</h2>
        </header>
        <div className='nursingPhenomena__body__container'>
          <SelectOptionsBoxes
            options={subtests}
            createTestNow={createTestNow}
            competencyTest={competencyTest}
            setCompetencyTest={setCompetencyTest}
            _test={_test}
            setSelectedSubTest={setSelectedSubTest}
            _competencyCat={_competencyCat}
          />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            paddingRight: "1.5rem",
          }}
        >
          <CSmButton
            title={"Select"}
            onClick={createTestNow}
            disabled={user?.userType === "student"}
          />
        </div>
      </div>
    </section>
  );
};

const SelectOptionsBoxes = ({
  options,
  createTestNow,
  competencyTest,
  _test,
  setSelectedSubTest,
  setCompetencyTest,
  _competencyCat,
}) => {
  const handleSubTestClick = _subtest =>
    window.open(
      `/nursingphenomena/subtests/links/${_test}/${_subtest}`,
      "_blank"
    );

  const changeHandler = async e => {
    const { name, value, checked } = e.target;

    if (competencyTest?._test && _test !== competencyTest._test) return;

    console.log(competencyTest);

    setCompetencyTest({
      ...competencyTest,
      _test,
      _subTest: value,
    });
    setSelectedSubTest(value);
  };

  return (
    <div className='selectedOptionsBoxes'>
      {options.map(op => (
        <SelectOptionsBox
          option={op}
          key={op._id}
          onClick={() => handleSubTestClick(op._id)}
          changeHandler={changeHandler}
          checked={
            op._id === competencyTest?._subTest &&
            _test === competencyTest?._test
          }
        />
      ))}
    </div>
  );
};

const SelectOptionsBox = ({ option, onClick, changeHandler, checked }) => {
  return (
    <div className='selectedOptionsBoxes__box'>
      <div className='selectedOptionsBoxes__box__title'>{option?.title}</div>

      <div className='selectedOptionsBoxes__box__row'>
        <input
          type='radio'
          name='_subTest'
          id={option.title}
          value={option._id}
          onChange={changeHandler}
          checked={checked}
        />
        <h3>{option?.subTitle}</h3>
      </div>
      <div className='selectedOptionsBoxes__box__row'>
        <button onClick={onClick}>Erklärung</button>
      </div>
    </div>
  );
};

export default NursingPhenomenonSubTests;
