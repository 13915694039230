import React from 'react'
import NursingPhenomenaContent from '../../components/Common/NursingPhenomenaContent'

const NursingPhenomena = () => {
  return (
    <section className='nursingPhenomena'>
      <div className='nursingPhenomena__body'>
        <header className='nursingPhenomena__body__header'>
          <img src={require("../../assets/Logo.png")} alt="" />
          <h2>Pflegephänomene</h2>
        </header>
        <div className='nursingPhenomena__body__container'>
          <NursingPhenomenaContent />
        </div>
      </div>
    </section>
  )
}

export default NursingPhenomena