import React, { useEffect, useState } from "react";
import Logo from "../../../assets/Logo.svg";
import useAuthenticate from "../../../hooks/useAuthenticate";
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { useNavigate } from "react-router";
import useStudent from "../../../hooks/useStudent";
import Navbar from "../../../components/Navbar";
import useUpload from "../../../hooks/useUpload";

const SignUp = () => {
  const navigate = useNavigate();
  const { register } = useAuthenticate();
  const { getAllClasses } = useStudent();
  const { uploadImage } = useUpload();

  const [details, setDetails] = useState({ //no need for impatient here ?
    name: "",
    email: "",
    firstName: "",
    username: "",
    password: "",
    userType: "teacher",
    grade: "",
    lfce: "LF",
  });

  const [classes, setClasses] = useState([]);

  useEffect(() => {
    getInitialData();
  }, []);

  const getInitialData = async () => {
    const response = await getAllClasses();
    if (response) setClasses([...response.classes]);
  };

  const changeHandler = (e, type) => {
    if (type) {
      if (type === "supervisor") {
        delete details.impatient1;
        delete details.impatient2;
        delete details.impatient3;
        delete details.impatient4;
        delete details.impatient5;
        details.lfce = "LF";
      } else if (type === "teacher") {
        delete details.impatient1;
        delete details.impatient2;
        delete details.impatient3;
        delete details.impatient4;
        delete details.impatient5;
        delete details.lfce;
      } else {
        delete details.lfce;
        details.impatient1 = "";
        details.impatient2 = "";
        details.impatient3 = "";
        details.impatient4 = "";
        details.impatient5 = "";
      }

      setDetails({ ...details, userType: type });
    } else {
      const { name, value, files } = e.target;
      setDetails({
        ...details,
        [name]: name === "signature" || name === "profile" ? files[0] : value,
      });
    }
  };

  const submitHandler = async e => {
    e.preventDefault();
    if (details.password !== details.repeatPassword)
      return alert("Die Passwörter stimmen nicht überein !");
    const formValid = validateForm();
    if (!formValid) return alert("Bitte alle Felder richtig ausfüllen!");

    try {
      const [profile, signature] = await Promise.all([
        uploadImage(details.profile),
        uploadImage(details.signature),
      ]);

      const response = await register({ ...details, profile, signature });
      if (response) {
        alert("Account erfolgreich erstellt!");
        navigate("/signin");
      }
    } catch (err) {
      console.log(err);
    }
  };

  const validateForm = () => {
    for (const prop in details) {
      if (!details[prop] && prop !== "grade" && details.userType !== "student")
        return false;
      else if (!details[prop] && details.userType === "student") return false;
    }

    if (
      details.userType === "student" &&
      (!details.impatient1 ||
        !details.impatient2 ||
        !details.impatient3 ||
        !details.impatient4 ||
        !details.impatient5)
    )
      return false;

    return true;
  };

  return (
    <>
      <Navbar />
      <div className='signUpContainer'>
        <div className='signUpContainer__header'>
          <Box className='signInContainer__header__logo'>
            <hr />
            <img src={Logo} alt='' />
          </Box>
        </div>

        <div
          className='signUpContainer__fields'
          // style={{ height: details.userType === "student" ? "420px" : "360px" }}
        >
          <form onSubmit={submitHandler} style={{ width: "100%" }}>
            <input
              type='text'
              placeholder='Nachname'
              name='name'
              className='signUpContainer__fields__input'
              onChange={changeHandler}
              required
            />
            <input
              type='text'
              placeholder='Vorname'
              name='firstName'
              className='signUpContainer__fields__input'
              onChange={changeHandler}
              required={true}
            />
            <input
              type='text'
              placeholder='Username'
              name='username'
              className='signUpContainer__fields__input'
              onChange={changeHandler}
              required={true}
            />
            <input
              type='email'
              placeholder='Email'
              name='email'
              className='signUpContainer__fields__input'
              onChange={changeHandler}
              required={true}
            />

            <input
              type='password'
              placeholder='Passwort'
              name='password'
              className='signUpContainer__fields__input'
              onChange={changeHandler}
              required={true}
            />
            <input
              type='password'
              placeholder='Passwort wiederholen'
              name='repeatPassword'
              className='signUpContainer__fields__input'
              onChange={changeHandler}
              required={true}
            />
            <input
              type='number'
              placeholder='Telefonnummer'
              name='contact'
              className='signUpContainer__fields__input'
              onChange={changeHandler}
              required={true}
            />

            {details.userType === "student" && (
              <>
                <div className='input__group'>
                  <label htmlFor='impatient' className='label'>
                    Orientierungseinsatz
                  </label>
                  <select
                    name='impatient1'
                    id='impatient1'
                    className='signUpContainer__fields__input'
                    required={true}
                    onChange={changeHandler}
                  >
                    <option value='' disabled selected>
                      Orientierungseinsatz
                    </option>
                    <option value='stationäre Langzeitpflege'>
                      stationäre Langzeitpflege
                    </option>
                    <option value='stationäre Akutpflege'>
                      stationäre Akutpflege
                    </option>
                    <option value='.- zentrale Notaufnahme'>
                      .- zentrale Notaufnahme
                    </option>
                    <option value='.- Intensivstation'>
                      .- Intensivstation
                    </option>
                    <option value='.- Pediatrie'>.- Pädiatrie</option>
                    <option value='.- stationäre Dialyse'>
                      .- stationäre Dialyse
                    </option>
                    <option value='ambulante Kurz- und Langzeitpflege'>
                      ambulante Kurz- und Langzeitpflege
                    </option>
                    <option value='.- Tagespflege'>.- Tagespflege</option>
                    <option value='.- Kurzzeitpflege'>.- Kurzzeitpflege</option>
                    <option value='.- ambulante Pflege'>
                      .- ambulante Pflege
                    </option>
                    <option value='.- ambulante Dialyse'>
                      .- ambulante Dialyse
                    </option>
                    <option value='Pediatrie'>Pädiatrie</option>
                    <option value='Psychiatrie'>Psychiatrie</option>
                  </select>
                </div>
                <div className='input__group'>
                  <label htmlFor='impatient' className='label'>
                    Pflicht-, Vertiefungseinsatz 1
                  </label>
                  <select
                    name='impatient2'
                    id='impatient2'
                    className='signUpContainer__fields__input'
                    required={true}
                    onChange={changeHandler}
                  >
                    <option value='' disabled selected>
                      Pflicht-, Vertiefungseinsatz 1
                    </option>
                    <option value='stationäre Langzeitpflege'>
                      stationäre Langzeitpflege
                    </option>
                    <option value='stationäre Akutpflege'>
                      stationäre Akutpflege
                    </option>
                    <option value='.- zentrale Notaufnahme'>
                      .- zentrale Notaufnahme
                    </option>
                    <option value='.- Intensivstation'>
                      .- Intensivstation
                    </option>
                    <option value='.- Pediatrie'>.- Pediatrie</option>
                    <option value='.- stationäre Dialyse'>
                      .- stationäre Dialyse
                    </option>
                    <option value='ambulante Kurz- und Langzeitpflege'>
                      ambulante Kurz- und Langzeitpflege
                    </option>
                    <option value='.- Tagespflege'>.- Tagespflege</option>
                    <option value='.- Kurzzeitpflege'>.- Kurzzeitpflege</option>
                    <option value='.- ambulante Pflege'>
                      .- ambulante Pflege
                    </option>
                    <option value='.- ambulante Dialyse'>
                      .- ambulante Dialyse
                    </option>
                    <option value='Pediatrie'>Pediatrie</option>
                    <option value='Psychiatrie'>Psychiatrie</option>
                  </select>
                </div>
                <div className='input__group'>
                  <label htmlFor='impatient' className='label'>
                    Pflicht-, Vertiefungseinsatz 2
                  </label>
                  <select
                    name='impatient3'
                    id='impatient3'
                    className='signUpContainer__fields__input'
                    required={true}
                    onChange={changeHandler}
                  >
                    <option value='' disabled selected>
                      Pflicht-, Vertiefungseinsatz 2
                    </option>
                    <option value='stationäre Langzeitpflege'>
                      stationäre Langzeitpflege
                    </option>
                    <option value='stationäre Akutpflege'>
                      stationäre Akutpflege
                    </option>
                    <option value='.- zentrale Notaufnahme'>
                      .- zentrale Notaufnahme
                    </option>
                    <option value='.- Intensivstation'>
                      .- Intensivstation
                    </option>
                    <option value='.- Pediatrie'>.- Pediatrie</option>
                    <option value='.- stationäre Dialyse'>
                      .- stationäre Dialyse
                    </option>
                    <option value='ambulante Kurz- und Langzeitpflege'>
                      ambulante Kurz- und Langzeitpflege
                    </option>
                    <option value='.- Tagespflege'>.- Tagespflege</option>
                    <option value='.- Kurzzeitpflege'>.- Kurzzeitpflege</option>
                    <option value='.- ambulante Pflege'>
                      .- ambulante Pflege
                    </option>
                    <option value='.- ambulante Dialyse'>
                      .- ambulante Dialyse
                    </option>
                    <option value='Pediatrie'>Pediatrie</option>
                    <option value='Psychiatrie'>Psychiatrie</option>
                  </select>
                </div>
                <div className='input__group'>
                  <label htmlFor='impatient' className='label'>
                    Pflicht-, Vertiefungseinsatz 3 
                  </label>
                  <select
                    name='impatient4'
                    id='impatient4'
                    className='signUpContainer__fields__input'
                    required={true}
                    onChange={changeHandler}
                  >
                    <option value='' disabled selected>
                      Pflicht-, Vertiefungseinsatz 3
                    </option>
                    <option value='stationäre Langzeitpflege'>
                      stationäre Langzeitpflege
                    </option>
                    <option value='stationäre Akutpflege'>
                      stationäre Akutpflege
                    </option>
                    <option value='.- zentrale Notaufnahme'>
                      .- zentrale Notaufnahme
                    </option>
                    <option value='.- Intensivstation'>
                      .- Intensivstation
                    </option>
                    <option value='.- Pediatrie'>.- Pediatrie</option>
                    <option value='.- stationäre Dialyse'>
                      .- stationäre Dialyse
                    </option>
                    <option value='ambulante Kurz- und Langzeitpflege'>
                      ambulante Kurz- und Langzeitpflege
                    </option>
                    <option value='.- Tagespflege'>.- Tagespflege</option>
                    <option value='.- Kurzzeitpflege'>.- Kurzzeitpflege</option>
                    <option value='.- ambulante Pflege'>
                      .- ambulante Pflege
                    </option>
                    <option value='.- ambulante Dialyse'>
                      .- ambulante Dialyse
                    </option>
                    <option value='Pediatrie'>Pediatrie</option>
                    <option value='Psychiatrie'>Psychiatrie</option>
                  </select>
                </div>
                <div className='input__group'>
                  <label htmlFor='impatient' className='label'>
                    Pflicht-, Vertiefungseinsatz 4
                  </label>
                  <select
                    name='impatient5'
                    id='impatient5'
                    className='signUpContainer__fields__input'
                    required={true}
                    onChange={changeHandler}
                  >
                    <option value='' disabled selected>
                      Pflicht-, Vertiefungseinsatz 4
                    </option>
                    <option value='stationäre Langzeitpflege'>
                      stationäre Langzeitpflege
                    </option>
                    <option value='stationäre Akutpflege'>
                      stationäre Akutpflege
                    </option>
                    <option value='.- zentrale Notaufnahme'>
                      .- zentrale Notaufnahme
                    </option>
                    <option value='.- Intensivstation'>
                      .- Intensivstation
                    </option>
                    <option value='.- Pediatrie'>.- Pediatrie</option>
                    <option value='.- stationäre Dialyse'>
                      .- stationäre Dialyse
                    </option>
                    <option value='ambulante Kurz- und Langzeitpflege'>
                      ambulante Kurz- und Langzeitpflege
                    </option>
                    <option value='.- Tagespflege'>.- Tagespflege</option>
                    <option value='.- Kurzzeitpflege'>.- Kurzzeitpflege</option>
                    <option value='.- ambulante Pflege'>
                      .- ambulante Pflege
                    </option>
                    <option value='.- ambulante Dialyse'>
                      .- ambulante Dialyse
                    </option>
                    <option value='Pediatrie'>Pediatrie</option>
                    <option value='Psychiatrie'>Psychiatrie</option>
                  </select>
                </div>
              </>
            )}

            {details.userType === "supervisor" && (
              <div className='input__group'>
                <label htmlFor='lfce' className='label'>
                  LFCE
                </label>
                <select
                  name='lfce'
                  id='lfce'
                  className='signUpContainer__fields__input'
                  required={true}
                  onChange={changeHandler}
                >
                  <option value='LF'>LF</option>
                  <option value='CE'>CE</option>
                </select>
              </div>
            )}

            <div className='input__group'>
              <label htmlFor='dob' className='label'>
                Profil Bild
              </label>
              <input
                type='file'
                placeholder='Profile Picture'
                name='profile'
                className='signUpContainer__fields__input'
                onChange={changeHandler}
                required={true}
              />
            </div>
            <div className='input__group'>
              <label htmlFor='dob' className='label'>
                Geburtsdatum
              </label>
              <input
                type='date'
                placeholder='Date of Birth'
                name='dob'
                className='signUpContainer__fields__input'
                required={true}
                onChange={changeHandler}
              />
            </div>
            <div className='input__group'>
              <label htmlFor='signature' className='label'>
                Unterschrift
              </label>
              <input
                type='file'
                placeholder='Signature'
                name='signature'
                className='signUpContainer__fields__input'
                onChange={changeHandler}
                required={true}
              />
            </div>

            {details.userType === "student" && (
              <Box sx={{ width: 280, background: "white" }}>
                <FormControl fullWidth>
                  <InputLabel id='demo-simple-select-label'>
                    Klasse
                  </InputLabel>
                  <Select
                    labelId='demo-simple-select-label'
                    id='demo-simple-select'
                    value={details.grade}
                    label='class'
                    name='grade'
                    onChange={e =>
                      setDetails({ ...details, grade: e.target.value })
                    }
                    required={true}
                  >
                    {classes.map(cl => (
                      <MenuItem value={cl._id}>{cl.className}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            )}
            <Button onClick={submitHandler} type='submit'>
              Registrieren
            </Button>
          </form>
          <span className='blockedSpanLink' onClick={() => navigate("/signin")}>
            <center>Haben Sie bereits ein Konto?<br></br></center>
              <center>Jetzt Einloggen!</center>
          </span>
        </div>

        <div className='signInContainer__buttons'>
          <button
            className='signInContainer__buttons__button1'
            onClick={e => changeHandler(e, "supervisor")}
            style={{
              background:
                details.userType === "supervisor" ? "#D7D7D7" : "#F2F2F2",
              cursor: "pointer",
            }}
          >
            Praxisbegleitung
          </button>
          <button
            className='signInContainer__buttons__button2'
            onClick={e => changeHandler(e, "teacher")}
            style={{
              background:
                details.userType === "teacher" ? "#D7D7D7" : "#F2F2F2",
              cursor: "pointer",
            }}
          >
            Praxisanleitung
          </button>
          <button
            className='signInContainer__buttons__button3'
            onClick={e => changeHandler(e, "student")}
            style={{
              background:
                details.userType === "student" ? "#D7D7D7" : "#F2F2F2",
              cursor: "pointer",
            }}
          >
            Auszubildende/r
          </button>
        </div>
      </div>
    </>
  );
};

export default SignUp;
