import { Box, Modal } from "@mui/material";
import React, { useState } from "react";
import Select from "react-select";
import CSmButton from "../../../../components/Form/CSmButton";
import CustomInput from "../../../../components/Form/CustomInput";
import useAdmin from "../../../../hooks/useAdmin";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "none",
  borderRadius: "0.5rem",
  boxShadow: 24,
  outline: "none",
  p: 4,
};

const UpdateLFCEModal = ({
  open,
  setOpen,
  competencies,
  loading,
  setLFCEs,
  lfce,
  setLFCE,
}) => {
  const { updateLFCE } = useAdmin();

  const handleClose = () => {
    setOpen(false);
  };

  const submitHandler = async e => {
    e.preventDefault();

    let objectives = lfce?._learningObjectives.map(item => item.value || item);

    const data = {
      ...lfce,
      _learningObjectives: objectives,
    };

    const response = await updateLFCE(data);
    if (response) {
      setLFCEs((pS, index) => {
        pS[index] = response.lfce;
        return [...pS];
      });
      setOpen(false);
      window.location.reload();
    }
  };

  const changeHandler = e => {
    const { name, value } = e.target;
    setLFCE(pS => ({
      ...pS,
      [name]: value,
    }));
  };

  const selectionChange = learningObjectives => {
    console.log(learningObjectives);
    setLFCE(pS => ({
      ...pS,
      _learningObjectives: learningObjectives,
    }));
  };

  console.log(lfce, "<---");

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
      sx={{ background: "rgba(255, 255, 255, 0.15)" }}
    >
      <Box sx={style}>
        <div className='dashboardAddModal'>
          {loading ? (
            <h1>Loading...</h1>
          ) : (
            <>
              <h3>Update LFCE</h3>
              <form onSubmit={submitHandler}>
                <CustomInput
                  style={{ background: "#ECEFF7", padding: "0.5rem" }}
                  placeholder='Title'
                  name='title'
                  type='text'
                  onChange={changeHandler}
                  required={true}
                  value={lfce?.title}
                />
                <CustomInput
                  style={{ background: "#ECEFF7", padding: "0.5rem" }}
                  placeholder='Learning Objective'
                  name='competencyIV'
                  type='text'
                  onChange={changeHandler}
                  required={true}
                  value={lfce?.competencyIV}
                />
                <Select
                  className='basic-single'
                  classNamePrefix='select'
                  defaultValue={lfce?._learningObjectives?.map(item => ({
                    value: item?._id,
                    label: item?.title,
                  }))}
                  isMulti
                  isDisabled={false}
                  isLoading={false}
                  isClearable={true}
                  isRtl={false}
                  isSearchable={true}
                  name='_competencyIV'
                  required={true}
                  options={competencies}
                  onChange={selectionChange}
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      textAlign: "start",
                      border: 0,
                      outline: "1px solid white",
                      // height: "1rem",
                      boxShadow: "none",
                      background: "#ECEFF7",
                      fontSize: "0.9rem",
                      marginBottom: "1rem",
                    }),
                  }}
                />
                <CSmButton title='Update' type='submit' />
              </form>
            </>
          )}
        </div>
      </Box>
    </Modal>
  );
};

export default UpdateLFCEModal;
