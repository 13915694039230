import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import Loading from "../../components/Common/Loading";
import OptionBox from "../../components/Common/OptionBox";
import CSmButton from "../../components/Form/CSmButton";
import Navbar from "../../components/Navbar";
import useAdmin from "../../hooks/useAdmin";
import useTalks from "../../hooks/useTalks";

const AssessementCategories = () => {
  const location = useLocation();
  const splitted = location.pathname.split("/");
  const _subTest = splitted[splitted.length - 1];
  const _test = splitted[splitted.length - 2];
  const _student = splitted[splitted.length - 3];

  const { getAssessmentCategries, getRequiredAssessments } = useAdmin();

  const [assessmentCats, setAssessmentCats] = useState([]);
  const [grades, setGrades] = useState("-");
  const [loading, setLoading] = useState(true);

  const [totalTests, setTotalTests] = useState();
  const [assessedTests, setAssessedTests] = useState([]);

  useEffect(() => {
    getInitialData();
  }, []);

  const getInitialData = async () => {
    const [categoriesResponse, assessedResponse] = await Promise.all([
      getAssessmentCategries(_student),
      getRequiredAssessments(_student, _test, _subTest),
    ]);

    if (assessedResponse) {
      console.log(assessedResponse);
      console.log(assessedResponse.allAssessedTests);
      setTotalTests(assessedResponse?.totalTests);
      setAssessedTests(assessedResponse?.allAssessedTests);
    }

    if (categoriesResponse) {
      console.log(categoriesResponse?.competencyAssessmentCats);
      setAssessmentCats([...categoriesResponse?.competencyAssessmentCats]);
      setGrades(categoriesResponse?.grade || "-");
    }

    setLoading(false);
  };

  const downloadAssessments = async () => {
    const headings = [
      "Assessment Category",
      "Assessment Test",
      "Test Title",
      "Grade",
      "Test Option",
    ];
    let fileText = "";
    headings.forEach(head => {
      fileText = fileText.concat(`${head},`);
    });

    let assessments = assessedTests;

    assessments.forEach(assesment => {
      fileText = fileText.concat("\n");
      fileText = fileText.concat(
        `${assesment?._competencyAssessmentCategoryTest?._competencyAssessmentCat.title.replace(
          /,/g,
          " "
        )},`
      );
      fileText = fileText.concat(
        `${assesment?._competencyAssessmentCategoryTest?.desc.replace(
          /,/g,
          " "
        )},`
      );
      fileText = fileText.concat(
        `${assesment?._competencyAssessmentCategoryOption?.title.replace(
          /,/g,
          " "
        )},`
      );
      fileText = fileText.concat(`${assesment.grade},`);
      fileText = fileText.concat(
        `${assesment?._competencyAssessmentCategoryOption?.desc.replace(
          /,/g,
          " "
        )},`
      );
    });

    console.log(fileText);

    var a = document.createElement("a");
    var file = new Blob([fileText], {
      type: "xlsx",
    });
    a.href = URL.createObjectURL(file);
    a.download = `assessment_${new Date()}.csv`;
    a.click();
  };

  if (loading) return <Loading />;

  return (
    <div>
      <Navbar />

      <div
        style={{
          width: "100%",
          paddingTop: "7rem",
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <CSmButton
          style={{ marginRight: "3rem" }}
          onClick={downloadAssessments}
          title='Download'
          disabled={totalTests > assessedTests.length}
        />
      </div>

      <div className='assessmentCats' style={{ paddingTop: "1rem" }}>
        <div className='assessmentCats__intro'>
          <h3>Arthrosis competency test</h3>
          <span>
            <b>Achieved grade:{grades}</b>
          </span>
        </div>

        <div className='assessmentCats__cats'>
          {assessmentCats.map(cat => (
            <OptionBox
              title={cat?.title}
              key={cat._id}
              path={`/assessment/competency/catTests/${_student}/${cat?._id}/${_test}/${_subTest}`}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default AssessementCategories;
