import * as React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Toolbar from "@mui/material/Toolbar";
import Navbar from "../../Navbar";
import CFWButton from "../../Form/CFWButton";
import { ADMIN_STORAGE_KEY } from "../../../helpers/variables";
import { useNavigate, useLocation } from "react-router-dom";

const drawerWidth = 240;

const pages = [
  {
    title: "Dashboard",
    path: "/admin/dashboard",
  },
  {
    title: "Training Portfolio",
    path: "/admin/trainingportfolio",
  },
  {
    title: "LFCE",
    path: "/admin/lfce",
  },
];

function RPageWithSideBar(props) {
  const { window, children } = props;
  const navigate = useNavigate();
  const location = useLocation();
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const logoutAdmin = () => {
    localStorage.removeItem(ADMIN_STORAGE_KEY);
    navigate("/admin/signin");
  };

  const drawer = (
    <div className='sidebarAdminDrawer'>
      {/* <Toolbar /> */}
      <List>
        {pages.map((page, index) => (
          <div
            className={`sidebarItem ${
              page.path === location.pathname && "sidebarItem--selected"
            }`}
            key={page?.title}
            onClick={() => navigate(page.path)}
          >
            <div className='sidebarItem__icon'>
              <i className='fa-solid fa-gauge'></i>
            </div>
            <div className='sidebarItem__txt'>{page?.title}</div>
          </div>
        ))}
      </List>

      <div className='sidebarAdminDrawer__lower'>
        <CFWButton title='Log out' isWhite={true} onClick={logoutAdmin} />
      </div>
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <Navbar isAdmin={true} />
      <Box
        component='nav'
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label='mailbox folders'
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant='temporary'
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant='permanent'
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              background: "#1A1C1E",
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component='main'
        sx={{
          flexGrow: 1,
          p: 3,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          background: "#F7F7F7",
          minHeight: "100vh",
        }}
      >
        <Toolbar />
        {children}
      </Box>
    </Box>
  );
}

RPageWithSideBar.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default RPageWithSideBar;
