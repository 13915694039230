import React from "react";

const OptionQuestionsBottom = ({ option }) => {
  switch (option) {
    case "abilitytoreflect":
      return (
        <div className='optionQuestions__bottom'>
          <label htmlFor='pics'>Choice of Pictures</label>
          <input type='file' />
        </div>
      );

    case "selforganizedlearning":
      return (
        <div className='optionQuestions__bottom'>
          <label htmlFor='clinicalpics'>Choice of Clinical Pics</label>
          <select name='clinicalpics' id=''>
            <option value='KR1'>KR1</option>
            <option value='KR2'>KR2</option>
            <option value='KR3'>KR3</option>
          </select>
        </div>
      );

    default:
      break;
  }

  return null;
};

export default OptionQuestionsBottom;
