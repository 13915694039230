import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import useTalks from "../../hooks/useTalks";
import Loading from "./Loading";
import NursingPhenomenaCheckBox from "./NursingPhenomenaCheckBox";

const NursingPhenomenaContent = () => {
  const location = useLocation();
  const splitted = location.pathname.split("/");
  const _student = splitted[splitted.length - 1];

  const { getNursingPhenomenonData } = useTalks();

  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getInitialData();
  }, []);

  const getInitialData = async () => {
    const response = await getNursingPhenomenonData(_student);

    console.log(response);

    if (response) setCategories([...response.categories]);
    setLoading(false);
  };

  if (loading) return <Loading />;

  return (
    <section className='nursingPhenomena__body__container__content'>
      <div className='nursingPhenomena__body__container__content__left'>
        {categories.map(cat => (
          <div
            className='nursingPhenomena__body__container__content__left__checkBoxes'
            key={cat._id}
          >
            <p className='nursingPhenomena__body__container__content__left__checkBoxes__heading'>
              {cat?.title}
            </p>
            <div className='nursingPhenomena__body__container__content__left__checkBoxes__container'>
              {cat?.options.map(op => (
                <NursingPhenomenaCheckBox
                  label={op.title}
                  key={op._id}
                  name={op._id}
                  _option={op._id}
                  _student={_student}
                  _competencyCat={cat._id}
                  checked={cat?.competencyTest?._categoryOption === op._id}
                />
              ))}
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default NursingPhenomenaContent;
