import React from 'react'
import TrainingTable from '../../components/Common/TrainingTable';
import Navbar from '../../components/Navbar'
import MetainteractionsTable from '../../components/Common/MetainteractionsTable';
import FixedPointsTable from '../../components/Common/FixedPointsTable';
import TutorialTable from '../../components/Common/TutorialTable';
import CompetencyTestTable from '../../components/Common/CompetencyTestTable';
import EvaluationTable from '../../components/Common/EvaluationTable';

const ProofOfPractice = () => {
  return (
    <>
      <Navbar />
      <section className='proofOfPractice'>
        <section className='proofOfPractice__body'>
          <h3 className='proofOfPractice__body__heading'>Evidence of training:</h3>
          <TrainingTable />
          <MetainteractionsTable />
          <FixedPointsTable />
          <TutorialTable />
          <CompetencyTestTable />
          <div className='proofOfPractice__body__evaluationHeading'>
            <p>Evaluation of the orientation assignment overall grade:</p>
          </div>
          <EvaluationTable />
        </section>
      </section>
    </>
  )
}
export default ProofOfPractice;