import React from 'react'

const PdfLink = ({ name, path }) => {
  return (
    <a target="_blank" rel="noopener noreferrer" href={path} className='accompanyingMaterial__body__container__pdfLink'>
      <p>{name}</p>
    </a>
  )
}

export default PdfLink