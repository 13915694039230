import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import moment from "moment";
import Page from "../../components/Page";
import useAuthenticate from "../../hooks/useAuthenticate";
import useStudent from "../../hooks/useStudent";

const Comments = () => {
  const location = useLocation();
  const { isAuthenticated } = useAuthenticate();
  const { commentStudent, getComments } = useStudent();
  const user = isAuthenticated(false, ["teacher", "student"]);

  const [_student, setStudent] = useState("");
  const [comments, setComments] = useState([]);

  const [comment, setComment] = useState();

  useEffect(() => {
    if (!_student) {
      let splitted = location.pathname.split("/");
      let studentId = splitted[splitted.length - 1];
      return setStudent(studentId);
    }
    getInitialData();
  }, [_student]);

  const getInitialData = async () => {
    const response = await getComments(_student);
    if (response) setComments([...response.comments]);
  };

  const commentNow = async () => {
    if (user.user.userType !== "teacher" || !comment) return;
    const response = await commentStudent(user.user._id, _student, comment);
    if (response) setComments([response.comment, ...comments]);
  };

  return (
    <Page>
      <div className='commentsContainer'>
        <div className='commentsContainer__displayContainer'>
          <div className='commentsContainer__displayContainer__heading'>
            <h1>Kommentare</h1>
          </div>
          {comments.map(com => (
            <div className='commentsContainer__displayContainer__box'>
              <span className='commentsContainer__displayContainer__box__username'>
                {com._teacher?.name}
              </span>
              <p className='commentsContainer__displayContainer__box__comment'>
                {com.comment}
              </p>
              <p className='commentsContainer__displayContainer__box__time'>
                {moment(com.createdAt).format("Do-MMMM-YYYY, h:mm a")}
              </p>
            </div>
          ))}
        </div>
        <div className='commentsContainer__addComment'>
          <textarea
            placeholder='Leave Comment'
            className='commentsContainer__addComment__input'
            onChange={e => setComment(e.target.value)}
          />
          <button
            className='commentsContainer__addComment__button'
            onClick={commentNow}
          >
            Senden
          </button>
        </div>
      </div>
    </Page>
  );
};

export default Comments;
