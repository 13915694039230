import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import moment from "moment";
import OptionBox from "../../components/Common/OptionBox";
import Page from "../../components/Page";
import useUser from "../../hooks/useUser";

const Home = () => {
  const location = useLocation();

  const { getUserById } = useUser();
  const [student, setStudent] = useState();

  let splitted = location.pathname.split("/");
  let _student = splitted[splitted.length - 1];

  useEffect(() => {
    getUserById(_student).then(res => {
      console.log(res.user);
      if (res.user.userType === "student") setStudent(res.user);
    });
  }, []);

  return (
    <Page>
      <div className='homeContainer'>
        <div className='homeContainer__top'></div>

        <div className='homeContainer__info'>
          <div className='homeContainer__info__row'>
            <div className='homeContainer__info__row__item'>
              <h4>Auszubildende/r:</h4>
              <span>
                {student?.firstName} {student?.name}
              </span>
            </div>
            <div className='homeContainer__info__row__item'>
              <h4>Email Adresse:</h4>
              <span>{student?.email}</span>
            </div>
          </div>
          <div className='homeContainer__info__row'>
            <div className='homeContainer__info__row__item'>
              <h4>Geburtsdatum:</h4>
              <span>
                {student?.dob ? moment(student?.dob).format("DD-MM-YYYY") : "-"}
              </span>
            </div>
            <div className='homeContainer__info__row__item'>
              <h4>Telefonnummer:</h4>
              <span>{student?.contact || "-"}</span>
            </div>
          </div>
        </div>

        <div className='homeContainer__options'>
          <OptionBox
            title='Beurteilungsbögen'
            path={`/assessmentforms/${_student}`}
          />
          <OptionBox
            title='Gespräche'
            path={`/listingOptions/talks/${_student}`}
          />
          <OptionBox
            title='Ausbildungsportfolio'
            path={`/listingOptions/trainingportfolio/${_student}`}
          />
          <OptionBox title='Logs' path='/listingOptions/logs' />
          <OptionBox
            title='KILIAN-Kompetenzmodell'
            path={'/competenceprofile/${_student}'}
          />
          <OptionBox
            title='Praxisnachweise'
            path={'/listingOptions/proofOfPractice'}
          />
          <OptionBox
            title='KILIAN Uploads'
            path={`/listingOptions/kilianUploads/${_student}`}
          />
          <OptionBox title='Reflection' path='/listingOptions/reflection' />
        </div>

        {/* <div className='homeContainer__boxes-R1'>
          <div
            className='homeContainer__boxes-R1__leftContainer'
            onClick={() => navigate(`/evaluationform/${_student}`)}
          >
            <p>Evaluation Form</p>
            <img src={require("../../assets/homePage.png")} alt='' />
          </div>
          <div
            className='homeContainer__boxes-R1__rightContainer'
            onClick={() => navigate(`/reviewform/${_student}`)}
          >
            <p>Review Talks</p>
            <img src={require("../../assets/homePage.png")} alt='' />
          </div>
        </div>
        <div className='homeContainer__boxes-R2'>
          <div className='homeContainer__boxes-R2__container'>
            <p>Excersizes Kilian</p>
            <img src={require("../../assets/homePage.png")} alt='' />
          </div>
        </div> */}
      </div>
    </Page>
  );
};
export default Home;
