import React from "react";
import { useLocation } from "react-router";
import OptionBox from "../../components/Common/OptionBox";
import Navbar from "../../components/Navbar";
import KilianUploads from "../KilianUploads";
import TrainingPortfolio from "../TrainingPortfolio";
import TopContent from "./components/TopContent";
import { listingOptions } from "./helpers/listing";

const ListingOptions = () => {
  const location = useLocation();
  let splittedLoc = location.pathname.split("/");
  let _student = splittedLoc[splittedLoc.length - 1];
  let option = splittedLoc[splittedLoc.length - 2];

  return (
    <>
      {option === "trainingportfolio" ? (
        <TrainingPortfolio _student={_student} />
      ) : (
        <>
          <Navbar />
          {option === "proofOfPractice" && (
            <div className='listingOptionsPractice'>
              <h3>Orientierungseinsatz</h3>
            </div>
          )}
          <div className='listingOptions'>
            <TopContent option={option} />
            {listingOptions[option]?.map(item => (
              <OptionBox title={item.title} path={`${item.path}/${_student}`} />
            ))}
          </div>
          {option === "proofOfPractice" && (
            <>
              <div className='listingOptionsPractice2'>
                <h3>Compulsory and specialization assignments</h3>
              </div>
              <div className='listingOptions'>
                <TopContent option={option} />
                {listingOptions[option]?.map(item => (
                  <OptionBox
                    title={item.title}
                    path={`${item.path}/${_student}`}
                  />
                ))}
              </div>
            </>
          )}

          {option === "kilianUploads" && <KilianUploads />}
        </>
      )}
    </>
  );
};

export default ListingOptions;
