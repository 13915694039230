import React from 'react'

const LogsReviews = () => {
  return (
    <>
      <section className='logsReviews'>
        <div className='logsReviews__body'>
          <header className='logsReviews__body__header'>
            <div className='logsReviews__body__header__logo'>
              <img src={require("../../assets/Logo.png")} alt="" />
              <h1>Protokolle und Beurteilungen</h1>
            </div>
          </header>
          <div className='logsReviews__body__container'>
            <div className='logsReviews__body__container__logs'>
              <h3>Protokolle</h3>
              <div className='logsReviews__body__container__logs__box'></div>
            </div>
            <div className='logsReviews__body__container__reviews'>
              <h3>Beurteilungen</h3>
              <div className='logsReviews__body__container__reviews__box'></div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default LogsReviews;