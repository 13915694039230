import axios from "axios";
import { BACKEND_API_URL } from "../helpers/variables";
import useAuthenticate from "./useAuthenticate";
import useHandleErrors from "./useHandleErrors";

const useStudent = () => {
  const { handleErrors } = useHandleErrors();
  const { isAuthenticated } = useAuthenticate();
  const user = isAuthenticated(false, ["supervisor", "teacher"]);
  const addClass = async className => {
    if (user.user.userType !== "supervisor")
      return alert("Only Supervisor is allowed to add classes!");
    try {
      const response = await axios.post(`${BACKEND_API_URL}/grade/create`, {
        className,
        _teacher: user.user._id,
      });
      console.log(response.data);
      return response.data;
    } catch (err) {
      handleErrors(err);
    }
  };

  const getClasses = async () => {
    try {
      const response = await axios.get(
        `${BACKEND_API_URL}/grade/${user?.user?._id}`
      );
      return response.data;
    } catch (err) {
      handleErrors(err);
    }
  };

  const getNotJoinedClasses = async () => {
    console.log(user);

    try {
      const response = await axios.get(
        `${BACKEND_API_URL}/grade/notJoined/${user?.user?._id}`
      );
      console.log(response.data);
      return response.data;
    } catch (err) {
      handleErrors(err);
    }
  };

  const joinClass = async _grade => {
    try {
      const response = await axios.post(
        `${BACKEND_API_URL}/grade/joinClass/${user?.user?._id}/${_grade}`
      );
      console.log(response.data);
      return response.data;
    } catch (err) {
      handleErrors(err);
    }
  };

  const getAllClasses = async () => {
    try {
      const response = await axios.post(`${BACKEND_API_URL}/grade/allClasses`);
      console.log(response.data);
      return response.data;
    } catch (err) {
      handleErrors(err);
    }
  };

  const getClassStudents = async _grade => {
    try {
      const response = await axios.get(
        `${BACKEND_API_URL}/user/students/${_grade}`
      );
      console.log(response.data);
      return response.data;
    } catch (err) {
      handleErrors(err);
    }
  };

  const getStudentForms = async _student => {
    console.log(_student);
    try {
      const response = await axios.get(
        `${BACKEND_API_URL}/home/forms/${_student}`
      );
      console.log(response.data);
      return response.data;
    } catch (err) {
      handleErrors(err);
    }
  };
  const getFormItems = async _form => {
    try {
      const response = await axios.get(
        `${BACKEND_API_URL}/home/items/${_form}`
      );
      console.log(response.data);
      return response.data;
    } catch (err) {
      handleErrors(err);
    }
  };

  const getItems = async _id => {
    try {
      const response = await axios.get(
        `${BACKEND_API_URL}/home/getItems/${_id}`
      );
      console.log(response.data);
      return response.data;
    } catch (err) {
      handleErrors(err);
    }
  };
  const getReviews = async _student => {
    try {
      const response = await axios.get(
        `${BACKEND_API_URL}/home/reviews/${_student}`
      );
      console.log(response.data);
      return response.data;
    } catch (err) {
      handleErrors(err);
    }
  };
  const getReviewByID = async _review => {
    try {
      const response = await axios.get(
        `${BACKEND_API_URL}/home/getReview/${_review}`
      );
      console.log(response.data);
      return response.data;
    } catch (err) {
      handleErrors(err);
    }
  };

  const getItem = async _id => {
    try {
      const response = await axios.get(`${BACKEND_API_URL}/home/item/${_id}`);
      console.log(response.data);
      return response.data;
    } catch (err) {
      handleErrors(err);
    }
  };

  const getItemById = async _item => {
    try {
      const response = await axios.get(
        `${BACKEND_API_URL}/home/getItem/${_item}`
      );
      console.log(response.data);
      return response.data;
    } catch (err) {
      handleErrors(err);
    }
  };

  const updatePoints = async (_item, _point) => {
    try {
      const response = await axios.put(`${BACKEND_API_URL}/home/updatePoint`, {
        _item,
        _point,
      });
      console.log(response.data);
      return response.data;
    } catch (err) {
      handleErrors(err);
    }
  };

  const updateItem = async item => {
    try {
      const response = await axios.put(`${BACKEND_API_URL}/home/item/update`, {
        item,
      });
      console.log(response.data);
      return response.data;
    } catch (err) {
      handleErrors(err);
    }
  };
  const updateReview = async review => {
    try {
      const response = await axios.put(`${BACKEND_API_URL}/home/review`, {
        review,
      });
      console.log(response.data);
      return response.data;
    } catch (err) {
      handleErrors(err);
    }
  };

  const commentStudent = async (_teacher, _student, comment) => {
    try {
      const response = await axios.post(`${BACKEND_API_URL}/user/comment`, {
        _teacher,
        _student,
        comment,
      });
      console.log(response.data);
      return response.data;
    } catch (err) {
      handleErrors(err);
    }
  };
  const getComments = async _student => {
    try {
      const response = await axios.get(
        `${BACKEND_API_URL}/user/comments/${_student}`
      );
      console.log(response.data);
      return response.data;
    } catch (err) {
      handleErrors(err);
    }
  };

  const startKilianTask = async data => {
    try {
      const response = await axios.post(
        `${BACKEND_API_URL}/user/talk/kilianTask/`,
        data
      );
      console.log(response.data);
      return response.data;
    } catch (err) {
      handleErrors(err);
    }
  };

  const getKilianTask = async (kilian_type, _student) => {
    try {
      const response = await axios.get(
        `${BACKEND_API_URL}/user/talk/kilianTask/${kilian_type}/${_student}`
      );
      console.log(response.data);
      return response.data;
    } catch (err) {
      handleErrors(err);
    }
  };

  const createTalk = async data => {
    try {
      const response = await axios.post(`${BACKEND_API_URL}/user/talk`, data);
      console.log(response.data);
      return response.data;
    } catch (err) {
      handleErrors(err);
    }
  };

  const getTalk = async (_student, talkType) => {
    try {
      const response = await axios.get(
        `${BACKEND_API_URL}/user/talk/${_student}/${talkType}`
      );
      console.log(response.data);
      return response.data;
    } catch (err) {
      console.log(err);
      // handleErrors(err);
    }
  };

  const addStudentConfirmationToTalk = async (_talk, studentConfirmation) => {
    try {
      const response = await axios.put(
        `${BACKEND_API_URL}/user/talk/${_talk}`,
        { studentConfirmation }
      );
      return response.data;
    } catch (err) {
      console.log(err);
      // handleErrors(err);
    }
  };

  return {
    addClass,
    getClasses,
    getClassStudents,
    getAllClasses,
    getStudentForms,
    getFormItems,
    getItems,
    getItem,
    updateItem,
    getReviews,
    getReviewByID,
    updateReview,
    getItemById,
    updatePoints,
    commentStudent,
    getComments,
    startKilianTask,
    getKilianTask,
    createTalk,
    getTalk,
    addStudentConfirmationToTalk,
    getNotJoinedClasses,
    joinClass,
  };
};

export default useStudent;
