import React from "react";

const NursingPhenomenaCheckBox = ({
  label,
  _competencyCat,
  _option,
  _student,
  checked,
  name,
}) => {
  console.log(checked);

  return (
    <div className='nursingPhenomenaCheckBox'>
      <div className='nursingPhenomenaCheckBox__checkBox'>
        <input type='radio' checked={checked} name={name} />
        <label>{label}</label>
      </div>
      <div className='nursingPhenomenaCheckBox__explanantionButton'>
        <a
          href={`/nursingphenomena/${_student}/${_competencyCat}/${_option}`}
          target='_blank'
        >
          Explanation
        </a>
      </div>
    </div>
  );
};

export default NursingPhenomenaCheckBox;
