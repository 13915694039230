import axios from "axios";
import React from "react";
import {
  ADMIN_STORAGE_KEY,
  BACKEND_API_URL,
  USER_STORAGE_KEY,
} from "../helpers/variables";
import useHandleErrors from "./useHandleErrors";

const useAuthenticate = () => {
  const { handleErrors } = useHandleErrors();

  const login = async user => {
    try {
      const response = await axios.post(
        `${BACKEND_API_URL}/user/auth/login`,
        user
      );

      return response.data;
    } catch (err) {
      handleErrors(err);
    }
  };

  const register = async user => {
    try {
      const response = await axios.post(
        `${BACKEND_API_URL}/user/auth/register`,
        user
      );

      return response.data;
    } catch (err) {
      handleErrors(err);
    }
  };

  const adminLogin = async admin => {
    try {
      const response = await axios.post(
        `${BACKEND_API_URL}/admin/auth/login`,
        admin
      );

      return response.data;
    } catch (err) {
      handleErrors(err);
    }
  };

  const isAuthenticated = (isAdmin, types = []) => {
    console.log(types);
    const user = JSON.parse(
      localStorage.getItem(isAdmin ? ADMIN_STORAGE_KEY : USER_STORAGE_KEY)
    );

    console.log(
      isAdmin ? user : types.includes(user?.user?.userType) ? user : null
    );
    return isAdmin ? user : types.includes(user?.user?.userType) ? user : null;
  };

  return { login, register, isAuthenticated, adminLogin };
};

export default useAuthenticate;
