import React, { useState, useEffect } from "react";
import Navbar from "../../components/Navbar";
import useAssessment from "../../hooks/useAssessment";

let grading = {
  1: "Competence achieved to a particularly high degree",
  2: "Competence fully achieved",
  3: "Competence achieved in general",
  4: "Competence basically meets the requirements",
  5: "Competence is fraught with major shortcomings",
};

const OptionQuestionAnswer = ({
  intro,
  options,
  _competencyAssessmentCategoryTest,
  _student,
}) => {
  const { createAssessment, getAssessment } = useAssessment();

  const [assessment, setAssessment] = useState();
  const [grade, setGrade] = useState();
  const [selectedOption, setSelectedOption] = useState();
  const [submitted, setSubmitted] = useState(false);

  useEffect(() => {
    getInitialData();
  }, []);

  console.log(_competencyAssessmentCategoryTest, _student);

  const getInitialData = async () => {
    const response = await getAssessment(
      _competencyAssessmentCategoryTest,
      _student
    );
    if (response) {
      console.log(response?.assessment);
      setAssessment(response?.assessment);
      setGrade(response?.assessment?.grade);
      setSelectedOption(
        response?.assessment?._competencyAssessmentCategoryOption
      );
    }
  };

  const submitTest = async e => {
    if (!e.target.checked || submitted) return;

    if (!grade) return alert("Please select an option!");

    setSubmitted(true);
    const _competencyAssessmentCategoryOption = options.find(
      op => op.grade == grade
    )?._id;

    if (!_competencyAssessmentCategoryOption) return;

    const data = {
      _competencyAssessmentCategoryTest,
      _competencyAssessmentCategoryOption,
      _student,
    };

    const response = await createAssessment(data);

    if (response) {
      console.log(response.assessment);
      setAssessment(response?.assessment);
      setGrade(response?.assessment?.grade);
      alert("Assessment submitted!");
    }

    console.log(
      _competencyAssessmentCategoryTest
      // _competencyAssessmentCategoryOption
    );
  };

  const changeHandler = (event, _option) => {
    console.log(submitted, assessment);
    if (submitted || assessment) return;
    setGrade(parseInt(event.target.value));
    setSelectedOption(_option);
  };

  console.log(options);

  return (
    <>
      <Navbar />
      <div className='optionQuestionAnswers'>
        <div className='optionQuestionAnswers__box'>
          <div className='optionQuestionAnswers__box__intro'>{intro}</div>
        </div>

        <div className='optionQuestionAnswers__box__items'>
          {options?.map(item => (
            <div className='optionQuestionAnswers__box__items__item'>
              <div className='optionQuestionAnswers__box__items__item__left'>
                {grading[item?.grade]}
                {/* {item.title} */}
              </div>
              <div className='optionQuestionAnswers__box__items__item__middle'>
                {item?.desc}
              </div>
              <div className='optionQuestionAnswers__box__items__item__right'>
                <input
                  type='radio'
                  id={`grade-${item.grade}`}
                  name='grade'
                  value={item.grade}
                  checked={item._id === selectedOption}
                  onChange={e => changeHandler(e, item._id)}
                />
              </div>
            </div>
          ))}
        </div>

        <div className='optionQuestionAnswers__confirm'>
          <input
            type='checkbox'
            name='submit'
            id='submit'
            onChange={submitTest}
            value={submitted}
          />
          I hereby confirm that the above selected evaluation is final, and
          corresponds to the achievements of the trainee.
        </div>
      </div>
    </>
  );
};

export default OptionQuestionAnswer;
