import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import Loading from "../../components/Common/Loading";
import useTalks from "../../hooks/useTalks";

const NursingPhenomenonOption = () => {
  const location = useLocation();
  const splittedLoc = location.pathname.split("/");
  const _option = splittedLoc[splittedLoc.length - 1];
  const _competencyCat = splittedLoc[splittedLoc.length - 2];
  const _student = splittedLoc[splittedLoc.length - 3];

  const [optionDetails, setOptionDetails] = useState();
  const [competencyTest, setCompetencyTest] = useState();
  const [loading, setLoading] = useState(true);

  const { getNursingPhenomenonTests, getCompetencyTestByCategory } = useTalks();

  useEffect(() => {
    getInitialData();
  }, []);

  const getInitialData = async () => {
    const [testsRes, competencyTest] = await Promise.all([
      getNursingPhenomenonTests(_option),
      getCompetencyTestByCategory(_competencyCat, _student),
    ]);
    if (testsRes) setOptionDetails({ ...testsRes });
    if (competencyTest) setCompetencyTest(competencyTest.test);
    setLoading(false);
  };

  if (loading) return <Loading />;

  return (
    <section className='nursingPhenomena'>
      <div className='nursingPhenomena__body'>
        <header className='nursingPhenomena__body__header'>
          <img src={require("../../assets/Logo.png")} alt='' />
          <h2>{optionDetails?.title}</h2>
        </header>
        <div className='nursingPhenomena__body__container'>
          <SelectOptionsBoxes
            options={optionDetails?.tests || []}
            _option={_option}
            _student={_student}
            _competencyCat={_competencyCat}
            competencyTest={competencyTest}
          />
        </div>
      </div>
    </section>
  );
};

const SelectOptionsBoxes = ({
  options,
  _option,
  _student,
  _competencyCat,
  competencyTest,
}) => {
  const navigate = useNavigate();

  const navigateToSubTests = _test =>
    navigate(
      `/nursingphenomena/subtests/${_student}/${_competencyCat}/${_option}/${_test}`
    );

  return (
    <div className='selectedOptionsBoxes'>
      {options.map(op => (
        <SelectOptionsBox
          option={op}
          key={op._id}
          onClick={() => navigateToSubTests(op._id)}
          checked={competencyTest?._test === op._id}
        />
      ))}
    </div>
  );
};

const SelectOptionsBox = ({ option, onClick, checked }) => {
  return (
    <div className='selectedOptionsBoxes__box'>
      <div className='selectedOptionsBoxes__box__row'>
        <input type='radio' name='box' id='box' checked={checked} />
        <h3>{option?.title}</h3>
      </div>
      <div className='selectedOptionsBoxes__box__row'>
        <button onClick={onClick}>Wählen</button>
      </div>
    </div>
  );
};

export default NursingPhenomenonOption;
