import React from "react";
import { useNavigate } from "react-router";

const QuestionBox = ({ question }) => {
  const navigate = useNavigate();

  const navigateToAnswers = () => {
    if (!question.path) return;

    if (question.path.includes("sub"))
      navigate("/optionquestionanswer/abilitytoact2");

    navigate(question.path);
  };
  return (
    <div className='questionBox' onClick={navigateToAnswers}>
      <span>{question.question}</span>
      <div className='questionBox__bottom'>
        <input type='checkbox' name='' id='' />
      </div>
    </div>
  );
};

export default QuestionBox;
