import React, { useState } from "react";
import Logo from "../../../assets/Logo.svg";
import { Box, Button } from "@mui/material";
import { useNavigate } from "react-router";
import useAuthenticate from "../../../hooks/useAuthenticate";
import { USER_STORAGE_KEY } from "../../../helpers/variables";
import Navbar from "../../../components/Navbar";

const SignIn = () => {
  const navigate = useNavigate();
  const { login } = useAuthenticate();

  const [details, setDetails] = useState({
    email: "",
    password: "",
  });
  const changeHandler = e => {
    const { name, value } = e.target;
    setDetails({ ...details, [name]: value });
  };

  const submitHandler = async () => {
    const formValid = validateForm();
    if (!formValid) return alert("Bitte alle Felder richtig ausfüllen!");

    try {
      const response = await login(details);
      if (response) {
        localStorage.setItem(USER_STORAGE_KEY, JSON.stringify(response));

        navigate(
          response.userType === "teacher"
            ? "/classes"
            : response.userType === "student"
            ? `/${response.user._id}`
            : "/classes"
        );
      }
    } catch (err) {
      console.log(err);
    }
  };

  const validateForm = () => {
    for (const prop in details) {
      if (!details[prop]) return false;
    }
    return true;
  };

  return (
    <>
      <Navbar />
      <div className='signInContainer'>
        <div className='signInContainer__header'>
          <Box className='signInContainer__header__logo'>
            <hr />
            <img src={Logo} alt='' />
          </Box>
          {/* <img src={Logo} alt='' /> */}
        </div>
        <div className='signInContainer__fields'>
          <input
            type='email'
            placeholder='Email-Adresse'
            name='email'
            className='signInContainer__fields__input'
            onChange={changeHandler}
          />
          <input
            type='password'
            name='password'
            placeholder='Passwort'
            className='signInContainer__fields__input'
            onChange={changeHandler}
          />
          <Button onClick={submitHandler}>Sign In</Button>
          <span className='blockedSpanLink' onClick={() => navigate("/signup")}>
          <center>Haben Sie noch kein Konto?<br></br></center>
              <center>Account erstellen!</center>
          </span>
        </div>

        {/* <div className='signInContainer__note'>
          <p>Fur Demonstration hier den Usertyp auswahlen</p>
        </div> */}
      </div>
    </>
  );
};

export default SignIn;
