import React from "react";

const CSmButton = ({ title, onClick, style, disabled }) => {
  return (
    <button
      onClick={onClick}
      className='cSmButton'
      style={
        disabled
          ? { ...style, background: "gray", color: "white" }
          : { ...style }
      }
      disabled={disabled}
    >
      {title}
    </button>
  );
};

export default CSmButton;
