import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import Table from "../../components/Common/Table";
import Navbar from "../../components/Navbar";
import useAdmin from "../../hooks/useAdmin";
import useTeacher from "../../hooks/useTeacher";
import BottomCards from "./BottomCards";

const TrainingPortfolio = ({ _student }) => {
  const { getTrainingPortfolioCategories } = useTeacher();

  const navigate = useNavigate();
  const [trainingCategories, setTrainingCategories] = useState([]);
  const [openedCategory, setOpenedCategory] = useState();

  useEffect(() => {
    getTrainingPortfolioCategories(_student).then(res => {
      console.log(res);
      setTrainingCategories([...res.categories]);
    });
  }, []);

  const selectCategory = _category => setOpenedCategory(_category);

  return (
    <>
      <Navbar />
      <div className='trainingPortfolio'>
        <h1>Training portfolio for the</h1>
        <h1> practical nursing training</h1>
        <h2>In accordance with the nationwide Nursing Professions Act PflBG</h2>

        <div className='trainingPortfolio__form'>
          <div className='trainingPortfolio__form__row'>
            <div className='trainingPortfolio__form__row__left'>
              <b>Trainee</b>
            </div>
            <div className='trainingPortfolio__form__row__right'>
              <div className='trainingPortfolio__form__row__right__upper'>
                <hr />
              </div>
              <div
                className='trainingPortfolio__form__row__right__lower'
                style={{ marginBottom: "1.5rem" }}
              >
                <label htmlFor=''>Surname, firstname</label>
              </div>
            </div>
          </div>
          <div className='trainingPortfolio__form__row'>
            <div className='trainingPortfolio__form__row__left'></div>
            <div className='trainingPortfolio__form__row__right'>
              <div className='trainingPortfolio__form__row__right__upper'>
                <hr />
              </div>
              <div className='trainingPortfolio__form__row__right__lower'></div>
            </div>
          </div>
          <div className='trainingPortfolio__form__row'>
            <div className='trainingPortfolio__form__row__left'></div>
            <div className='trainingPortfolio__form__row__right'>
              <div className='trainingPortfolio__form__row__right__upper'>
                <hr />
              </div>
              <div className='trainingPortfolio__form__row__right__lower'></div>
            </div>
          </div>
          <div
            className='trainingPortfolio__form__row'
            style={{ marginBottom: "1.5rem" }}
          >
            <div className='trainingPortfolio__form__row__left'></div>
            <div className='trainingPortfolio__form__row__right'>
              <div className='trainingPortfolio__form__row__right__upper'>
                <hr />
              </div>
              <div className='trainingPortfolio__form__row__right__lower'>
                <label htmlFor=''>Address</label>
              </div>
            </div>
          </div>
          <div
            className='trainingPortfolio__form__row'
            style={{ marginBottom: "1rem" }}
          >
            <div className='trainingPortfolio__form__row__left'></div>
            <div className='trainingPortfolio__form__row__right'>
              <div className='trainingPortfolio__form__row__right__upper'>
                <hr />
              </div>
              <div className='trainingPortfolio__form__row__right__lower'>
                <label htmlFor='' className='biggerLabel'>
                  Telephone
                </label>
              </div>
            </div>
          </div>
          <div
            className='trainingPortfolio__form__row'
            // style={{ marginBottom: "1rem" }}
          >
            <div className='trainingPortfolio__form__row__left'></div>
            <div className='trainingPortfolio__form__row__right'>
              <div className='trainingPortfolio__form__row__right__upper'>
                <hr />
              </div>
              <div className='trainingPortfolio__form__row__right__lower'>
                <label htmlFor='' className='biggerLabel'>
                  Enamel
                </label>
              </div>
            </div>
          </div>
          <div
            className='trainingPortfolio__form__row'
            style={{ marginBottom: "1.5rem" }}
          >
            <div className='trainingPortfolio__form__row__left'></div>
            <div className='trainingPortfolio__form__row__right'>
              <div className='trainingPortfolio__form__row__right__upper'></div>
              <div className='trainingPortfolio__form__row__right__lower'>
                <label htmlFor='' className='smallLabel'>
                  Change of address must be communicated immediately to the
                  school administration / training company
                </label>
              </div>
            </div>
          </div>

          <div className='trainingPortfolio__form__row'>
            <div className='trainingPortfolio__form__row__left'>
              <b>Nursing School</b>
            </div>
            <div className='trainingPortfolio__form__row__right'>
              <div className='trainingPortfolio__form__row__right__upper'>
                <hr />
              </div>
              <div
                className='trainingPortfolio__form__row__right__lower'
                style={{ marginBottom: "1.5rem" }}
              >
                <label htmlFor=''>course management</label>
              </div>
            </div>
          </div>

          <div className='trainingPortfolio__form__row'>
            <div className='trainingPortfolio__form__row__left'>
              <b>Practical carrier of Education:</b>
            </div>
            <div className='trainingPortfolio__form__row__right'>
              <div className='trainingPortfolio__form__row__right__upper'>
                <hr />
              </div>
              <div
                className='trainingPortfolio__form__row__right__lower'
                style={{ marginBottom: "1.5rem" }}
              >
                <label htmlFor=''>Name of the enterprise</label>
              </div>
            </div>
          </div>

          <div className='trainingPortfolio__form__row'>
            <div className='trainingPortfolio__form__row__left'></div>
            <div className='trainingPortfolio__form__row__right'>
              <div className='trainingPortfolio__form__row__right__upper'>
                <hr />
              </div>
              <div className='trainingPortfolio__form__row__right__lower'></div>
            </div>
          </div>
          <div className='trainingPortfolio__form__row'>
            <div className='trainingPortfolio__form__row__left'></div>
            <div className='trainingPortfolio__form__row__right'>
              <div className='trainingPortfolio__form__row__right__upper'>
                <hr />
              </div>
              <div className='trainingPortfolio__form__row__right__lower'></div>
            </div>
          </div>
          <div
            className='trainingPortfolio__form__row'
            style={{ marginBottom: "1.5rem" }}
          >
            <div className='trainingPortfolio__form__row__left'></div>
            <div className='trainingPortfolio__form__row__right'>
              <div className='trainingPortfolio__form__row__right__upper'>
                <hr />
              </div>
              <div className='trainingPortfolio__form__row__right__lower'>
                <label htmlFor=''>Address of company</label>
              </div>
            </div>
          </div>

          <div
            className='trainingPortfolio__form__row'
            style={{ marginBottom: "1.5rem" }}
          >
            <div className='trainingPortfolio__form__row__left'></div>
            <div className='trainingPortfolio__form__row__right'>
              <div className='trainingPortfolio__form__row__right__upper'></div>
              <div className='trainingPortfolio__form__row__right__lower'>
                <label htmlFor='' className='smallLabel'>
                  In the event of a change of company, the school administration
                  must be informed immediately
                </label>
              </div>
            </div>
          </div>

          <div className='trainingPortfolio__form__row'>
            <div className='trainingPortfolio__form__row__left'>
              <b>Specialist assignments</b>
              <p>orientation mission</p>
            </div>
            <div className='trainingPortfolio__form__row__right'>
              <div className='trainingPortfolio__smallDashes'>
                <div
                  className='trainingPortfolio__form__row__right__lower from'
                  style={{ marginBottom: "1.5rem" }}
                >
                  <label htmlFor=''>from the</label>
                  <div className=''>
                    <hr />
                  </div>
                </div>
                <div
                  className='trainingPortfolio__form__row__right__lower until'
                  style={{ marginBottom: "1.5rem" }}
                >
                  <label htmlFor=''>Until</label>
                  <div className=''>
                    <hr />
                  </div>
                </div>
              </div>
              <div className='trainingPortfolio__form__row__right__upper'>
                <hr />
              </div>
              <div
                className='trainingPortfolio__form__row__right__lower'
                style={{ marginBottom: "1.5rem" }}
              >
                <label htmlFor=''>Name of the enterprise</label>
              </div>
            </div>
          </div>
          <div className='trainingPortfolio__form__row'>
            <div className='trainingPortfolio__form__row__left'></div>
            <div className='trainingPortfolio__form__row__right'>
              <div className='trainingPortfolio__form__row__right__upper'>
                <hr />
              </div>
              <div className='trainingPortfolio__form__row__right__lower'></div>
            </div>
          </div>
          <div className='trainingPortfolio__form__row'>
            <div className='trainingPortfolio__form__row__left'></div>
            <div className='trainingPortfolio__form__row__right'>
              <div className='trainingPortfolio__form__row__right__upper'>
                <hr />
              </div>
              <div className='trainingPortfolio__form__row__right__lower'></div>
            </div>
          </div>
          <div
            className='trainingPortfolio__form__row'
            style={{ marginBottom: "1.5rem" }}
          >
            <div className='trainingPortfolio__form__row__left'></div>
            <div className='trainingPortfolio__form__row__right'>
              <div className='trainingPortfolio__form__row__right__upper'>
                <hr />
              </div>
              <div className='trainingPortfolio__form__row__right__lower'>
                <label htmlFor=''>Address of company</label>
              </div>
            </div>
          </div>
          <div
            className='trainingPortfolio__form__row'
            style={{ marginBottom: "1.5rem" }}
          >
            <div className='trainingPortfolio__form__row__left'></div>
            <div className='trainingPortfolio__form__row__right'>
              <div className='trainingPortfolio__form__row__right__upper'>
                <hr />
              </div>
              <div className='trainingPortfolio__form__row__right__lower'>
                <label htmlFor=''>Practice management</label>
              </div>
            </div>
          </div>

          <div className='trainingPortfolio__form__row'>
            <div className='trainingPortfolio__form__row__left'>
              <b>
                long-term <br /> Ambulatory use
              </b>
            </div>
            <div className='trainingPortfolio__form__row__right'>
              <div className='trainingPortfolio__smallDashes'>
                <div
                  className='trainingPortfolio__form__row__right__lower from'
                  style={{ marginBottom: "1.5rem" }}
                >
                  <label htmlFor=''>from the</label>
                  <div className=''>
                    <hr />
                  </div>
                </div>
                <div
                  className='trainingPortfolio__form__row__right__lower until'
                  style={{ marginBottom: "1.5rem" }}
                >
                  <label htmlFor=''>Until</label>
                  <div className=''>
                    <hr />
                  </div>
                </div>
              </div>
              <div className='trainingPortfolio__form__row__right__upper'>
                <hr />
              </div>
              <div
                className='trainingPortfolio__form__row__right__lower'
                style={{ marginBottom: "1.5rem" }}
              >
                <label htmlFor=''>Name of the enterprise</label>
              </div>
            </div>
          </div>
          <div className='trainingPortfolio__form__row'>
            <div className='trainingPortfolio__form__row__left'></div>
            <div className='trainingPortfolio__form__row__right'>
              <div className='trainingPortfolio__form__row__right__upper'>
                <hr />
              </div>
              <div className='trainingPortfolio__form__row__right__lower'></div>
            </div>
          </div>
          <div className='trainingPortfolio__form__row'>
            <div className='trainingPortfolio__form__row__left'></div>
            <div className='trainingPortfolio__form__row__right'>
              <div className='trainingPortfolio__form__row__right__upper'>
                <hr />
              </div>
              <div className='trainingPortfolio__form__row__right__lower'></div>
            </div>
          </div>
          <div
            className='trainingPortfolio__form__row'
            style={{ marginBottom: "1.5rem" }}
          >
            <div className='trainingPortfolio__form__row__left'></div>
            <div className='trainingPortfolio__form__row__right'>
              <div className='trainingPortfolio__form__row__right__upper'>
                <hr />
              </div>
              <div className='trainingPortfolio__form__row__right__lower'>
                <label htmlFor=''>Address of company</label>
              </div>
            </div>
          </div>
          <div
            className='trainingPortfolio__form__row'
            style={{ marginBottom: "1.5rem" }}
          >
            <div className='trainingPortfolio__form__row__left'></div>
            <div className='trainingPortfolio__form__row__right'>
              <div className='trainingPortfolio__form__row__right__upper'>
                <hr />
              </div>
              <div className='trainingPortfolio__form__row__right__lower'>
                <label htmlFor=''>Practice management</label>
              </div>
            </div>
          </div>

          <div className='trainingPortfolio__form__row'>
            <div className='trainingPortfolio__form__row__left'>
              <b>
                acute inpatient <br />
                Mission
              </b>
            </div>
            <div className='trainingPortfolio__form__row__right'>
              <div className='trainingPortfolio__smallDashes'>
                <div
                  className='trainingPortfolio__form__row__right__lower from'
                  style={{ marginBottom: "1.5rem" }}
                >
                  <label htmlFor=''>from the</label>
                  <div className=''>
                    <hr />
                  </div>
                </div>
                <div
                  className='trainingPortfolio__form__row__right__lower until'
                  style={{ marginBottom: "1.5rem" }}
                >
                  <label htmlFor=''>Until</label>
                  <div className=''>
                    <hr />
                  </div>
                </div>
              </div>
              <div className='trainingPortfolio__form__row__right__upper'>
                <hr />
              </div>
              <div
                className='trainingPortfolio__form__row__right__lower'
                style={{ marginBottom: "1.5rem" }}
              >
                <label htmlFor=''>Name of the enterprise</label>
              </div>
            </div>
          </div>
          <div className='trainingPortfolio__form__row'>
            <div className='trainingPortfolio__form__row__left'></div>
            <div className='trainingPortfolio__form__row__right'>
              <div className='trainingPortfolio__form__row__right__upper'>
                <hr />
              </div>
              <div className='trainingPortfolio__form__row__right__lower'></div>
            </div>
          </div>
          <div className='trainingPortfolio__form__row'>
            <div className='trainingPortfolio__form__row__left'></div>
            <div className='trainingPortfolio__form__row__right'>
              <div className='trainingPortfolio__form__row__right__upper'>
                <hr />
              </div>
              <div className='trainingPortfolio__form__row__right__lower'></div>
            </div>
          </div>
          <div
            className='trainingPortfolio__form__row'
            style={{ marginBottom: "1.5rem" }}
          >
            <div className='trainingPortfolio__form__row__left'></div>
            <div className='trainingPortfolio__form__row__right'>
              <div className='trainingPortfolio__form__row__right__upper'>
                <hr />
              </div>
              <div className='trainingPortfolio__form__row__right__lower'>
                <label htmlFor=''>Address of company</label>
              </div>
            </div>
          </div>
          <div
            className='trainingPortfolio__form__row'
            style={{ marginBottom: "1.5rem" }}
          >
            <div className='trainingPortfolio__form__row__left'></div>
            <div className='trainingPortfolio__form__row__right'>
              <div className='trainingPortfolio__form__row__right__upper'>
                <hr />
              </div>
              <div className='trainingPortfolio__form__row__right__lower'>
                <label htmlFor=''>Practice management</label>
              </div>
            </div>
          </div>

          <div className='trainingPortfolio__form__row'>
            <div className='trainingPortfolio__form__row__left'>
              <b>Psychiatric Outreach</b>
            </div>
            <div className='trainingPortfolio__form__row__right'>
              <div className='trainingPortfolio__smallDashes'>
                <div
                  className='trainingPortfolio__form__row__right__lower from'
                  style={{ marginBottom: "1.5rem" }}
                >
                  <label htmlFor=''>from the</label>
                  <div className=''>
                    <hr />
                  </div>
                </div>
                <div
                  className='trainingPortfolio__form__row__right__lower until'
                  style={{ marginBottom: "1.5rem" }}
                >
                  <label htmlFor=''>Until</label>
                  <div className=''>
                    <hr />
                  </div>
                </div>
              </div>
              <div className='trainingPortfolio__form__row__right__upper'>
                <hr />
              </div>
              <div
                className='trainingPortfolio__form__row__right__lower'
                style={{ marginBottom: "1.5rem" }}
              >
                <label htmlFor=''>Name of the enterprise</label>
              </div>
            </div>
          </div>
          <div className='trainingPortfolio__form__row'>
            <div className='trainingPortfolio__form__row__left'></div>
            <div className='trainingPortfolio__form__row__right'>
              <div className='trainingPortfolio__form__row__right__upper'>
                <hr />
              </div>
              <div className='trainingPortfolio__form__row__right__lower'></div>
            </div>
          </div>
          <div className='trainingPortfolio__form__row'>
            <div className='trainingPortfolio__form__row__left'></div>
            <div className='trainingPortfolio__form__row__right'>
              <div className='trainingPortfolio__form__row__right__upper'>
                <hr />
              </div>
              <div className='trainingPortfolio__form__row__right__lower'></div>
            </div>
          </div>
          <div
            className='trainingPortfolio__form__row'
            style={{ marginBottom: "1.5rem" }}
          >
            <div className='trainingPortfolio__form__row__left'></div>
            <div className='trainingPortfolio__form__row__right'>
              <div className='trainingPortfolio__form__row__right__upper'>
                <hr />
              </div>
              <div className='trainingPortfolio__form__row__right__lower'>
                <label htmlFor=''>Address of company</label>
              </div>
            </div>
          </div>
          <div
            className='trainingPortfolio__form__row'
            style={{ marginBottom: "1.5rem" }}
          >
            <div className='trainingPortfolio__form__row__left'></div>
            <div className='trainingPortfolio__form__row__right'>
              <div className='trainingPortfolio__form__row__right__upper'>
                <hr />
              </div>
              <div className='trainingPortfolio__form__row__right__lower'>
                <label htmlFor=''>Practice management</label>
              </div>
            </div>
          </div>

          <div className='trainingPortfolio__form__row'>
            <div className='trainingPortfolio__form__row__left'>
              <b>
                acute inpatient <br />
                Mission
              </b>
            </div>
            <div className='trainingPortfolio__form__row__right'>
              <div className='trainingPortfolio__smallDashes'>
                <div
                  className='trainingPortfolio__form__row__right__lower from'
                  style={{ marginBottom: "1.5rem" }}
                >
                  <label htmlFor=''>from the</label>
                  <div className=''>
                    <hr />
                  </div>
                </div>
                <div
                  className='trainingPortfolio__form__row__right__lower until'
                  style={{ marginBottom: "1.5rem" }}
                >
                  <label htmlFor=''>Until</label>
                  <div className=''>
                    <hr />
                  </div>
                </div>
              </div>
              <div className='trainingPortfolio__form__row__right__upper'>
                <hr />
              </div>
              <div
                className='trainingPortfolio__form__row__right__lower'
                style={{ marginBottom: "1.5rem" }}
              >
                <label htmlFor=''>Name of the enterprise</label>
              </div>
            </div>
          </div>
          <div className='trainingPortfolio__form__row'>
            <div className='trainingPortfolio__form__row__left'></div>
            <div className='trainingPortfolio__form__row__right'>
              <div className='trainingPortfolio__form__row__right__upper'>
                <hr />
              </div>
              <div className='trainingPortfolio__form__row__right__lower'></div>
            </div>
          </div>
          <div className='trainingPortfolio__form__row'>
            <div className='trainingPortfolio__form__row__left'></div>
            <div className='trainingPortfolio__form__row__right'>
              <div className='trainingPortfolio__form__row__right__upper'>
                <hr />
              </div>
              <div className='trainingPortfolio__form__row__right__lower'></div>
            </div>
          </div>
          <div
            className='trainingPortfolio__form__row'
            style={{ marginBottom: "1.5rem" }}
          >
            <div className='trainingPortfolio__form__row__left'></div>
            <div className='trainingPortfolio__form__row__right'>
              <div className='trainingPortfolio__form__row__right__upper'>
                <hr />
              </div>
              <div className='trainingPortfolio__form__row__right__lower'>
                <label htmlFor=''>Address of company</label>
              </div>
            </div>
          </div>
          <div
            className='trainingPortfolio__form__row'
            style={{ marginBottom: "1.5rem" }}
          >
            <div className='trainingPortfolio__form__row__left'></div>
            <div className='trainingPortfolio__form__row__right'>
              <div className='trainingPortfolio__form__row__right__upper'>
                <hr />
              </div>
              <div className='trainingPortfolio__form__row__right__lower'>
                <label htmlFor=''>Practice management</label>
              </div>
            </div>
          </div>

          <div className='trainingPortfolio__form__row'>
            <div className='trainingPortfolio__form__row__left'>
              <b>
                long-term <br />
                stationary use
              </b>
            </div>
            <div className='trainingPortfolio__form__row__right'>
              <div className='trainingPortfolio__smallDashes'>
                <div
                  className='trainingPortfolio__form__row__right__lower from'
                  style={{ marginBottom: "1.5rem" }}
                >
                  <label htmlFor=''>from the</label>
                  <div className=''>
                    <hr />
                  </div>
                </div>
                <div
                  className='trainingPortfolio__form__row__right__lower until'
                  style={{ marginBottom: "1.5rem" }}
                >
                  <label htmlFor=''>Until</label>
                  <div className=''>
                    <hr />
                  </div>
                </div>
              </div>
              <div className='trainingPortfolio__form__row__right__upper'>
                <hr />
              </div>
              <div
                className='trainingPortfolio__form__row__right__lower'
                style={{ marginBottom: "1.5rem" }}
              >
                <label htmlFor=''>Name of the enterprise</label>
              </div>
            </div>
          </div>
          <div className='trainingPortfolio__form__row'>
            <div className='trainingPortfolio__form__row__left'></div>
            <div className='trainingPortfolio__form__row__right'>
              <div className='trainingPortfolio__form__row__right__upper'>
                <hr />
              </div>
              <div className='trainingPortfolio__form__row__right__lower'></div>
            </div>
          </div>
          <div className='trainingPortfolio__form__row'>
            <div className='trainingPortfolio__form__row__left'></div>
            <div className='trainingPortfolio__form__row__right'>
              <div className='trainingPortfolio__form__row__right__upper'>
                <hr />
              </div>
              <div className='trainingPortfolio__form__row__right__lower'></div>
            </div>
          </div>
          <div
            className='trainingPortfolio__form__row'
            style={{ marginBottom: "1.5rem" }}
          >
            <div className='trainingPortfolio__form__row__left'></div>
            <div className='trainingPortfolio__form__row__right'>
              <div className='trainingPortfolio__form__row__right__upper'>
                <hr />
              </div>
              <div className='trainingPortfolio__form__row__right__lower'>
                <label htmlFor=''>Address of company</label>
              </div>
            </div>
          </div>
          <div
            className='trainingPortfolio__form__row'
            style={{ marginBottom: "1.5rem" }}
          >
            <div className='trainingPortfolio__form__row__left'></div>
            <div className='trainingPortfolio__form__row__right'>
              <div className='trainingPortfolio__form__row__right__upper'>
                <hr />
              </div>
              <div className='trainingPortfolio__form__row__right__lower'>
                <label htmlFor=''>Practice management</label>
              </div>
            </div>
          </div>

          <div className='trainingPortfolio__form__row'>
            <div className='trainingPortfolio__form__row__left'>
              <b>pediatric Mission</b>
            </div>
            <div className='trainingPortfolio__form__row__right'>
              <div className='trainingPortfolio__smallDashes'>
                <div
                  className='trainingPortfolio__form__row__right__lower from'
                  style={{ marginBottom: "1.5rem" }}
                >
                  <label htmlFor=''>from the</label>
                  <div className=''>
                    <hr />
                  </div>
                </div>
                <div
                  className='trainingPortfolio__form__row__right__lower until'
                  style={{ marginBottom: "1.5rem" }}
                >
                  <label htmlFor=''>Until</label>
                  <div className=''>
                    <hr />
                  </div>
                </div>
              </div>
              <div className='trainingPortfolio__form__row__right__upper'>
                <hr />
              </div>
              <div
                className='trainingPortfolio__form__row__right__lower'
                style={{ marginBottom: "1.5rem" }}
              >
                <label htmlFor=''>Name of the enterprise</label>
              </div>
            </div>
          </div>
          <div className='trainingPortfolio__form__row'>
            <div className='trainingPortfolio__form__row__left'></div>
            <div className='trainingPortfolio__form__row__right'>
              <div className='trainingPortfolio__form__row__right__upper'>
                <hr />
              </div>
              <div className='trainingPortfolio__form__row__right__lower'></div>
            </div>
          </div>
          <div className='trainingPortfolio__form__row'>
            <div className='trainingPortfolio__form__row__left'></div>
            <div className='trainingPortfolio__form__row__right'>
              <div className='trainingPortfolio__form__row__right__upper'>
                <hr />
              </div>
              <div className='trainingPortfolio__form__row__right__lower'></div>
            </div>
          </div>
          <div
            className='trainingPortfolio__form__row'
            style={{ marginBottom: "1.5rem" }}
          >
            <div className='trainingPortfolio__form__row__left'></div>
            <div className='trainingPortfolio__form__row__right'>
              <div className='trainingPortfolio__form__row__right__upper'>
                <hr />
              </div>
              <div className='trainingPortfolio__form__row__right__lower'>
                <label htmlFor=''>Address of company</label>
              </div>
            </div>
          </div>
          <div
            className='trainingPortfolio__form__row'
            style={{ marginBottom: "1.5rem" }}
          >
            <div className='trainingPortfolio__form__row__left'></div>
            <div className='trainingPortfolio__form__row__right'>
              <div className='trainingPortfolio__form__row__right__upper'>
                <hr />
              </div>
              <div className='trainingPortfolio__form__row__right__lower'>
                <label htmlFor=''>Practice management</label>
              </div>
            </div>
          </div>

          <div className='trainingPortfolio__form__row'>
            <div className='trainingPortfolio__form__row__left'>
              <b>Deepening Mission</b>
            </div>
            <div className='trainingPortfolio__form__row__right'>
              <div className='trainingPortfolio__smallDashes'>
                <div
                  className='trainingPortfolio__form__row__right__lower from'
                  style={{ marginBottom: "1.5rem" }}
                >
                  <label htmlFor=''>from the</label>
                  <div className=''>
                    <hr />
                  </div>
                </div>
                <div
                  className='trainingPortfolio__form__row__right__lower until'
                  style={{ marginBottom: "1.5rem" }}
                >
                  <label htmlFor=''>Until</label>
                  <div className=''>
                    <hr />
                  </div>
                </div>
              </div>
              <div className='trainingPortfolio__form__row__right__upper'>
                <hr />
              </div>
              <div
                className='trainingPortfolio__form__row__right__lower'
                style={{ marginBottom: "1.5rem" }}
              >
                <label htmlFor=''>Name of the enterprise</label>
              </div>
            </div>
          </div>
          <div className='trainingPortfolio__form__row'>
            <div className='trainingPortfolio__form__row__left'></div>
            <div className='trainingPortfolio__form__row__right'>
              <div className='trainingPortfolio__form__row__right__upper'>
                <hr />
              </div>
              <div className='trainingPortfolio__form__row__right__lower'></div>
            </div>
          </div>
          <div className='trainingPortfolio__form__row'>
            <div className='trainingPortfolio__form__row__left'></div>
            <div className='trainingPortfolio__form__row__right'>
              <div className='trainingPortfolio__form__row__right__upper'>
                <hr />
              </div>
              <div className='trainingPortfolio__form__row__right__lower'></div>
            </div>
          </div>
          <div
            className='trainingPortfolio__form__row'
            style={{ marginBottom: "1.5rem" }}
          >
            <div className='trainingPortfolio__form__row__left'></div>
            <div className='trainingPortfolio__form__row__right'>
              <div className='trainingPortfolio__form__row__right__upper'>
                <hr />
              </div>
              <div className='trainingPortfolio__form__row__right__lower'>
                <label htmlFor=''>Address of company</label>
              </div>
            </div>
          </div>
          <div
            className='trainingPortfolio__form__row'
            style={{ marginBottom: "1.5rem" }}
          >
            <div className='trainingPortfolio__form__row__left'></div>
            <div className='trainingPortfolio__form__row__right'>
              <div className='trainingPortfolio__form__row__right__upper'>
                <hr />
              </div>
              <div className='trainingPortfolio__form__row__right__lower'>
                <label htmlFor=''>Practice management</label>
              </div>
            </div>
          </div>
          <div>
            <div className='trainingPortfolio__form__row'>
              <div className='trainingPortfolio__form__row__left'></div>
              <div
                className='trainingPortfolio__form__row__right'
                style={{ display: "flex" }}
              >
                <div
                  className='trainingPortfolio__form__row__left'
                  style={{ width: "40%" }}
                ></div>
                <div className='trainingPortfolio__bottom-text'>
                  <div style={{ width: "100%" }}>
                    <p>
                      <b>Introduction</b> <br />
                      This training portfolio provides a better overview of the
                      training for nurse. The portfolio is managed by the
                      trainee himself and completely. It serves to Evidence of
                      when and where which part of the training was completed.
                      This compilation as a portfolio for practical training
                      contains the legally required documentation of practical
                      nursing training and serves in particular the desirable
                      interlocking of theoretical and practical Training content
                      through systematic training and guidance. The Carrying out
                      preliminary, interim and evaluation talks enables a
                      continuous focus on learning success throughout the
                      practical Education. In this way, together with the
                      competence assessment sheet facilitates the objective
                      overall assessment. The proof of the learning objectives
                      reflects the training content like a textbook. From here
                      Nursing measures for various nursing problems of all
                      people become apparent. The learning objectives listed
                      relate to the entire three-thirds of the training. The
                      proof of learning objectives is therefore provided in the
                      practical phases of the training continuously, until the
                      end of the training, if possible, all learning objectives
                      have been demonstrated are. The portfolio has a moderate
                      focus on training content, the various Places of use are
                      assigned, but are not necessarily only in this area to be
                      proven, but can be provided at any time during the
                      training period and be documented. In the training plan,
                      the training institution confirms that the skills and
                      abilities. According to the current legal basis. The
                      systematic connection of professional skills, Fields of
                      activity, learning goals of the school and the training
                      institutions promoted in order to further improve the
                      quality of training.
                      <br />
                      <br />
                      <b>User information for the trainee</b>
                      <br />
                      The trainee is responsible for the regular documentation
                      of the practical Responsible for training content. For
                      this purpose, the trainee agrees with the supervising
                      nursing staff Appointments for the preliminary, interim
                      and evaluation talks, which in each use must take place
                      and reminds them if necessary. Induction plan, proof of
                      attendance as well as preliminary and Minutes of interim
                      conversations are used as pedagogical tools for the course
                      of the see practical use so that the evaluation interview
                      along with finally an objective overall assessment with
                      the competency assessment sheet of practical use. The
                      trainee fills out the documents together or in
                      consultation with the supervising caregiver. The trainee
                      should provide evidence of the practical learning content
                      before and during the course review the practical training
                      phases regularly to to keep an eye on the prescribed
                      learning goals, but also on your own expectations and
                      ideas (“What do I want to learn?”) and to realize them in
                      the respective facility to take advantage of existing
                      learning opportunities. With this learning control, the
                      trainee should already achieved learning successes and
                      find out about the learning goals that still have to be
                      achieved. The required training and assessments are basic
                      components of a every assignment, as well as the
                      management of absenteeism and must not be forgotten
                      become. Therefore, it makes sense to have a to arrange a
                      new date for the follow-up interview. With the help of the
                      portfolio all learning content is systematically developed
                      and objectively verified. After the signature of the
                      supervising caregiver, none are allowed without their
                      knowledge more changes to be made. At each reflection of
                      the use with The institution or school provided the
                      student with the portfolio without being asked to submit.
                      The portfolio is used for the half-yearly meeting monitor
                      training progress.
                    </p>
                  </div>
                  <br />
                  <div className='trainingPortfolio__compitency-button'>
                    <button
                      onClick={() => navigate(`/competenceprofile/${_student}`)}
                    >
                      Competency Profile
                    </button>
                  </div>
                  <div className='trainingPortfolio__bottom-buttons'>
                    <BottomCards />
                  </div>
                  {trainingCategories?.map(cat => (
                    <div
                      className='trainingPortfolio__bottom-table-container'
                      key={cat._id}
                    >
                      <div className='trainingPortfolio__bottom-table-container__content'>
                        <div>
                          <p>{cat.title}</p>
                        </div>
                        <div>
                          <button onClick={() => selectCategory(cat._id)}>
                            To sue
                          </button>
                        </div>
                      </div>
                      {openedCategory === cat._id && (
                        <div className='trainingPortfolio__bottom-table-container__table'>
                          <div>
                            <Table
                              items={cat.items}
                              setTrainingCategories={setTrainingCategories}
                              trainingCategories={trainingCategories}
                              _student={_student}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TrainingPortfolio;
