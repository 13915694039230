import React, { useEffect, useState } from "react";
import Page from "../../components/Page";
import Switch from "@mui/material/Switch";
import { useLocation } from "react-router";
import useStudent from "../../hooks/useStudent";
import useAuthenticate from "../../hooks/useAuthenticate";

const ItemA = () => {
  const location = useLocation();
  const { isAuthenticated } = useAuthenticate();
  const { getItemById, updatePoints } = useStudent();

  const [_item, setItem] = useState("");
  const [oItem, setOItem] = useState();

  let user = isAuthenticated(false, ["teacher", "student"]);

  useEffect(() => {
    if (!_item) {
      let splitted = location.pathname.split("/");
      let itemId = splitted[splitted.length - 1];
      return setItem(itemId);
    }
    getInitialData();
  }, [_item]);

  const getInitialData = async () => {
    const response = await getItemById(_item);
    if (response) setOItem(response.item);
  };

  const updateItemPoint = async _point => {
    if (user.user.userType !== "teacher") return;

    const response = await updatePoints(_item, _point);
    if (response) setOItem({ ...response.item });
  };

  return (
    <Page>
      <div className='itemAContainer'>
        <div className='itemAContainer__box'>
          {oItem?.points.map(p => (
            <div className='itemAContainer__box__item'>
              <div className='itemAContainer__box__item__heading'>
                <p>{p.name}</p>
              </div>
              <div className='itemAContainer__box__item__body'>
                <div className='itemAContainer__box__item__body__contents'>
                  <p>{p.desc}</p>
                  <Switch
                    checked={p.done}
                    sx={{ fontSize: "5rem" }}
                    onChange={() => updateItemPoint(p._id)}
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </Page>
  );
};

export default ItemA;
