import React from 'react'
import { useNavigate } from 'react-router'

export default function BottomCards() {
    const navigate = useNavigate();
  return (
    <div >
        <button onClick={() => navigate('/learninggoals', {state: {data: "pain"}})}>Schmerz</button>
        <button onClick={() => navigate('/learninggoals', {state:{data: 'Personal hygiene'}})}>Hygiene</button>
        <button onClick={() => navigate('/learninggoals', {state: {data: "Nourishment"}})}>Ernährung</button>
        <button onClick={() => navigate('/learninggoals', {state: {data: "Mobility"}})}>Mobilität</button>
    </div>
  )
}
