import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import Loading from "../../components/Common/Loading";
import Navbar from "../../components/Navbar";
import useTalks from "../../hooks/useTalks";

const CompetencyAssessment = () => {
  const { getCompetencyAssessment } = useTalks();

  const navigate = useNavigate();

  const location = useLocation();
  const splitted = location.pathname.split("/");
  const _student = splitted[splitted.length - 1];

  const [subTestsAssessment, setSubTestsAssessment] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getCompetencyAssessment(_student)
      .then(res => {
        console.log(res.subTestsAssessment);
        setSubTestsAssessment([...res.subTestsAssessment]);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  if (loading) return <Loading />;

  const navigateToAssessmentCats = (_test, _subTest) => {
    if (_test && _subTest)
      navigate(
        `/assessment/competency/categories/${_student}/${_test}/${_subTest}`
      );
  };

  return (
    <div>
      <Navbar />

      <div
        style={{
          display: "flex",
          gap: "1rem",
          justifyContent: "center",
          flexWrap: "wrap",
          paddingTop: "6.5rem",
        }}
      >
        {subTestsAssessment?.map(cat => (
          <CatBox
            cat={cat}
            key={cat._id}
            onBoxClick={navigateToAssessmentCats}
          />
        ))}
      </div>
    </div>
  );
};

const CatBox = ({ cat, onBoxClick }) => {
  return (
    <div
      className='competencyCatBox'
      onClick={() =>
        onBoxClick(
          cat?.competencyTest?._test?._id,
          cat?.competencyTest?._subTest?._id
        )
      }
    >
      <div>{cat?.subTest?.title}</div>
      <div>
        <b> {cat?.competencyTest?._test?.title}</b>
      </div>
      <div>{cat?.subTest?.subTitle}</div>
    </div>
  );
};

export default CompetencyAssessment;
