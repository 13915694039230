import React from "react";

const SimpleOptionsTopBar = ({ option }) => {
  switch (option) {
    case "orientationmission":
      return (
        <div className='simpleOptions__topbar'>
          <h4>Competence test osteoarthritis</h4>
          <div className='simpleOptions__topbar__grades'>
            Achieved Grades: -
          </div>
        </div>
      );

    default:
      return null;
  }
};

export default SimpleOptionsTopBar;
