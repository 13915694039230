import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import Loading from "../../components/Common/Loading";
import useTalks from "../../hooks/useTalks";

const NursingPhenomenonSubTestLinks = () => {
  const location = useLocation();
  const splittedLoc = location.pathname.split("/");
  const _test = splittedLoc[splittedLoc.length - 2];
  const _subtest = splittedLoc[splittedLoc.length - 1];

  const [subtest, setSubtest] = useState();
  const [loading, setLoading] = useState(true);

  const { getNursingPhenomenonSubTestById } = useTalks();

  useEffect(() => {
    getInitialData();
  }, []);

  const getInitialData = async () => {
    const response = await getNursingPhenomenonSubTestById(_test, _subtest);
    if (response) {
      setSubtest({ ...response.subTest });
    }
    setLoading(false);
  };

  if (loading) return <Loading />;

  return (
    <section className='nursingPhenomena'>
      <div className='nursingPhenomena__body'>
        <header className='nursingPhenomena__body__header'>
          <img src={require("../../assets/Logo.png")} alt='' />
          <h2>{subtest?.title}</h2>
        </header>
        <div className='nursingPhenomena__body__container'>
          <Links subtest={subtest} />
        </div>
      </div>
    </section>
  );
};

const Links = ({ subtest }) => {
  const openLink = link => window.open(link, "_blank");

  return (
    <div className='nursingPhenomena__links'>
      <div
        className='nursingPhenomena__links__link'
        onClick={() => openLink(subtest?.videoLink)}
      >
        <h3>Video Link</h3>
      </div>
      <div
        className='nursingPhenomena__links__link'
        onClick={() => openLink(subtest?.audioLink)}
      >
        <h3>Audio Link</h3>
      </div>
      <div
        className='nursingPhenomena__links__link'
        onClick={() => openLink(subtest?.pdfLink1)}
      >
        <h3>PDF Link 1</h3>
      </div>
      <div
        className='nursingPhenomena__links__link'
        onClick={() => openLink(subtest?.pdfLink2)}
      >
        <h3>PDF Link 2</h3>
      </div>
    </div>
  );
};

export default NursingPhenomenonSubTestLinks;
