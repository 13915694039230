import React from "react";
import moment from "moment";

const AnalysisData = ({ registerAnalysis }) => {
  let analysis8 = registerAnalysis?.analysis?.find(
    item => item.time.toString() === "8"
  );
  let analysis10 = registerAnalysis?.analysis?.find(
    item => item.time.toString() === "10"
  );
  let analysis12 = registerAnalysis?.analysis?.find(
    item => item.time.toString() === "12"
  );
  let analysis1345 = registerAnalysis?.analysis?.find(
    item => item.time.toString() === "13:45"
  );

  return (
    <>
      {analysis8 && (
        <TimeAnalysis
          registerAnalysis={registerAnalysis}
          title='08:00-09:30'
          time='8'
          analysis={analysis8}
        />
      )}
      {analysis10 && (
        <TimeAnalysis
          registerAnalysis={registerAnalysis}
          title='10:00-11:30'
          time='10'
          analysis={analysis10}
        />
      )}

      {analysis12 && (
        <TimeAnalysis
          registerAnalysis={registerAnalysis}
          title='12:00-13:30'
          time='12'
          analysis={analysis12}
        />
      )}
      {analysis1345 && (
        <TimeAnalysis
          registerAnalysis={registerAnalysis}
          title='13:45-15:15'
          time='13:45'
          analysis={analysis1345}
        />
      )}
    </>
  );
};

const TimeAnalysis = ({ title, time, registerAnalysis, analysis }) => {
  return (
    <tr style={{ border: "1px solid red" }}>
      <td style={{ padding: "0" }}>
        <div
          style={{ fontSize: "0.7rem", fontWeight: "600", color: "#00c2ff" }}
        >
          {moment(registerAnalysis.date).format("MM-DD-YYYY")}
        </div>
        <div
          style={{ width: "100%", fontSize: "0.75rem", marginTop: "0.5rem" }}
        >
          {title}
        </div>
      </td>
      <td>{analysis?._lfce?.title || ""}</td>
      <td>
        {analysis?._supervisor
          ? `${analysis?._supervisor?.firstName} ${analysis._supervisor?.name}`
          : ""}
      </td>
      <td>{analysis?.content || ""}</td>
      <td>
        {analysis?._lfce?._learningObjectives?.map(item => `${item?.title} `) ||
          ""}
      </td>
      <td>{analysis?._lfce?.competencyIV || ""}</td>
      <td>{analysis?.status || ""}</td>
      <td>
        <input
          type='checkbox'
          name='forInstructors'
          id=''
          checked={analysis?.forInstructors || false}
        />
      </td>
    </tr>
  );
};

export default AnalysisData;
