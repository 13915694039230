import React, { useEffect, useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import AccountCircle from "@mui/icons-material/AccountCircle";
import MoreIcon from "@mui/icons-material/MoreVert";
import useAuthenticate from "../../hooks/useAuthenticate";
import { USER_STORAGE_KEY } from "../../helpers/variables";
import { useNavigate } from "react-router";
import { FormControl, InputLabel, Select, selectClasses } from "@mui/material";
import Logo from "../../assets/Logo.svg";
export default function Navbar({
  classes,
  setSelectedClass,
  selectedClass,
  isAdmin,
}) {
  const { isAuthenticated } = useAuthenticate();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const [user, setUser] = useState();

  useEffect(() => {
    setUser({
      ...isAuthenticated(isAdmin, ["student", "supervisor", "teacher"]),
    });
  }, []);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleProfileMenuOpen = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
    localStorage.removeItem(USER_STORAGE_KEY);
    navigate("/signin");
  };

  const handleMobileMenuOpen = event => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const handleChange = e => {
    console.log(setSelectedClass, selectedClass);

    setSelectedClass(e.target.value);
  };

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleMenuClose}>Logout</MenuItem>
    </Menu>
  );

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem onClick={handleProfileMenuOpen}>
        <p>Logout</p>
      </MenuItem>
    </Menu>
  );

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        position='static'
        sx={{
          backgroundColor: "#D7D7D7",
          position: "fixed",
          zIndex: "1",
          color: "black",
          boxShadow: "unset",
          height: "50px",
          marginBottom: "5rem",
        }}
      >
        <Toolbar style={{ display: "flex", justifyContent: "space-between" }}>
          <IconButton
            size='large'
            edge='start'
            color='inherit'
            aria-label='open drawer'
            sx={{ display: { xs: "block", sm: "none" } }}
          >
            <MenuIcon />
          </IconButton>
          {/* <Typography
            variant='h6'
            noWrap
            component='div'
            sx={{ display: { xs: "none", sm: "block" } }}
          >
            Kolibri
          </Typography> */}
          <Box sx={{ display: { xs: "none", sm: "block" } }}>
            <img
              src={require("../../assets/Logo.png")}
              alt=''
              style={{
                width: "80px",
                position: "absolute",
                marginTop: "-1.6rem",
              }}
            />
          </Box>
          {classes && (
            <Box sx={{ minWidth: 120, background: "white" }}>
              <FormControl fullWidth>
                <InputLabel id='demo-simple-select-label'>--Class-</InputLabel>
                <Select
                  labelId='demo-simple-select-label'
                  id='demo-simple-select'
                  value={selectedClass}
                  label='class'
                  onChange={handleChange}
                >
                  {classes.map(cl => (
                    <MenuItem value={cl._id}>{cl.className}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          )}

          <Box
            sx={{
              display: {
                xs: "none",
                md: "flex",
                marginTop: "",
              },
            }}
          >
            {/* <Typography
              variant='h6'
              noWrap
              component='div'
              sx={{ display: { xs: "none", sm: "block" }, mt: 2, mr: 1, fontWeight: "bold" }}
            >
              Hi!
            </Typography> */}
            <Typography
              variant='h6'
              noWrap
              component='div'
              sx={{
                display: { xs: "none", sm: "block" },
                ml: -24,
                marginTop: "35px",
                fontWeight: "600",
                marginRight: "100px",
                // position: "absolute",
              }}
            >
              {user?.user?.firstName} {user?.user?.name}
            </Typography>
            <IconButton
              size='large'
              edge='end'
              aria-label='account of current user'
              aria-controls={menuId}
              aria-haspopup='true'
              onClick={handleProfileMenuOpen}
              color='inherit'
            >
              <Box
                sx={{
                  border: "1px solid gray",
                  position: "absolute",
                  marginTop: "2.4rem",
                  marginLeft: "-7rem",
                  borderRadius: "50%",
                  padding: "1rem",
                  backgroundColor: "white",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <hr style={{ width: "80px", position: "absolute" }} />
                <img
                  src={Logo}
                  alt=''
                  style={{
                    width: "50px",
                    zIndex: "1",
                    marginBottom: "-.1rem",
                    marginRight: "-.1rem",
                  }}
                />
              </Box>
            </IconButton>
          </Box>
          <Box sx={{ display: { xs: "flex", md: "none" } }}>
            <IconButton
              size='large'
              aria-label='show more'
              aria-controls={mobileMenuId}
              aria-haspopup='true'
              onClick={handleMobileMenuOpen}
              color='inherit'
            >
              <MoreIcon />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
      {renderMenu}
    </Box>
  );
}
