import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import Loading from "../../components/Common/Loading";
import useAdmin from "../../hooks/useAdmin";
import OptionQuestionAnswer from "../OptionQuestionAnswers";

const AssessmentQuestions = () => {
  const location = useLocation();
  const { getAssessmentCategoryOptions } = useAdmin();

  const splitted = location.pathname.split("/");
  const _competencyAssessmentCategoryTest = splitted[splitted.length - 1];
  const _student = splitted[splitted.length - 2];

  const [assessmentTestOptions, setAssessmentTestOptions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [
    competencyAssessmentCategoryTest,
    setCompetencyAssessmentCategoryTest,
  ] = useState();

  useEffect(() => {
    getInitialData();
  }, []);

  const getInitialData = async () => {
    const response = await getAssessmentCategoryOptions(
      _competencyAssessmentCategoryTest
    );
    if (response) {
      console.log(response);
      let sortedOptions = response.competencyTestOptions.sort(function (a, b) {
        return a.grade - b.grade;
      });

      setAssessmentTestOptions([...sortedOptions]);
      setCompetencyAssessmentCategoryTest(response?.comptencyAssessmentTest);
    }
    setLoading(false);
  };

  if (loading) return <Loading />;

  return (
    <OptionQuestionAnswer
      options={assessmentTestOptions}
      intro={competencyAssessmentCategoryTest?.desc}
      _competencyAssessmentCategoryTest={_competencyAssessmentCategoryTest}
      _student={_student}
    />
  );
};

export default AssessmentQuestions;
