import React from "react";
import { useNavigate } from "react-router";
import { USER_STORAGE_KEY } from "../helpers/variables";

const useHandleErrors = () => {
  const navigate = useNavigate();
  const handleErrors = err => {
    console.log(err);
    err.response && alert(err.response.data.messages[0].message);
    if (err.response && err.response.status === 401) {
      localStorage.removeItem(USER_STORAGE_KEY);
      navigate("/signin");
    }
  };

  return { handleErrors };
};

export default useHandleErrors;
