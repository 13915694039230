import { Box, Modal } from "@mui/material";
import React, { useState } from "react";
import CSmButton from "../../../../components/Form/CSmButton";
import CustomInput from "../../../../components/Form/CustomInput";
import useAdmin from "../../../../hooks/useAdmin";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "none",
  borderRadius: "0.5rem",
  boxShadow: 24,
  outline: "none",
  p: 4,
};

const SubTestLinksModal = ({ links, open, setOpen, setLinks, loading }) => {
  const { updateSubTestLinks } = useAdmin();

  const handleClose = () => {
    setOpen(false);
    setLinks(pS => ({
      ...pS,
      subTitle: "",
      audioLink: "",
      videoLink: "",
      pdfLink1: "",
      pdfLink2: "",
    }));
  };

  const changeHandler = e => {
    const { name, value } = e.target;
    setLinks(pS => ({
      ...pS,
      [name]: value,
    }));
  };

  const submitHandler = async e => {
    e.preventDefault();

    const response = await updateSubTestLinks(links);
    if (response?.links) {
      alert("Links updated successfully!");
      handleClose();
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
      sx={{ background: "rgba(255, 255, 255, 0.15)" }}
    >
      <Box sx={style}>
        <div className='dashboardAddModal'>
          {loading ? (
            <h1>Loading...</h1>
          ) : (
            <>
              <h3>Links</h3>
              <form onSubmit={submitHandler}>
                <CustomInput
                  style={{ background: "#ECEFF7", padding: "0.5rem" }}
                  placeholder='Sub Title'
                  name='subTitle'
                  type='text'
                  onChange={changeHandler}
                  value={links?.subTitle}
                  required={true}
                />
                <CustomInput
                  style={{ background: "#ECEFF7", padding: "0.5rem" }}
                  placeholder='Video Link'
                  name='videoLink'
                  type='url'
                  onChange={changeHandler}
                  value={links?.videoLink}
                  required={true}
                />
                <CustomInput
                  style={{ background: "#ECEFF7", padding: "0.5rem" }}
                  placeholder='Audio Link'
                  name='audioLink'
                  onChange={changeHandler}
                  required={true}
                  value={links?.audioLink}
                  type='url'
                />
                <CustomInput
                  style={{ background: "#ECEFF7", padding: "0.5rem" }}
                  placeholder='PDF Link 1'
                  name='pdfLink1'
                  onChange={changeHandler}
                  required={true}
                  value={links?.pdfLink1}
                  type='url'
                />
                <CustomInput
                  style={{ background: "#ECEFF7", padding: "0.5rem" }}
                  placeholder='PDF Link 2'
                  name='pdfLink2'
                  onChange={changeHandler}
                  required={true}
                  value={links?.pdfLink2}
                  type='url'
                />
                <CSmButton title='Update' type='submit' />
              </form>
            </>
          )}
        </div>
      </Box>
    </Modal>
  );
};

export default SubTestLinksModal;
