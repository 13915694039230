import React from "react";
import { useLocation } from "react-router";
import QuestionBox from "../../components/Common/QuestionBox";
import Navbar from "../../components/Navbar";
import OptionQuestionsBottom from "./components/OptionQuestionsBottom";
import { questions } from "./helpers/questions";

const OptionQuestions = () => {
  const location = useLocation();
  let splittedLoc = location.pathname.split("/");
  let option = splittedLoc[splittedLoc.length - 1];

  return (
    <>
      <Navbar />
      <div className='optionQuestions'>
        {questions[option]?.map(item => (
          <QuestionBox key={item.question} question={item} />
        ))}

        <OptionQuestionsBottom option={option} />
      </div>
    </>
  );
};

export default OptionQuestions;
