import React from 'react'

const Checkox = ({ label }) => {
  return (
    <div className='customChecbox'>
      <input type="checkbox" />
      <label>{label}</label>
    </div>
  )
}

export default Checkox;