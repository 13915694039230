import "./sass/main.scss";
import Routing from "./components/Routing";
import React, { useState } from "react";

export const KolibriStore = React.createContext();

function App() {
  const [kilianTask, setKilianTask] = useState();
  return (
    <KolibriStore.Provider value={{ kilianTask, setKilianTask }}>
      <Routing />
    </KolibriStore.Provider>
  );
}

export default App;
