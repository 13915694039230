import React, { useEffect, useState } from "react";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import Page from "../../components/Page";
import { useLocation } from "react-router";
import useStudent from "../../hooks/useStudent";
import useAuthenticate from "../../hooks/useAuthenticate";

const AddFormA = () => {
  const location = useLocation();
  const { isAuthenticated } = useAuthenticate();
  const { getItems, getItem, updateItem } = useStudent();
  const [_id, setID] = useState("");
  const [items, setItems] = useState([]);
  const [selectedItem, setSelectedItem] = useState("");
  const [item, setItem] = useState();
  let user = isAuthenticated(false, ["student", "teacher"]);

  useEffect(() => {
    if (!_id) {
      let splitted = location.pathname.split("/");
      let id = splitted[splitted.length - 1];
      return setID(id);
    }
    getInitialData();
  }, [_id]);

  const getInitialData = async () => {
    const response = await getItems(_id);
    if (response) {
      setItems([...response.items]);
      setSelectedItem(response.items[0]._id);
    }
  };

  useEffect(() => {
    if (selectedItem) {
      getItemData();
    }
  }, [selectedItem]);

  const getItemData = async () => {
    const response = await getItem(selectedItem);
    if (response) setItem({ ...response.item });
  };

  const updateNow = async () => {
    if (user.user.userType !== "teacher") return;
    const response = await updateItem(item);
    if (response) {
      let index = items.findIndex(it => it._id === selectedItem);
      items[index] = response.item;
      setItems([...items]);
      alert("Updated!");
    }
  };

  return (
    <Page>
      <div className='addForm-AContainer'>
        <div className='addForm-AContainer__selection'>
          <select
            value={selectedItem}
            onChange={e => setSelectedItem(e.target.value)}
          >
            {items.map(it => (
              <option key={it._id} value={it._id}>
                {it.itemName}
              </option>
            ))}
          </select>
          <AddCircleIcon
            sx={{ fontSize: "2.5rem", color: "Gray", cursor: "pointer" }}
            onClick={updateNow}
          />
        </div>
        <div className='addForm-AContainer__textarea'>
          <textarea
            placeholder='Item Description'
            value={item?.itemDesc}
            style={{ color: "black" }}
            onChange={e => {
              if (user.user.userType !== "teacher") return;
              if (item) {
                setItem({ ...item, itemDesc: e.target.value });
              }
            }}
          ></textarea>
        </div>
      </div>
    </Page>
  );
};

export default AddFormA;
