import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import Navbar from "../../components/Navbar";
import useUpload from "../../hooks/useUpload";

const folders = [
  { english: "Confrontation", german: "" },
  { english: "Implementation", german: "" },
  { english: "Information", german: "" },
  { english: "Application", german: "" },
  { english: "Finding a Solution", german: "" },
  { english: "Sustainability", german: "" },
];

const KilianUploadFolders = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { getComptencyCategoryOptionSubTest } = useUpload();
  const splitted = location.pathname.split("/");
  const _competencyCategoryOptionSubTest = splitted[splitted.length - 1];
  const _student = splitted[splitted.length - 2];

  const [assessmentSubTest, setAssessmentSubTest] = useState();

  console.log(_competencyCategoryOptionSubTest);
  useEffect(() => {
    getComptencyCategoryOptionSubTest(_competencyCategoryOptionSubTest).then(
      res => {
        console.log(res.subTest);
        setAssessmentSubTest(res.subTest);
      }
    );
  }, []);

  const selectFolder = folder =>
    navigate(
      `/kilianUploads/${_student}/${_competencyCategoryOptionSubTest}/${folder?.toLowerCase()}`
    );

  return (
    <>
      <Navbar />

      <div
        style={{
          padding: "6.5rem 0",
          display: "flex",
          flexFlow: "column",
          alignItems: "center",
        }}
      >
        <h3 style={{ textAlign: "center", textTransform: "capitalize" }}>
          {assessmentSubTest?.title}
        </h3>
        <div className='uploadFolders'>
          {folders.map(folder => (
            <Folder
              title={folder.english}
              key={folder.english}
              onClick={() => selectFolder(folder.english)}
            />
          ))}
        </div>
      </div>
    </>
  );
};

const Folder = ({ title, onClick }) => {
  return (
    <div className='uploadFolder' onClick={onClick}>
      <i className='fa-regular fa-folder'></i>
      {title}
    </div>
  );
};

export default KilianUploadFolders;
