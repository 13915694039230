import React, { useEffect, useState } from "react";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import Page from "../../components/Page";
import { useLocation, useNavigate } from "react-router";
import useStudent from "../../hooks/useStudent";
import { Button, TextField } from "@mui/material";
import useAuthenticate from "../../hooks/useAuthenticate";

const ReviewA = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { isAuthenticated } = useAuthenticate();
  const { getReviewByID, updateReview } = useStudent();

  const [_review, setReview] = useState("");
  const [cReview, setCReview] = useState();

  let user = isAuthenticated(false, ["teacher", "student"]);

  useEffect(() => {
    if (!_review) {
      let splitted = location.pathname.split("/");
      let reviewId = splitted[splitted.length - 1];
      return setReview(reviewId);
    }
    getInitialData();
  }, [_review]);

  const getInitialData = async () => {
    const response = await getReviewByID(_review);
    if (response) {
      setCReview({ ...response.review, items: response.items });
    }
  };

  const updateNow = async () => {
    if (user.user.userType !== "teacher") return;
    const response = await updateReview(cReview);
    if (response) alert("Updated successfully!");
  };

  return (
    <Page>
      <div className='review-AContainer'>
        <img
          src={require("../../assets/homePage.png")}
          alt=''
          className='review-AContainer__image'
        />
        <div className='review-AContainer__form'>
          <h2>Review A</h2>
          <div className='review-AContainer__form__date'>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DesktopDatePicker
                label='Date desktop'
                inputFormat='MM/dd/yyyy'
                value={cReview?.rDate}
                onChange={nV => setCReview({ ...cReview, rDate: nV })}
                renderInput={params => <TextField {...params} />}
              />
            </LocalizationProvider>
          </div>
          <div className='review-AContainer__form__container1'>
            <input
              type='text'
              style={{
                border: "none",
                width: "100%",
                outline: "none",
                fontSize: "1.5rem",
              }}
              placeholder='Attending people'
              value={cReview?.attendingPeople}
              onChange={e =>
                setCReview({ ...cReview, attendingPeople: e.target.value })
              }
            />
            {user.user.userType === "teacher" && (
              <Button onClick={updateNow}>Update</Button>
            )}
          </div>
          <div className='review-AContainer__form__container2'>
            {cReview?.items.map(it => (
              <div
                style={{
                  marginTop: "1rem",
                  borderBottom: "1px solid gray",
                  width: "100%",
                  textAlign: "start",
                  cursor: "pointer",
                  display: "flex",
                  justifyContent: "space-between",
                }}
                onClick={() => navigate(`/itema/${it._id}`)}
              >
                <span>{it.itemName}</span>
                <span>
                  {it.points.reduce((pV, cV) => (cV.done ? pV + 1 : pV), 0)}/6
                </span>
              </div>
            ))}
            <span>
              <AddCircleIcon
                sx={{ fontSize: "3rem", marginTop: ".8rem", cursor: "pointer" }}
                onClick={() => navigate(`/addforma/${cReview._id}`)}
              />
            </span>
            <span className='review-AContainer__form__container2__footer'>
              Auto Adds to Gesprach B
            </span>
          </div>
        </div>
      </div>
    </Page>
  );
};

export default ReviewA;
