import React, { useEffect, useState } from "react";
import AdminPage from "../../../components/AdminPage";
import CSmButton from "../../../components/Form/CSmButton";
import useAdmin from "../../../hooks/useAdmin";
import AddModal from "./components/AddModal";

const TrainingPortFolio = () => {
  const { getTrainingPortfolioCategories, getTrainingPortfolioItems } =
    useAdmin();

  const [trainingCategories, setTrainingCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState();

  const [categoryItems, setCategoryItems] = useState([]);
  const [selectedItem, setSelectedItem] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getInitialData();
  }, []);

  const getInitialData = async () => {
    const response = await getTrainingPortfolioCategories();
    if (response) {
      console.log(response.trainingPortfolioCategories);
      setTrainingCategories([...response.trainingPortfolioCategories]);
    }
  };

  const getCategoryItemsNow = async _category => {
    setSelectedCategory(_category);
    const response = await getTrainingPortfolioItems(_category);
    if (response) {
      console.log(response);
      setCategoryItems([...response.trainingPortfolioItems]);
    }
  };

  return (
    <AdminPage>
      <div className='dashboard'>
        <div
          className='dashboard__tables'
          style={{ justifyContent: "start", gap: "1rem" }}
        >
          <DashboardTable
            title='Training Portfolio Categories'
            items={trainingCategories}
            setItems={setTrainingCategories}
            onAction={getCategoryItemsNow}
            _selected={selectedCategory}
            // _parent={selectedSubTest}
            loading={loading}
            boxStyle={{ width: "48%" }}
          />
          <DashboardTable
            title='Training Portfolio Items'
            items={categoryItems}
            setItems={setCategoryItems}
            // onAction={getCategoryItemsNow}
            _selected={selectedItem}
            _parent={selectedCategory}
            loading={loading}
            boxStyle={{ width: "48%" }}
          />
        </div>
      </div>
    </AdminPage>
  );
};

const DashboardTable = ({
  title,
  items,
  setItems,
  onAction,
  _selected,
  _parent,
  loading,
  boxStyle,
}) => {
  const [addModalOpen, setAddModalOpen] = useState(false);

  return (
    <div className='dashboard__tables__table' style={{ ...boxStyle }}>
      <div className='dashboard__tables__table__add'>
        <CSmButton title='Add' onClick={() => setAddModalOpen(true)} />
      </div>
      {items.length > 0 ? (
        <>
          <h4>{title}</h4>

          <div className='dashboard__tables__table__headings'>
            <div className='dashboard__tables__table__headings__heading'>
              <b>Title</b>
            </div>
            <div className='dashboard__tables__table__headings__heading'></div>
          </div>
          {items.map(item => (
            <div className='dashboard__tables__table__item' key={item?._id}>
              <div className='dashboard__tables__table__item__col'>
                {item?.title}
              </div>

              <div className='dashboard__tables__table__item__col'>
                {title === "Training Portfolio Categories" && (
                  <button
                    onClick={() => onAction(item?._id, _parent)}
                    style={
                      _selected === item?._id ? { background: "gray" } : {}
                    }
                  >
                    Item
                  </button>
                )}
              </div>
            </div>
          ))}
        </>
      ) : (
        <div className='dashboard__tables__table__message'>
          <h5> {loading ? "Loading..." : `No ${title} to show!`} </h5>
        </div>
      )}
      <AddModal
        open={addModalOpen}
        setOpen={setAddModalOpen}
        type={title === "Training Portfolio Categories" ? "Category" : "Item"}
        items={items}
        setItems={setItems}
        _parent={_parent}
      />
    </div>
  );
};

export default TrainingPortFolio;
