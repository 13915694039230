import React, { useEffect, useState, useRef } from "react";
import { useLocation, useNavigate } from "react-router";
import Loading from "../../components/Common/Loading";
import OptionBox from "../../components/Common/OptionBox";
import Navbar from "../../components/Navbar";
import useTalks from "../../hooks/useTalks";
import useUpload from "../../hooks/useUpload";

const KilianUploads = () => {
  const navigate = useNavigate();

  const location = useLocation();
  const splitted = location.pathname.split("/");
  const _student = splitted[splitted.length - 1];

  const { getCompetencyAssessmentTypes } = useTalks();

  const [competencyAssessments, setCompetencyAssessments] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getCompetencyAssessmentTypes()
      .then(res => {
        console.log(res.competencyAssessments);
        setCompetencyAssessments([...res.competencyAssessments]);
      })
      .finally(() => setLoading(false));
  }, []);

  const selectAssessment = _assessment =>
    navigate(`/kilianUploads/${_student}/${_assessment}`);

  if (loading) return <Loading />;

  return (
    <div
      style={{
        display: "flex",
        flexFlow: "column",
        alignItems: "center",
        width: "100%",
      }}
    >
      <h3 style={{ padding: "0 1rem" }}>Kilian Uploads</h3>
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          gap: "1rem",
          width: "80%",
          padding: "2rem 0",
        }}
      >
        {competencyAssessments.map(cA => (
          <CatBox
            title={cA.title}
            key={cA._id}
            onClick={() => selectAssessment(cA._id)}
          />
        ))}
      </div>
    </div>
  );
};

const CatBox = ({ title, onClick }) => {
  return (
    <div className='competencyCatBox' onClick={onClick}>
      <div>{title}</div>
    </div>
  );
};

export default KilianUploads;
