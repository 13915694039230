import React from "react";
import Navbar from "../Navbar";

const Loading = () => {
  return (
    <>
      <div style={styles.loader}>
        {" "}
        <span class='loader'></span>
      </div>
    </>
  );
};

const styles = {
  loader: {
    width: "100%",
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
};

export default Loading;
