import React from 'react'

const EvaluationTable = () => {
  return (
    <section className='proofOfPractice__body__evaluationTable'>
      {/* <div className='proofOfPractice__body__evaluationTable__heading'>
        <p>Evaluation of the orientation assignment overall grade:</p>
      </div> */}
      <div className='proofOfPractice__body__evaluationTable__tableHeading'>
        <p>It is confirmed that the information is complete and the work performance corresponds to the facts! / per practical phase 10% instruction by vocational educator. trained practitioners</p>
      </div>
      <table className='proofOfPractice__body__evaluationTable__table'>
        <tbody className='proofOfPractice__body__evaluationTable__table__tableBody'>
          <tr className='proofOfPractice__body__evaluationTable__table__tableBody__row'>
            <td className='proofOfPractice__body__evaluationTable__table__tableBody__row__details'>
              <div className='proofOfPractice__body__evaluationTable__table__tableBody__row__details__checkBox'>
                <input type="checkbox" />
                <label>Apprentice</label>
              </div>
            </td>
            <td className='proofOfPractice__body__evaluationTable__table__tableBody__row__details'>
              <div className='proofOfPractice__body__evaluationTable__table__tableBody__row__details__checkBox'>
                <input type="checkbox" />
                <label>Head of department/practical guide</label>
              </div>
            </td>
            <td className='proofOfPractice__body__evaluationTable__table__tableBody__row__details'>
              <div className='proofOfPractice__body__evaluationTable__table__tableBody__row__details__checkBox'>
                <input type="checkbox" />
                <label>Carrier of the training</label>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </section>
  )
}

export default EvaluationTable