import { Box, Modal, Typography } from "@mui/material";
import React from "react";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "none",
  borderRadius: "0.5rem",
  boxShadow: 24,
  outline: "none",
  p: 4,
};

const DatesModal = ({ open, setOpen, talk, setTalk, changeHandler }) => {
  const handleClose = () => setOpen(false);

  const handleMainteractionUpdate = (e, index) => {
    talk.mainteraction_date[index] = e.target.value;
    setTalk({ ...talk });
  };

  const handleFixedPointUpdate = (e, index) => {
    talk.fixedpoint_date[index] = e.target.value;
    setTalk({ ...talk });
  };

  const addMainteractionDate = () => {
    talk.mainteraction_date.push("");
    setTalk({ ...talk });
  };
  const addFixedPointDate = () => {
    talk.fixedpoint_date.push("");
    setTalk({ ...talk });
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <Box sx={style}>
        <div className='input__group' style={{ width: "100%" }}>
          <label htmlFor='fixedpoint_date' style={styles.labelStyle}>
            Metainteraktions Termine
          </label>
          {talk.mainteraction_date.map((date, index) => (
            <div className='form__input' style={{ width: "100%" }}>
              <input
                type='date'
                value={talk.mainteraction_date[index]}
                name={`mainteraction_date_${index}`}
                id={`mainteraction_date_${index}`}
                onChange={e => handleMainteractionUpdate(e, index)}
                style={styles.inputStyle}
              />
            </div>
          ))}
          <div className='alignedRight' style={{ marginTop: "0.5rem" }}>
            <i className='fa-solid fa-plus' onClick={addMainteractionDate}></i>
          </div>
        </div>
        <div className='input__group' style={{ width: "100%" }}>
          <label htmlFor='fixedpoint_date' style={styles.labelStyle}>
            Fixpunkt Termine
          </label>
          {talk.fixedpoint_date.map((date, index) => (
            <div className='form__input' style={{ width: "100%" }}>
              <input
                type='date'
                value={talk.fixedpoint_date[index]}
                name={`fixedpoint_date_${index}`}
                id={`fixedpoint_date_${index}`}
                onChange={e => handleFixedPointUpdate(e, index)}
                style={styles.inputStyle}
              />
            </div>
          ))}
          <div className='alignedRight' style={{ marginTop: "0.5rem" }}>
            <i className='fa-solid fa-plus' onClick={addFixedPointDate}></i>
          </div>
        </div>
      </Box>
    </Modal>
  );
};

const styles = {
  inputStyle: { width: "100%", marginTop: "0.5rem" },
  labelStyle: {
    fontSize: "0.8rem",
    fontWeight: "bold",
  },
};

export default DatesModal;
