import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router";
import Loading from "../../components/Common/Loading";
import Navbar from "../../components/Navbar";
import { USER_STORAGE_KEY } from "../../helpers/variables";
import useUpload from "../../hooks/useUpload";

const KilianUploadsFiles = () => {
  const inputRef = useRef(null);
  const location = useLocation();
  const splitted = location.pathname.split("/");
  const _student = splitted[splitted.length - 3];
  const _competencyCategoryOptionSubTest = splitted[splitted.length - 2];
  const type = splitted[splitted.length - 1];

  const { uploadFile, uploadKilianFile, geKilianUploads } = useUpload();
  const [kilianUploads, setKilianUploads] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    geKilianUploads(_competencyCategoryOptionSubTest, type, _student)
      .then(res => {
        console.log(res.kilianUploads);
        setKilianUploads([...res.kilianUploads]);
      })
      .finally(() => setLoading(false));
  }, []);

  const changeHandler = async e => {
    const files = Array.from(e.target.files);
    try {
      const user = JSON.parse(localStorage.getItem(USER_STORAGE_KEY));

      if (user?.userType !== "student" || user?.user?._id !== _student) return;

      setUploading(true);
      const uploadedFiles = await Promise.all(
        files.map(file => {
          return new Promise(async (resolve, reject) => {
            const uploadedFile = await uploadFile(file);
            resolve({ file: uploadedFile, title: file.name });
          });
        })
      );

      const kilianUploads = await Promise.all(
        uploadedFiles.map(file =>
          uploadKilianFile({
            file: file?.file,
            _competencyCategoryOptionSubTest,
            type,
            title: file?.title,
            _student,
          })
        )
      );

      if (kilianUploads) {
        setKilianUploads(pS => [...pS, ...kilianUploads]);
      }
    } catch (err) {
    } finally {
      setUploading(false);
    }
  };

  const downloadFile = (uri, title) => {
    const link = document.createElement("a");
    link.download = title;
    link.href = uri;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  if (loading) return <Loading />;

  return (
    <>
      <Navbar />
      <div style={{ padding: "6.5rem 0" }}>
        <div className='uploads'>
          <h3>{type}</h3>
          <div className='uploads__upload'>
            <input
              type='file'
              name='file'
              onChange={changeHandler}
              ref={inputRef}
              multiple
            />
            <i
              className='fa-solid fa-upload'
              onClick={() => inputRef.current?.click()}
            ></i>
          </div>
          {uploading && (
            <div className='uploads__loader'>
              <span className='loader2'></span>
            </div>
          )}
          <div className='uploads__uploads'>
            {kilianUploads.map(upload => (
              <div
                className='uploads__uploads__file'
                key={upload._id}
                onClick={() => downloadFile(upload.file, upload.title)}
              >
                <i className='fa-solid fa-file'></i>
                {upload.title}
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default KilianUploadsFiles;
