import axios from "axios";
import { BACKEND_API_URL } from "../helpers/variables";
import useHandleErrors from "./useHandleErrors";

const useTeacher = () => {
  const { handleErrors } = useHandleErrors();

  const getTrainingPortfolioCategories = async _student => {
    try {
      const response = await axios.get(
        `${BACKEND_API_URL}/teacher/trainingPortfolio/categories/${_student}`
      );

      return response.data;
    } catch (err) {
      handleErrors(err);
    }
  };

  const updateItemTrainingPortfolio = async data => {
    try {
      const response = await axios.patch(
        `${BACKEND_API_URL}/teacher/trainingPortfolio/update`,
        data
      );

      return response.data;
    } catch (err) {
      handleErrors(err);
    }
  };

  const getRegisterAnalysisByDate = async (date, _grade) => {
    try {
      const response = await axios.get(
        `${BACKEND_API_URL}/admin/registerAnalysis/byDate/${date}/${_grade}`
      );

      return response.data;
    } catch (err) {
      handleErrors(err);
    }
  };

  const getRegisterAnalysisComplete = async _grade => {
    try {
      const response = await axios.get(
        `${BACKEND_API_URL}/admin/registerAnalysis/all/${_grade}`
      );

      return response.data;
    } catch (err) {
      handleErrors(err);
    }
  };

  const updateRegisterAnalysis = async data => {
    try {
      const response = await axios.put(
        `${BACKEND_API_URL}/admin/registerAnalysis/classRegisterAnalysis`,
        data
      );

      return response.data;
    } catch (err) {
      handleErrors(err);
    }
  };

  return {
    getTrainingPortfolioCategories,
    updateItemTrainingPortfolio,
    getRegisterAnalysisByDate,
    updateRegisterAnalysis,
    getRegisterAnalysisComplete,
  };
};

export default useTeacher;
