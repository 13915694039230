import { Box, Button, Modal } from "@mui/material";
import React from "react";

const ClassModal = ({ open, setOpen, changeHandler, submitHandler }) => {
  const handleClose = () => setOpen(false);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <Box sx={style} className='supervisorContainer__modal'>
        <input
          type='text'
          placeholder='Name'
          name='name'
          className='signUpContainer__fields__input'
          onChange={changeHandler}
        />
        <button onClick={submitHandler}>Klasse hinzufügen</button>
      </Box>
    </Modal>
  );
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "lightgray",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexFlow: "column",
  border: "none",
  borderRadius: ".5rem",
  boxShadow: 24,
  p: 6,
};

export default ClassModal;
