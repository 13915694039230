import { Box, Modal } from "@mui/material";
import React from "react";
import { useState } from "react";
import CSmButton from "../../../../components/Form/CSmButton";
import CustomInput from "../../../../components/Form/CustomInput";
import CustomSelection from "../../../../components/Form/CustomSelection";
import useAdmin from "../../../../hooks/useAdmin";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "none",
  borderRadius: "0.5rem",
  boxShadow: 24,
  outline: "none",
  p: 4,
};

const AddModal = ({ open, setOpen, type, items, setItems, _parent }) => {
  const handleClose = () => setOpen(false);

  const [loading, setLoading] = useState(false);

  const { createTrainingPortfolioCategory, createTrainingPortfolioItem } =
    useAdmin();

  const [form, setForm] = useState({
    title: "",
    type: "Mobility",
  });

  const submitHandler = async e => {
    e.preventDefault();
    if (loading) return;

    const func =
      type === "Category"
        ? createTrainingPortfolioCategory
        : createTrainingPortfolioItem;

    const data = form;
    if (type === "Item") {
      if (!_parent) return alert("Please select a Category!");
      data["_trainingPortfolioCategory"] = _parent;
    }

    setLoading(true);

    const response = await func(data);
    if (response) {
      setItems([...items, response.item]);
      handleClose();
    }
    setLoading(false);
  };

  const changeHandler = e => {
    const { name, value } = e.target;
    setForm(pS => ({
      ...pS,
      [name]: value,
    }));
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
      sx={{ background: "rgba(255, 255, 255, 0.15)" }}
    >
      <Box sx={style}>
        <div className='dashboardAddModal'>
          <h3>Add {type}</h3>
          <form onSubmit={submitHandler}>
            <CustomInput
              style={{ background: "#ECEFF7", padding: "0.5rem" }}
              placeholder='Title'
              name='title'
              onChange={changeHandler}
              required={true}
            />

            {type !== "Category" && (
              <CustomSelection
                options={["Mobility", "Knowledge", "Pain", "Nutrition"]}
                name='type'
                id='type'
                defaultValue='Mobility'
                onChange={changeHandler}
                style={{ background: "#ECEFF7", padding: "0.5rem 1rem" }}
              />
            )}
            <CSmButton title='Submit' type='submit' />
          </form>
        </div>
      </Box>
    </Modal>
  );
};

export default AddModal;
