import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import Loading from "../../components/Common/Loading";
import Navbar from "../../components/Navbar";
import useAssessment from "../../hooks/useAssessment";

const AssessmentCatTest = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const splitted = location.pathname.split("/");

  const _subTest = splitted[splitted.length - 1];
  const _test = splitted[splitted.length - 2];
  const _assessmentCat = splitted[splitted.length - 3];
  const _student = splitted[splitted.length - 4];

  const { getAssessmentTests } = useAssessment();

  const [assessmentTests, setAssessmentTests] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getInitialData();
  }, []);

  const getInitialData = async () => {
    const response = await getAssessmentTests(
      _assessmentCat,
      _test,
      _subTest,
      _student
    );

    if (response) {
      console.log(response.assessmentTests);
      setAssessmentTests([...response?.assessmentTests]);
    }
    setLoading(false);
  };

  if (loading) return <Loading />;

  const navigateToQuestions = _assessmentTest =>
    navigate(`/assessment/competency/catTests/${_student}/${_assessmentTest}`);

  return (
    <div>
      <Navbar />

      <div className='assessmentTests'>
        {assessmentTests?.map(test => (
          <CheckedBoxWithDesc
            desc={test?.desc}
            key={test._id}
            checked={test?.assessment}
            onClick={navigateToQuestions}
            _test={test._id}
          />
        ))}
      </div>
    </div>
  );
};

const CheckedBoxWithDesc = ({ desc, checked, onClick, _test }) => {
  return (
    <div className='checkedBoxWithDesc' onClick={() => onClick(_test)}>
      <div className='checkedBoxWithDesc__desc'>{desc}</div>
      <div className='checkedBoxWithDesc__checkbox'>
        <input type='radio' checked={checked} />
      </div>
    </div>
  );
};

export default AssessmentCatTest;
