import React from "react";
import { USER_STORAGE_KEY } from "../../helpers/variables";
import useAdmin from "../../hooks/useAdmin";
import useTeacher from "../../hooks/useTeacher";

export default function Table({
  items,
  trainingCategories,
  setTrainingCategories,
  _student,
}) {
  const { updateItemTrainingPortfolio } = useTeacher();

  console.log(items);

  const updateItem = async (e, item, index) => {
    const { name, checked } = e.target;

    const user = JSON.parse(localStorage.getItem(USER_STORAGE_KEY));

    if (user?.userType === "teacher" && name !== "teacherChecked")
      return alert("Action not allowed");
    else if (user?.userType === "student" && name !== "studentChecked")
      return alert("Action not allowed");

    // return;

    const data = {
      _trainingPortfolioItem: item._id,
      ...item.trainingPortfolio,
      [name]: !!!item.trainingPortfolio[name],
    };

    if (user.userType === "student") {
      data["_student"] = user?.user?._id;
    } else {
      data["_teacher"] = user?.user?._id;
      data["_student"] = _student;
    }

    item.trainingPortfolio = data;

    setTrainingCategories([...trainingCategories]);

    const response = await updateItemTrainingPortfolio(data);
    if (response) {
      item.trainingPortfolio = response?.trainingPortfolio;
      setTrainingCategories([...trainingCategories]);
      alert("Action successful!");
    }
  };

  console.log(items);

  return (
    <>
      <div>
        <table>
          <thead>
            <tr>
              <th></th>
              <th>Theoretisch gelern</th>
              <th>Praktisch zugesehen</th>
              <th>Praktisch mitgeholfen</th>
              <th>Selbstständig durchgeführt</th>
              <th>Unterschrift Azubi</th>
              <th>Unterschrift PA</th>
            </tr>
          </thead>
          <tbody>
            {items.map((item, index) => (
              <tr key={item._id}>
                <td>{item.title}</td>
                <td>
                  <input type='checkbox' checked={item.itemLearned} />
                </td>
                <td></td>
                <td></td>
                <td></td>
                <td>
                  <input
                    type='checkbox'
                    checked={!!item.trainingPortfolio?.teacherChecked}
                    onChange={e => updateItem(e, item, index)}
                    name='teacherChecked'
                  />
                </td>
                <td>
                  <input
                    type='checkbox'
                    checked={!!item.trainingPortfolio?.studentChecked}
                    onChange={e => updateItem(e, item, index)}
                    name='studentChecked'
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
}
