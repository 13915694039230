import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import CSmButton from "../../../components/Form/CSmButton";
import CustomInput from "../../../components/Form/CustomInput";
import CustomSelection from "../../../components/Form/CustomSelection";
import { USER_STORAGE_KEY } from "../../../helpers/variables";
import useAdmin from "../../../hooks/useAdmin";
import useTeacher from "../../../hooks/useTeacher";
import useUser from "../../../hooks/useUser";

const statuses = [
  "Gestartet",
  "Weitergeführt",
  "Beendet",
  "Prüfung",
  "Block Start",
  "Block Ende",
];

const ClassRegisterAnalysisTable = ({ date }) => {
  const location = useLocation();
  const splitted = location.pathname.split("/");
  const _grade = splitted[splitted.length - 1];

  const { getRegisterAnalysisByDate, updateRegisterAnalysis } = useTeacher();
  const { getLFCEs } = useAdmin();
  const { getSupervisors } = useUser();

  const initialState = {
    _grade,
    date,
    analysis: [],
  };

  const user = JSON.parse(localStorage.getItem(USER_STORAGE_KEY));

  const [registerAnalysis, setRegisterAnalysis] = useState(initialState);
  const [supervisors, setSupervisors] = useState([]);

  const [lfces, setLFCEs] = useState([]);

  useEffect(() => {
    getSupervisors().then(res => {
      console.log(res.supervisors);
      setSupervisors([...res.supervisors]);
    });
  }, []);

  useEffect(() => {
    setRegisterAnalysis({ ...initialState, date });
    getRegisterAnalysisByDate(date, _grade).then(res => {
      if (res.classRegisterAnalysis)
        setRegisterAnalysis({ ...res.classRegisterAnalysis });
    });
  }, [date]);

  useEffect(() => {
    getInitialData();
  }, []);

  const getInitialData = async () => {
    const lfcesRes = await getLFCEs();

    if (lfcesRes) {
      setLFCEs([...lfcesRes.lfces]);
    }
  };

  const validateRegister = () => {
    let validated = true;
    if (
      !registerAnalysis.date ||
      !registerAnalysis._grade ||
      registerAnalysis.analysis.length < 4
    )
      validated = false;

    console.log(registerAnalysis.analysis, "<>>>>>");
    registerAnalysis.analysis.forEach(item => {
      if (
        !item._lfce ||
        !item._teacher ||
        !item.status ||
        !item.content ||
        !item.time
      )
        validated = false;
    });

    return validated;
  };

  const submitHandler = async e => {
    e.preventDefault();

    if (user.userType !== "supervisor") return;

    // const validated = validateRegister();

    // if (!validated) return alert("Please fill the form correctly!");

    const data = {
      ...registerAnalysis,
      date,
    };

    const res = await updateRegisterAnalysis(data);

    if (res) {
      if (res.classRegisterAnalysis)
        setRegisterAnalysis({ ...res.classRegisterAnalysis });
      alert("Success!");
    }
  };

  const changeHandler = (e, time) => {
    if (user.userType !== "supervisor") return;
    const { name, value, checked } = e.target;

    const timeAnalysisIndex = registerAnalysis.analysis.findIndex(
      item => item.time.toString() === time.toString()
    );

    console.log(registerAnalysis, timeAnalysisIndex);
    console.log(registerAnalysis, typeof timeAnalysisIndex);

    if (timeAnalysisIndex !== -1) {
      registerAnalysis.analysis[timeAnalysisIndex] = {
        ...registerAnalysis.analysis[timeAnalysisIndex],
        [name]: name === "forInstructors" ? checked : value,
      };
    } else {
      registerAnalysis.analysis.push({
        _lfce: "",
        content: "",
        status: "",
        _teacher: user?.user,
        forInstructors: false,
        time,
        [name]: name === "forInstructors" ? checked : value,
      });
    }

    console.log(registerAnalysis, "<----");

    setRegisterAnalysis({
      ...registerAnalysis,
    });
  };

  const lfceChangeHandler = (e, time) => {
    if (user.userType !== "supervisor") return;

    const selectedLFCE = lfces.find(item => item._id === e.target.value);

    console.log(selectedLFCE);

    if (selectedLFCE) {
      const timeAnalysisIndex = registerAnalysis.analysis.findIndex(
        item => item.time.toString() === time.toString()
      );

      if (timeAnalysisIndex !== -1) {
        registerAnalysis.analysis[timeAnalysisIndex] = {
          ...registerAnalysis.analysis[timeAnalysisIndex],
          _lfce: selectedLFCE,
        };
      } else {
        registerAnalysis.analysis.push({
          _lfce: selectedLFCE,
          content: "",
          status: "",
          _supervisor: "",
          forInstructors: false,
          time,
        });
      }

      setRegisterAnalysis({
        ...registerAnalysis,
      });
    }
  };

  return (
    <>
      <h3 style={{ textAlign: "center", marginBottom: "2rem" }}>
        Klassenbuch Analyse
      </h3>
      <table className='classAnalysis__table'>
        <tr>
          <th>Zeit</th>
          <th>LF/CE</th>
          <th>Lehrer</th>
          <th>Inhalt</th>
          <th>Lernziele (Portfolio)</th>
          <th>Kompetenzen I-V</th>
          <th>Status</th>
          <th>Für PAs relevant</th>
        </tr>
        <tr style={{ border: "1px solid red" }}>
          <td>08:00-09:30</td>
          <td>
            <CustomSelection
              name='lfce'
              isNested={true}
              options={lfces?.map(lfce => ({
                value: lfce._id,
                title: lfce?.title,
              }))}
              value={
                registerAnalysis?.analysis?.find(
                  item => item.time.toString() === "8"
                )?._lfce?._id || ""
              }
              onChange={e => lfceChangeHandler(e, 8)}
            />
          </td>
          <td>
            <CustomSelection
              name='_supervisor'
              isNested={true}
              options={supervisors.map(supervisor => ({
                value: supervisor._id,
                title: `${supervisor.firstName} ${supervisor.name} `,
              }))}
              value={
                registerAnalysis?.analysis?.find(
                  item => item.time.toString() === "8"
                )?._supervisor || ""
              }
              onChange={e => changeHandler(e, 8)}
            />
          </td>
          <td>
            <CustomInput
              name='content'
              placeholder='Inhalt'
              value={
                registerAnalysis?.analysis?.find(
                  item => item.time.toString() === "8"
                )?.content || ""
              }
              onChange={e => changeHandler(e, 8)}
            />
          </td>
          <td>
            {registerAnalysis?.analysis
              ?.find(item => item.time.toString() === "8")
              ?._lfce?._learningObjectives?.map(item => `${item?.title} `) ||
              ""}
          </td>
          <td>
            {registerAnalysis?.analysis?.find(
              item => item.time.toString() === "8"
            )?._lfce?.competencyIV || ""}
          </td>
          <td>
            <CustomSelection
              name='status'
              options={statuses}
              value={
                registerAnalysis?.analysis?.find(
                  item => item.time.toString() === "8"
                )?.status || ""
              }
              onChange={e => changeHandler(e, 8)}
            />
          </td>
          <td>
            <input
              type='checkbox'
              name='forInstructors'
              id=''
              checked={
                registerAnalysis?.analysis?.find(
                  item => item.time.toString() === "8"
                )?.forInstructors || false
              }
              onChange={e => changeHandler(e, 8)}
            />
          </td>
        </tr>
        <tr style={{ border: "1px solid red" }}>
          <td>10:00-11:30</td>
          <td>
            <CustomSelection
              name='lfce'
              isNested={true}
              options={lfces?.map(lfce => ({
                value: lfce._id,
                title: lfce?.title,
              }))}
              onChange={e => lfceChangeHandler(e, 10)}
              value={
                registerAnalysis?.analysis?.find(
                  item => item.time.toString() === "10"
                )?._lfce?._id || ""
              }
            />
          </td>
          <td>
            <CustomSelection
              name='_supervisor'
              isNested={true}
              options={supervisors.map(supervisor => ({
                value: supervisor._id,
                title: `${supervisor.firstName} ${supervisor.name} `,
              }))}
              value={
                registerAnalysis?.analysis?.find(
                  item => item.time.toString() === "10"
                )?._supervisor || ""
              }
              onChange={e => changeHandler(e, 10)}
            />
          </td>
          <td>
            <CustomInput
              name='content'
              placeholder='Inhalt'
              value={
                registerAnalysis?.analysis?.find(
                  item => item.time.toString() === "10"
                )?.content || ""
              }
              onChange={e => changeHandler(e, 10)}
            />
          </td>
          <td>
            {registerAnalysis?.analysis
              ?.find(item => item.time.toString() === "10")
              ?._lfce?._learningObjectives?.map(item => `${item?.title} `) ||
              ""}
          </td>
          <td>
            {registerAnalysis?.analysis?.find(
              item => item.time.toString() === "10"
            )?._lfce?.competencyIV || ""}
          </td>
          <td>
            <CustomSelection
              name='status'
              options={statuses}
              value={
                registerAnalysis?.analysis?.find(
                  item => item.time.toString() === "10"
                )?.status || ""
              }
              onChange={e => changeHandler(e, 10)}
            />
          </td>
          <td>
            <input
              type='checkbox'
              name='forInstructors'
              id=''
              checked={
                registerAnalysis?.analysis?.find(
                  item => item.time.toString() === "10"
                )?.forInstructors || false
              }
              onChange={e => changeHandler(e, 10)}
            />
          </td>
        </tr>
        <tr style={{ border: "1px solid red" }}>
          <td>12:00-13:30</td>
          <td>
            <CustomSelection
              name='lfce'
              isNested={true}
              options={lfces?.map(lfce => ({
                value: lfce._id,
                title: lfce?.title,
              }))}
              onChange={e => lfceChangeHandler(e, 12)}
              value={
                registerAnalysis?.analysis?.find(
                  item => item.time.toString() === "12"
                )?._lfce?._id || ""
              }
            />
          </td>
          <td>
            <CustomSelection
              name='_supervisor'
              isNested={true}
              options={supervisors.map(supervisor => ({
                value: supervisor._id,
                title: `${supervisor.firstName} ${supervisor.name} `,
              }))}
              value={
                registerAnalysis?.analysis?.find(
                  item => item.time.toString() === "12"
                )?._supervisor || ""
              }
              onChange={e => changeHandler(e, 12)}
            />
          </td>
          <td>
            <CustomInput
              name='content'
              placeholder='Inhalt'
              value={
                registerAnalysis?.analysis?.find(
                  item => item.time.toString() === "12"
                )?.content || ""
              }
              onChange={e => changeHandler(e, 12)}
            />
          </td>
          <td>
            {registerAnalysis?.analysis
              ?.find(item => item.time.toString() === "12")
              ?._lfce?._learningObjectives?.map(item => `${item?.title} `) ||
              ""}
          </td>
          <td>
            {registerAnalysis?.analysis?.find(
              item => item.time.toString() === "12"
            )?._lfce?.competencyIV || ""}
          </td>
          <td>
            <CustomSelection
              name='status'
              options={statuses}
              value={
                registerAnalysis?.analysis?.find(
                  item => item.time.toString() === "12"
                )?.status || ""
              }
              onChange={e => changeHandler(e, 12)}
            />
          </td>
          <td>
            <input
              type='checkbox'
              name='forInstructors'
              id=''
              checked={
                registerAnalysis?.analysis?.find(
                  item => item.time.toString() === "12"
                )?.forInstructors || false
              }
              onChange={e => changeHandler(e, 12)}
            />
          </td>
        </tr>
        <tr style={{ border: "1px solid red" }}>
          <td>13:45-15:15</td>
          <td>
            <CustomSelection
              name='lfce'
              isNested={true}
              options={lfces?.map(lfce => ({
                value: lfce._id,
                title: lfce?.title,
              }))}
              onChange={e => lfceChangeHandler(e, "13:45")}
              value={
                registerAnalysis?.analysis?.find(
                  item => item.time.toString() === "13:45"
                )?._lfce?._id || ""
              }
            />
          </td>
          <td>
            <CustomSelection
              name='_supervisor'
              isNested={true}
              options={supervisors.map(supervisor => ({
                value: supervisor._id,
                title: `${supervisor.firstName} ${supervisor.name} `,
              }))}
              value={
                registerAnalysis?.analysis?.find(
                  item => item.time.toString() === "13:45"
                )?._supervisor || ""
              }
              onChange={e => changeHandler(e, "13:45")}
            />
          </td>
          <td>
            <CustomInput
              name='content'
              placeholder='Inhalt'
              value={
                registerAnalysis?.analysis?.find(
                  item => item.time.toString() === "13:45"
                )?.content || ""
              }
              onChange={e => changeHandler(e, "13:45")}
            />
          </td>
          <td>
            {registerAnalysis?.analysis
              ?.find(item => item.time.toString() === "13:45")
              ?._lfce?._learningObjectives?.map(item => `${item?.title} `) ||
              ""}
          </td>
          <td>
            {registerAnalysis?.analysis?.find(
              item => item.time.toString() === "13:45"
            )?._lfce?.competencyIV || ""}
          </td>
          <td>
            <CustomSelection
              name='status'
              options={statuses}
              value={
                registerAnalysis?.analysis?.find(
                  item => item.time.toString() === "13:45"
                )?.status || ""
              }
              onChange={e => changeHandler(e, "13:45")}
            />
          </td>
          <td>
            <input
              type='checkbox'
              name='forInstructors'
              id=''
              checked={
                registerAnalysis?.analysis?.find(
                  item => item.time.toString() === "13:45"
                )?.forInstructors || false
              }
              onChange={e => changeHandler(e, "13:45")}
            />
          </td>
        </tr>
      </table>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "flex-end",
          marginTop: "1rem",
        }}
      >
        {user.userType === "supervisor" && (
          <CSmButton title='Submit' onClick={submitHandler} />
        )}
      </div>
    </>
  );
};

export default ClassRegisterAnalysisTable;
