import { Box, Modal } from "@mui/material";
import React from "react";
import { useState } from "react";
import CSmButton from "../../../../components/Form/CSmButton";
import CustomInput from "../../../../components/Form/CustomInput";
import useAdmin from "../../../../hooks/useAdmin";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "none",
  borderRadius: "0.5rem",
  boxShadow: 24,
  outline: "none",
  p: 4,
};

const AddModal = ({ open, setOpen, type, items, setItems, _parent }) => {
  const handleClose = () => setOpen(false);

  const [loading, setLoading] = useState(false);

  const { addCategory, addOption, addTest, addSubTest } = useAdmin();

  const [form, setForm] = useState({
    title: "",
    subTitle: "",
  });

  const submitHandler = async e => {
    e.preventDefault();
    if (loading) return;

    const func =
      type === "Category"
        ? addCategory
        : type === "Category Option"
        ? addOption
        : type === "Test"
        ? addTest
        : addSubTest;

    const data = form;
    if (type === "Category Option") data["_competencyCat"] = _parent;
    else if (type === "Test") data["_competencyCategoryOption"] = _parent;

    setLoading(true);
    const response = await func(data);
    if (response) {
      setItems([...items, response.item]);
      handleClose();
    }
    setLoading(false);
  };

  const changeHandler = e => {
    const { name, value } = e.target;
    setForm(pS => ({
      ...pS,
      [name]: value,
    }));
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
      sx={{ background: "rgba(255, 255, 255, 0.15)" }}
    >
      <Box sx={style}>
        <div className='dashboardAddModal'>
          <h3>Add {type}</h3>
          <form onSubmit={submitHandler}>
            <CustomInput
              style={{ background: "#ECEFF7", padding: "0.5rem" }}
              placeholder='Title'
              name='title'
              onChange={changeHandler}
              required={true}
            />
            {type === "Sub-Test" && (
              <>
                {/* <CustomInput
                  style={{ background: "#ECEFF7", padding: "0.5rem" }}
                  placeholder='Video Link'
                  name='videoLink'
                  onChange={changeHandler}
                  required={true}
                  type='url'
                />
                <CustomInput
                  style={{ background: "#ECEFF7", padding: "0.5rem" }}
                  placeholder='Audio Link'
                  name='audioLink'
                  onChange={changeHandler}
                  required={true}
                  type='url'
                />
                <CustomInput
                  style={{ background: "#ECEFF7", padding: "0.5rem" }}
                  placeholder='PDF Link 1'
                  name='pdfLink1'
                  onChange={changeHandler}
                  required={true}
                  type='url'
                />
                <CustomInput
                  style={{ background: "#ECEFF7", padding: "0.5rem" }}
                  placeholder='PDF Link 2'
                  name='pdfLink2'
                  type='url'
                  onChange={changeHandler}
                  required={true}
                /> */}
              </>
            )}
            <CSmButton title='Submit' type='submit' />
          </form>
        </div>
      </Box>
    </Modal>
  );
};

export default AddModal;
