import React from "react";
import { useEffect } from "react";
import { useMemo } from "react";
import { useState } from "react";
import { useLocation } from "react-router";
import CInputWithLabel from "../../../components/Form/CInputWithLabel";
import CSelectWithLabel from "../../../components/Form/CSelectWithLabel";
import CSmButton from "../../../components/Form/CSmButton";
import useAdmin from "../../../hooks/useAdmin";
import useTeacher from "../../../hooks/useTeacher";
import useUser from "../../../hooks/useUser";
import AnalysisData from "./AnalysisData";

const ClassRegisterAnalysisTeacher = () => {
  const location = useLocation();
  const splitted = location.pathname.split("/");
  const _grade = splitted[splitted.length - 1];

  const { getRegisterAnalysisComplete } = useTeacher();
  const { getLFCEs } = useAdmin();
  const { getSupervisors } = useUser();

  const [registerAnalysises, setRegisterAnalysises] = useState([]);
  const [filteredRegister, setFilteredRegister] = useState([]);
  const [lfces, setLFCEs] = useState([]);
  const [supervisors, setSupervisors] = useState([]);

  const [filter, setFilter] = useState({
    date: "",
    lfce: "",
    supervisor: "",
    content: "",
  });
  const [showSearchResults, setShowSearchResults] = useState(false);

  let areFiltersApplied = useMemo(
    () => filter.date || filter.lfce || filter.supervisor || filter.content,
    [filter]
  );

  useEffect(() => {
    const getInitialData = async () => {
      const [analysisRes, supRes, lfceRes] = await Promise.all([
        getRegisterAnalysisComplete(_grade),
        getSupervisors(),
        getLFCEs(),
      ]);
      if (analysisRes)
        setRegisterAnalysises([...analysisRes.classRegisterAnalysises]);
      if (supRes) setSupervisors([...supRes.supervisors]);
      if (lfceRes) setLFCEs([...lfceRes.lfces]);
    };
    getInitialData();
  }, []);

  const filterChangeHandler = event => {
    const { name, value } = event.target;
    setFilter(pS => ({
      ...pS,
      [name]: value,
    }));
  };

  const clearFilters = () => {
    setFilter({
      date: "",
      lfce: "",
      supervisor: "",
      content: "",
    });
    setFilteredRegister([]);
    setShowSearchResults(false);
  };

  const filterNow = async () => {
    if (!areFiltersApplied) return;

    setShowSearchResults(true);

    let tempFiltered = JSON.parse(JSON.stringify(registerAnalysises)).filter(
      ra => {
        if (filter.date) {
          let dateStart = new Date(
            new Date(filter.date).getFullYear(),
            new Date(filter.date).getMonth(),
            new Date(filter.date).getDate(),
            0,
            0,
            0
          );
          let dateEnd = new Date(
            new Date(filter.date).getFullYear(),
            new Date(filter.date).getMonth(),
            new Date(filter.date).getDate(),
            23,
            59,
            59
          );

          return new Date(ra.date) > dateStart && new Date(ra.date) < dateEnd;
        }

        return true;
      }
    );

    tempFiltered.forEach(ra => {
      let filteredAnalysiss = ra.analysis.filter(an => {
        let lfceFilter = true;
        let supervisorFilter = true;
        let contentFilter = true;
        if (filter.lfce) lfceFilter = an._lfce?._id === filter.lfce;
        if (filter.supervisor)
          supervisorFilter = an?._supervisor?._id === filter.supervisor;
        if (filter.content) contentFilter = an.content.includes(filter.content);

        return lfceFilter && supervisorFilter && contentFilter;
      });

      ra.analysis = filteredAnalysiss;
    });

    setFilteredRegister([...tempFiltered]);
  };

  let data = showSearchResults ? filteredRegister : registerAnalysises;
  return (
    <>
      <h3 style={{ textAlign: "center", marginBottom: "2rem" }}>
        Klassenbuch Analyse
      </h3>
      <div className='classAnalysis__teacher__row'>
        <CInputWithLabel
          type='date'
          label='Datum'
          style={{ width: "17.5rem" }}
          name='date'
          id='date'
          placeholder='All Dates'
          value={filter.date}
          onChange={filterChangeHandler}
        />
        <CSelectWithLabel
          label='LF/CE'
          style={{ width: "17.5rem" }}
          isNested={true}
          options={[
            { value: "", title: "All" },
            ...lfces?.map(lfce => ({
              value: lfce._id,
              title: lfce?.title,
            })),
          ]}
          name='lfce'
          id='lfce'
          value={filter.lfce}
          onChange={filterChangeHandler}
        />
        <CSelectWithLabel
          label='Lehrer'
          style={{ width: "17.5rem" }}
          isNested={true}
          options={[
            { value: "", title: "All" },
            ...supervisors.map(supervisor => ({
              value: supervisor._id,
              title: `${supervisor.firstName} ${supervisor.name} `,
            })),
          ]}
          name='supervisor'
          id='supervisor'
          value={filter.supervisor}
          onChange={filterChangeHandler}
        />

        <CInputWithLabel
          label='Inhalt'
          style={{ width: "17.5rem" }}
          name='content'
          id='content'
          placeholder='All content'
          value={filter.content}
          onChange={filterChangeHandler}
        />
        <CSmButton
          title='Filter'
          onClick={filterNow}
          style={{ height: "2rem", alignSelf: "flex-end" }}
        />

        <span
          style={{
            height: "2rem",
            alignSelf: "flex-end",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: "0.7rem",
            color: "#00c2ff",
            cursor: "pointer",
          }}
          onClick={clearFilters}
        >
          Filter zurücksetzen
        </span>
      </div>
      <table className='classAnalysis__table'>
        <tr>
          <th>Zeit</th>
          <th>LF/CE</th>
          <th>Lehrer</th>
          <th>Inhalt</th>
          <th>Lernobjektive (Portfolio)</th>
          <th>Kompetenzen I-V</th>
          <th>Status</th>
          <th>für PAs</th>
        </tr>
        {data.map(registerAnalysis => (
          <AnalysisData
            registerAnalysis={registerAnalysis}
            key={registerAnalysis._id}
          />
        ))}
      </table>
    </>
  );
};

export default ClassRegisterAnalysisTeacher;
