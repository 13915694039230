import React, { useEffect, useState } from "react";
import Navbar from "../../components/Navbar";
import ClassModal from "./components/ClassModal";
import Loading from "../../components/Common/Loading";
import useStudent from "../../hooks/useStudent";
import useAuthenticate from "../../hooks/useAuthenticate";
import { useNavigate } from "react-router";

const Classes = () => {
  const navigate = useNavigate();
  const { isAuthenticated } = useAuthenticate();
  const { addClass, getClasses, getNotJoinedClasses, joinClass } = useStudent();
  const [open, setOpen] = useState(false);

  const [tab, setTab] = useState("Classes");

  const [classes, setClasses] = useState([]);
  const [notJoinedClasses, setNotJoinedClasses] = useState([]);
  const [classToAdd, setClassToAdd] = useState("");
  const [searchKeyword, setSearchKeyword] = useState("");
  const [loading, setLoading] = useState(true);

  let user = isAuthenticated(false, ["teacher", "supervisor"]);

  console.log(user);

  useEffect(() => {
    getInitialData();
  }, []);

  const getInitialData = async () => {
    const [classesResponse, notJoinedRes] = await Promise.all([
      getClasses(),
      getNotJoinedClasses(),
    ]);
    if (classesResponse) setClasses([...classesResponse.classes]);
    if (notJoinedRes) setNotJoinedClasses([...notJoinedRes.classes]);
    setLoading(false);
  };

  const changeHandler = e => setClassToAdd(e.target.value);

  const submitHandler = async () => {
    if (!classToAdd) return alert("Please fill the form correctly!");

    const response = await addClass(classToAdd);
    if (response) setClasses([...classes, response.class]);
    setOpen(false);
  };

  const joinGrade = async _class => {
    const response = await joinClass(_class);
    if (response) {
      alert("Class Joined successfully!");
      const filteredClasses = notJoinedClasses.filter(
        item => item._id !== _class
      );
      setNotJoinedClasses([...filteredClasses]);
    }
  };

  let classesToDisplay = tab === "Classes" ? classes : notJoinedClasses;

  let filteredClasses = classesToDisplay.filter(cl =>
    cl.className.includes(searchKeyword)
  );

  if (loading) return <Loading />;

  return (
    <>
      <Navbar />

      <div className='supervisorContainer'>
        {/* <div className='supervisorContainer__searchBar'>
          <input
            type='text'
            placeholder='Search'
            name='search'
            value={searchKeyword}
            className='signUpContainer__fields__input'
            onChange={e => setSearchKeyword(e.target.value)}
          />
        </div> */}

        <div className='supervisorContainer__addButtonContainer'>
          {user.user.userType === "teacher" && (
            <button
              onClick={() =>
                setTab(pS => (pS === "Classes" ? "Join" : "Classes"))
              }
            >
              {tab === "Classes" ? "Join Class" : "My Classes"}
            </button>
          )}
          {user.user.userType === "supervisor" && (
            <button onClick={setOpen} className='primaryBtn'>
              Klasse erstellen
            </button>
          )}
        </div>

        <div className='supervisorContainer__container'>
          <div className='supervisorContainer__container__classes'>
            <div className='supervisorContainer__container__classes__heading'>
              <div className='supervisorContainer__container__classes__heading__name'>
                <b> Klasse</b>
              </div>
              <div className='supervisorContainer__container__classes__heading__numberOfStudent'>
                <b> Anzahl der Auszubildenden</b>
              </div>
              {tab === "Join" && (
                <div className='supervisorContainer__container__classes__heading__numberOfStudent'>
                  <b>Action</b>
                </div>
              )}
            </div>
            {filteredClasses.map((cl, index) => (
              <div
                className='supervisorContainer__container__classes__data'
                key={cl._id}
                onClick={() => {
                  if (tab !== "Classes") return;
                  navigate(`/students/${cl._id}`);
                }}
                style={{ cursor: tab === "Classes" ? "pointer" : "auto" }}
              >
                <div className='supervisorContainer__container__classes__data__name'>
                  {cl.className}
                </div>
                <div className='supervisorContainer__container__classes__data__numberOfStudent'>
                  {cl?.noOfStudents}
                </div>
                {tab === "Join" && (
                  <div className='supervisorContainer__container__classes__data__numberOfStudent'>
                    <button
                      className='primaryBtn'
                      onClick={() => joinGrade(cl._id)}
                    >
                      Beitreten
                    </button>
                  </div>
                )}
                {/* <ArrowForwardIosIcon sx={{ fontSize: ".8rem" }} /> */}
              </div>
            ))}
          </div>
        </div>
      </div>
      <ClassModal
        open={open}
        setOpen={setOpen}
        changeHandler={changeHandler}
        submitHandler={submitHandler}
      />
    </>
  );
};

export default Classes;
