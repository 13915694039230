import { Box, Modal } from "@mui/material";
import React from "react";
import { useState } from "react";
import CSmButton from "../../../../components/Form/CSmButton";
import CustomInput from "../../../../components/Form/CustomInput";
import CustomSelection from "../../../../components/Form/CustomSelection";
import useAdmin from "../../../../hooks/useAdmin";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "none",
  borderRadius: "0.5rem",
  boxShadow: 24,
  outline: "none",
  p: 4,
};

const AddModal = ({
  open,
  setOpen,
  type,
  items,
  setItems,
  _parent,
  _grandParent,
  _greatGrandParent,
  subTests,
}) => {
  const handleClose = () => setOpen(false);

  const [loading, setLoading] = useState(false);

  const {
    createAssessmentCategory,
    addCategory,
    addOption,
    addTest,
    addSubTest,
    createCategoryTest,
    createCategoryOption,
  } = useAdmin();

  const [form, setForm] = useState({
    title: "",
    subTitle: "",
    desc: "",
  });

  const submitHandler = async e => {
    e.preventDefault();
    console.log("HEHRHE");
    if (loading) return;

    console.log("HEHRHE");

    const func =
      type === "Category"
        ? createAssessmentCategory
        : type === "Category Option"
        ? addOption
        : type === "Test"
        ? createCategoryTest
        : createCategoryOption;

    const data = form;
    // if (type === "Category Option") data["_competencyCat"] = _parent;
    if (type === "Test") {
      if (!_parent) return alert("Please select an Assessment Category!");
      if (!_grandParent) return alert("Please select a subtest!");
      if (!_greatGrandParent) return alert("Please select a test!");

      data["_competencyAssessmentCat"] = _parent;
      data["_competencySubTest"] = _grandParent;
      data["_competencyCatOptionTest"] = _greatGrandParent;
    }

    if (type === "Option") {
      if (!_parent) return alert("Please select an Assessment Test!");
      data["_competencyAssessmentCategoryTest"] = _parent;
    }

    setLoading(true);
    const response = await func(data);
    if (response) {
      console.log(response);

      setItems([...items, response.item]);
      handleClose();
    }
    setLoading(false);
  };

  const changeHandler = e => {
    const { name, value } = e.target;
    setForm(pS => ({
      ...pS,
      [name]: value,
    }));
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
      sx={{ background: "rgba(255, 255, 255, 0.15)" }}
    >
      <Box sx={style}>
        <div className='dashboardAddModal'>
          <h3>Add {type}</h3>
          <form onSubmit={submitHandler}>
            {type === "Category" && (
              <CustomSelection
                options={subTests.map(sT => sT.title)}
                style={{ background: "#ECEFF7", padding: "0.5rem" }}
                name={"title"}
                onChange={changeHandler}
                required={true}
              />
            )}

            {type !== "Category" && (
              <CustomInput
                style={{ background: "#ECEFF7", padding: "0.5rem" }}
                placeholder={type !== "Test" ? "Title" : "Description"}
                name={type !== "Test" ? "title" : "desc"}
                onChange={changeHandler}
                required={true}
              />
            )}

            {type === "Option" && (
              <>
                <CustomInput
                  style={{ background: "#ECEFF7", padding: "0.5rem" }}
                  placeholder='Description'
                  name={"desc"}
                  onChange={changeHandler}
                  required={true}
                />
                <CustomInput
                  style={{ background: "#ECEFF7", padding: "0.5rem" }}
                  placeholder='Grade'
                  name={"grade"}
                  onChange={changeHandler}
                  required={true}
                  type='number'
                />
              </>
            )}

            <CSmButton title='Submit' type='submit' />
          </form>
        </div>
      </Box>
    </Modal>
  );
};

export default AddModal;
