export const simpleOptions = {
  orientationmission: [
    {
      desc: "I. Responsibly plan, organize, design, implement, control and evaluate nursing processes and nursing diagnostics in acute and permanent care situations.",
      path: "/optionquestions/orientationmission1",
    },
    {
      desc: "II Making communication and advice person- and situation-oriented.",
      path: "/optionquestions/orientationmission2",
    },
    {
      desc: "III Responsibly shaping and co-shaping intra- and interprofessional action in different systemic contexts.",
      path: "/optionquestions/orientationmission3",
    },
  ],
};
