import React from "react";

const SimpleBox = ({ item, onClick }) => {
  return (
    <div className='simpleOptions__options__item' onClick={onClick}>
      <span>{item.desc}</span>
    </div>
  );
};

export default SimpleBox;
