import React from "react";

const CFWButton = ({ title, onClick, isWhite }) => {
  return (
    <button
      className='cFWButton'
      onClick={onClick}
      style={
        isWhite
          ? { background: "white", color: "black", fontWeight: "600" }
          : {}
      }
    >
      {title}
    </button>
  );
};

export default CFWButton;
