import axios from "axios";
import { BACKEND_API_URL } from "../helpers/variables";
import useHandleErrors from "./useHandleErrors";

const useUpload = () => {
  const { handleErrors } = useHandleErrors();

  const getPreSignedUrl = async type => {
    try {
      const _type = type.split("/")[type.split("/").length - 1];
      const response = await axios.get(
        `${BACKEND_API_URL}/api/upload/preSignedURL/${_type}`
      );
      return response.data.url;
    } catch (err) {
      handleErrors(err);
    }
  };

  const uploadImage = async image => {
    try {
      const url = await getPreSignedUrl(image.type);

      const response = await axios.put(url, image, {
        headers: {
          ContentType: image.type,
        },
      });

      const imageUrl = response?.config?.url?.split("?")[0];

      return imageUrl;
    } catch (err) {
      handleErrors(err);
    }
  };

  const uploadFile = async file => {
    try {
      const url = await getPreSignedUrl(file.type);

      const response = await axios.put(url, file, {
        headers: {
          ContentType: file.type,
        },
      });

      const fileUrl = response?.config?.url?.split("?")[0];

      return fileUrl;
    } catch (err) {
      handleErrors(err);
    }
  };

  const uploadKilianFile = async data => {
    try {
      const response = await axios.post(
        `${BACKEND_API_URL}/teacher/kilianUploads`,
        data
      );
      return response.data;
    } catch (err) {
      handleErrors(err);
    }
  };

  const geKilianUploads = async (
    _competencyCategoryOptionSubTest,
    type,
    _student
  ) => {
    try {
      const response = await axios.get(
        `${BACKEND_API_URL}/teacher/kilianUploads/${_competencyCategoryOptionSubTest}/${type}/${_student}`
      );
      return response.data;
    } catch (err) {
      handleErrors(err);
    }
  };

  const getComptencyCategoryOptionSubTest =
    async _competencyCategoryOptionSubTest => {
      try {
        const response = await axios.post(
          `${BACKEND_API_URL}/teacher/kilianUploads/competencySubTestById/${_competencyCategoryOptionSubTest}`
        );
        return response.data;
      } catch (err) {
        handleErrors(err);
      }
    };

  return {
    getPreSignedUrl,
    uploadImage,
    uploadFile,
    uploadKilianFile,
    geKilianUploads,
    getComptencyCategoryOptionSubTest,
  };
};

export default useUpload;
