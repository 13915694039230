import React from "react";
import RPageWithSideBar from "./components/RPageWithSideBar";

const AdminPage = ({ children }) => {
  return (
    <div className='adminPage'>
      <RPageWithSideBar children={children} />
    </div>
  );
};

export default AdminPage;
