import React, { useEffect, useState } from "react";
import Navbar from "../../components/Navbar";
import useStudent from "../../hooks/useStudent";
import { useLocation, useNavigate } from "react-router";
import OptionBox from "../../components/Common/OptionBox";
import Loading from "../../components/Common/Loading";
import { USER_STORAGE_KEY } from "../../helpers/variables";

const Students = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const splitted = location.pathname.split("/");
  const _class = splitted[splitted.length - 1];

  const user = JSON.parse(localStorage.getItem(USER_STORAGE_KEY));

  const [loading, setLoading] = useState(true);
  const { getClassStudents } = useStudent();
  const [students, setStudents] = useState([]);

  useEffect(() => {
    getStudents();
  }, []);

  const getStudents = async () => {
    if (!_class) return alert("Bitte eine Klasse auswählen!");

    const response = await getClassStudents(_class);

    if (response) setStudents([...response.students]);
    setLoading(false);
  };

  if (loading) return <Loading />;

  const navigateToStudentDetails = _student => {
    if (user?.user?.userType !== "teacher") return;
    navigate(`/${_student}`);
  };

  return (
    <>
      <Navbar />
      <div className='teachContainer'>
        <header className='teachContainer__header'> {/*Name of currently selected Class*/}
          <h3>Klasse A</h3>              
        </header>
        <div className='teachContainer__container'>
          <div className='teachContainer__container__classes'>
            <table>
              <tr>
                <th></th>
                <th>Vorname</th>
                <th>Nachname</th>
                <th>Email</th>
                <th>Telefon</th>
              </tr>
              {students.map((st, index) => (
                <tr
                  key={st._id}
                  onClick={() => navigateToStudentDetails(st._id)}
                  style={
                    user?.user?.userType === "teacher"
                      ? styles.teacherRow
                      : styles.supervisorRow
                  }
                >
                  <td
                    style={
                      user?.user?.userType === "teacher"
                        ? styles.teacherRow
                        : styles.supervisorRow
                    }
                  >
                    {index + 1}
                  </td>
                  <td
                    style={
                      user?.user?.userType === "teacher"
                        ? styles.teacherRow
                        : styles.supervisorRow
                    }
                  >
                    {st.firstName || "-"}
                  </td>
                  <td
                    style={
                      user?.user?.userType === "teacher"
                        ? styles.teacherRow
                        : styles.supervisorRow
                    }
                  >
                    {st.name}
                  </td>
                  <td
                    style={
                      user?.user?.userType === "teacher"
                        ? styles.teacherRow
                        : styles.supervisorRow
                    }
                  >
                    {st.email}
                  </td>
                  <td
                    style={
                      user?.user?.userType === "teacher"
                        ? styles.teacherRow
                        : styles.supervisorRow
                    }
                  >
                    {st.contact || "-"}
                  </td>
                </tr>
              ))}
            </table>
          </div>
        </div>

        <div className='teachContainer__options'>
          <OptionBox
            title='Klassenbuch Analyse'
            path={`/classRegisterAnalysis/${_class}`}
          />
        </div>
      </div>
    </>
  );
};

const styles = {
  teacherRow: {
    cursor: "pointer",
  },
  supervisorRow: {
    cursor: "auto",
  },
};

export default Students;
