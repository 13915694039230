import React from "react";
import { useLocation } from "react-router";
import OptionBox from "../../components/Common/OptionBox";
import Navbar from "../../components/Navbar";

const AssessmentForm = () => {
  const location = useLocation();
  const splitted = location.pathname.split("/");
  const _student = splitted[splitted.length - 1];

  return (
    <>
      <Navbar />

      <div style={styles.container}>
        <OptionBox
          title='Kompetenzen'
          path={`/assessment/competency/${_student}`}
        />
        <OptionBox
          title='Orientierungseinsatz'
          path={`/assessment/orientationmission/${_student}`}
        />
      </div>
    </>
  );
};

const styles = {
  container: { paddingTop: "5rem", display: "flex", gap: "1rem" },
};

export default AssessmentForm;
