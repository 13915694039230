import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import Page from "../../components/Page";
import useStudent from "../../hooks/useStudent";

const EvaluationForm = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { getStudentForms } = useStudent();

  const [_student, setStudent] = useState("");
  const [forms, setForms] = useState([]);

  useEffect(() => {
    if (_student) {
      getInitialData();
    } else {
      let splitted = location.pathname.split("/");
      let studentId = splitted[splitted.length - 1];
      setStudent(studentId);
    }
  }, [_student]);

  const getInitialData = async () => {
    const response = await getStudentForms(_student);
    if (response) setForms([...response.forms]);
  };

  const redirectMe = _form => navigate(`/forma/${_form}`);
  return (
    <Page>
      <div className='evaluationContainer'>
        <div className='evaluationContainer__top'></div>
        <div className='evaluationContainer__boxes-R1'>
          {forms.map(fm => (
            <div
              className='evaluationContainer__boxes-R1__leftContainer'
              onClick={() => redirectMe(fm._id)}
            >
              <p>{fm.fName}</p>
              <img src={require("../../assets/homePage.png")} alt='' />
            </div>
          ))}
        </div>
      </div>
    </Page>
  );
};

export default EvaluationForm;
