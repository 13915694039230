import React from 'react'
import { useLocation } from 'react-router'

const NursingPhenomenaExplanation = () => {
  const { location } = useLocation();
  // let splittedLoc = location.pathname.split("/");
  // let option = splittedLoc[splittedLoc.length - 1];

  return (
    <section className='nursingPhenomenaExplanation'>
      <div className='nursingPhenomenaExplanation__body'>
        <header className='nursingPhenomenaExplanation__body__header'>
          <img src={require("../../assets/Logo.png")} alt="" />
          <h2>Arthrosis</h2>
        </header>
        <div className='nursingPhenomenaExplanation__body__container'>
          <p>
            In order to be able to participate in everyday life, regular and moderate exercise is important

            not to further promote joint wear. Especially with arthritic

            changes! Even small limitations can lead to significant dependency

            in everyday life and increase the need for help in self-employment. This has in turn

            the effect that self-determination also suffers and dependency increases.

            You learn to recognize the smallest changes in the movement process and promote them

            individual resources including the professional use of aids and

            Therapies as a supportive measure for medication, non-medication

            Treatment.
          </p>
          <div className='nursingPhenomenaExplanation__body__container__link'>
            <a href='' target="_blank">
              To the explanatory video
            </a>
          </div>
        </div>
      </div>
    </section>
  )
}

export default NursingPhenomenaExplanation