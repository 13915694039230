import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useLocation } from "react-router";
import AdminPage from "../../../components/AdminPage";
import CSmButton from "../../../components/Form/CSmButton";
import useAdmin from "../../../hooks/useAdmin";
import AddModal from "./components/AddModal";
import SubTestLinksModal from "./components/SubTestLinksModal";

const Assessment = () => {
  const location = useLocation();

  const splittedPath = location.pathname.split("/");
  const _subTest = splittedPath[splittedPath.length - 1];
  const _test = splittedPath[splittedPath.length - 2];

  const [categories, setCategories] = useState([]);
  const [options, setOptions] = useState([]);
  const [assessmentCats, setAssessmentCats] = useState([]);
  const [catTests, setCatTests] = useState([]);
  const [test, setTest] = useState([]);
  const [subTest, setSubTest] = useState([]);
  const [subTests, setSubTests] = useState([]);
  const [catOptions, setCatOptions] = useState([]);

  const [selectedSubTest, setSelectedSubTest] = useState("");
  const [selectedCat, setSelectedCat] = useState("");
  const [selectedCatTest, setSelectedCatTest] = useState("");
  const [selectedOption, setSelectedOption] = useState("");

  const [loading, setLoading] = useState(true);
  const [linksModalOpen, setLinksModalOpen] = useState(false);
  const [linksLoading, setLinksLoading] = useState(false);

  const [links, setLinks] = useState({
    videoLink: "",
    audioLink: "",
    pdfLink1: "",
    pdfLink2: "",
    _test: "",
    _subTest: "",
  });

  const {
    getAssessmentCategries,
    getSubTests,
    getAssessmentCategoryTests,
    getAssessmentCategoryOptions,
    getJustSubTests,
    getAssessmentTestNSubTest,
  } = useAdmin();

  useEffect(() => {
    getInitialData();
  }, []);

  const getInitialData = async () => {
    const [subtests, assessmentCategories, subTests] = await Promise.all([
      getAssessmentTestNSubTest(_test, _subTest),
      getAssessmentCategries("64145a3ee3330855306c9907"),
      getJustSubTests(),
    ]);

    console.log(subTests);

    subTests?.subTests && setSubTests([...subTests.subTests]);
    // subtests?.tests && setSubTests([...subtests.tests]);
    assessmentCategories?.competencyAssessmentCats &&
      setAssessmentCats([...assessmentCategories?.competencyAssessmentCats]);

    setLoading(false);
  };

  const getCatTestsNow = async (
    _competencyAssessmentCat,
    _competencySubTest
  ) => {
    if (!_subTest) return alert("Please select a subtest!");

    setSelectedCat(_competencyAssessmentCat);
    const response = await getAssessmentCategoryTests(
      _competencyAssessmentCat,
      _test,
      _subTest
    );
    if (response) {
      console.log(response?.competencyAssessmentTests);
      setCatTests([...response?.competencyAssessmentTests]);
    }

    console.log(_competencyAssessmentCat, _competencySubTest);
  };

  const getTestOptions = async _competencyAssessmentCategoryTest => {
    if (!_competencyAssessmentCategoryTest)
      return alert("Please select a Category Test!");

    setSelectedCatTest(_competencyAssessmentCategoryTest);

    const response = await getAssessmentCategoryOptions(
      _competencyAssessmentCategoryTest
    );
    if (response) {
      console.log(response?.competencyTestOptions);
      setCatOptions([...response?.competencyTestOptions]);
    }
  };

  const selectSubTest = _subTest => setSelectedSubTest(_subTest);

  return (
    <AdminPage>
      <div className='dashboard'>
        <div className='dashboard__tables'>
          <DashboardTable
            title='Assessment Categories'
            items={assessmentCats}
            setItems={setAssessmentCats}
            onAction={getCatTestsNow}
            _selected={selectedCat}
            _parent={selectedSubTest}
            loading={loading}
            subTests={subTests}
            boxStyle={{ width: "32%" }}
          />
          <DashboardTable
            title='Category Tests'
            items={catTests}
            setItems={setCatTests}
            onAction={getTestOptions}
            _selected={selectedCatTest}
            _parent={selectedCat}
            _grandParent={_subTest}
            _greatGrandParent={_test}
            loading={loading}
            boxStyle={{ width: "32%" }}
          />
          <DashboardTable
            title='Test Options'
            items={catOptions}
            setItems={setCatOptions}
            onAction={getTestOptions}
            _selected={selectedOption}
            _parent={selectedCatTest}
            loading={loading}
            boxStyle={{ width: "32%" }}
          />
        </div>
      </div>
      <SubTestLinksModal
        open={linksModalOpen}
        setOpen={setLinksModalOpen}
        links={links}
        setLinks={setLinks}
        loading={linksLoading}
      />
    </AdminPage>
  );
};

const DashboardTable = ({
  title,
  items,
  setItems,
  onAction,
  _selected,
  _parent,
  _grandParent,
  _greatGrandParent,
  loading,
  subTests,
  boxStyle,
}) => {
  const [addModalOpen, setAddModalOpen] = useState(false);

  return (
    <div className='dashboard__tables__table' style={{ ...boxStyle }}>
      {title !== "SubTests" && (
        <div className='dashboard__tables__table__add'>
          <CSmButton title='Add' onClick={() => setAddModalOpen(true)} />
        </div>
      )}
      {items.length > 0 ? (
        <>
          <h4>{title}</h4>

          <div className='dashboard__tables__table__headings'>
            <div className='dashboard__tables__table__headings__heading'>
              <b>Title</b>
            </div>
            <div className='dashboard__tables__table__headings__heading'></div>
          </div>
          {items.map(item => (
            <div className='dashboard__tables__table__item' key={item?._id}>
              <div className='dashboard__tables__table__item__col'>
                {item?.title || item?.desc}
              </div>

              {title !== "Test Options" && (
                <div className='dashboard__tables__table__item__col'>
                  <button
                    onClick={() => onAction(item?._id, _parent)}
                    style={
                      _selected === item?._id ? { background: "gray" } : {}
                    }
                  >
                    {title === "Assessment Categories"
                      ? "Test"
                      : title === "Category Tests"
                      ? "Options"
                      : "View Links"}
                  </button>
                </div>
              )}
            </div>
          ))}
        </>
      ) : (
        <div className='dashboard__tables__table__message'>
          <h5> {loading ? "Loading..." : `No ${title} to show!`} </h5>
        </div>
      )}
      <AddModal
        open={addModalOpen}
        setOpen={setAddModalOpen}
        type={
          title === "Assessment Categories"
            ? "Category"
            : title === "Category Tests"
            ? "Test"
            : title === "Tests"
            ? "Test"
            : title === "Test Options"
            ? "Option"
            : "Sub-Test"
        }
        items={items}
        setItems={setItems}
        _parent={_parent}
        _grandParent={_grandParent}
        subTests={subTests}
        _greatGrandParent={_greatGrandParent}
      />
    </div>
  );
};
export default Assessment;
