import React from "react";
import { useLocation, useNavigate } from "react-router";
import SimpleBox from "../../components/Common/SimpleBox";
import Navbar from "../../components/Navbar";
import SimpleOptionsTopBar from "./components/SimpleOptionsTopBar";
import { simpleOptions } from "./helpers/simpleOptions";

const SimpleOptions = () => {
  const navigate = useNavigate();
  const location = useLocation();
  let splittedLoc = location.pathname.split("/");
  let option = splittedLoc[splittedLoc.length - 1];

  const navigateFunc = path => {
    if (!path) return;
    navigate(path);
  };

  return (
    <>
      <Navbar />
      <div className='simpleOptions'>
        <SimpleOptionsTopBar option={option} />

        <div className='simpleOptions__options'>
          {simpleOptions[option]?.map(item => (
            <SimpleBox
              item={item}
              key={item.desc}
              onClick={() => navigateFunc(item.path)}
            />
          ))}
        </div>
      </div>
    </>
  );
};

export default SimpleOptions;
