import React from "react";
import { useNavigate } from "react-router";
import icon from "../../assets/icon.svg";

const OptionBox = ({ title, path, onClick }) => {
  const navigate = useNavigate();

  const redirectToPage = () => {
    if (!path) return;
    navigate(path);
  };

  return (
    <>
      <div className='optionBox' onClick={onClick || redirectToPage}>
        <h3>{title}</h3>
        <img src={icon} alt='' />
      </div>
      <br />
    </>
  );
};

export default OptionBox;
