export const questions = {
  communication: [
    {
      question:
        "The trainee can recognize and respond to insecurities and fears due to arthritic pain.",
      path: "/optionquestionanswer/communication1",
    },
    {
      question:
        "The trainee takes into account the desire for self-determination in the application of therapeutic, drug and non-drug measures.",
      path: "/optionquestionanswer/communication2",
    },
    {
      question:
        "The trainee asks the person to be cared for about the pain symptoms and knows the relationship according to the expert standard 'chronic pain'.",
      path: "/optionquestionanswer/communication3",
    },
    {
      question:
        "The trainee recognises changes in mobility due to arthritic changes in connection with fall prphylactic measures and advises on suitable measures to prevent a fall.",
      path: "/optionquestionanswer/communication4",
    },
    {
      question:
        "The trainee advises with regard to simple movement sequences and guides the person to be cared for in a targeted manner",
      path: "/optionquestionanswer/communication5",
    },
    {
      question:
        "he trainee knows and teaches the person to be cared for suitable measures in the independent handling of arthrothic pain.",
      path: "/optionquestionanswer/communication6",
    },
  ],
  abilitytoact: [
    {
      question:
        "The trainee recognises the need for help in coping with everyday life through arthritic changes and carries out assisted measures taking into account existing resources.",
      path: "/optionquestionanswer/abilitytoact1",
    },
    {
      question:
        "The trainee recognizes changes such as joint noises, deformations and swelling that lead to changes in the movement and takes this into account in his nursing actions.",
      path: "/optionquestionanswer/abilitytoact2",
    },
    {
      question:
        "The trainee uses professional aids and therapies, e.g. active, assistive and passive movement exercises to counteract arthritic changes.",
      path: "/optionquestionanswer/abilitytoact3",
    },
  ],
  abilitytoreflect: [
    {
      question:
        "The trainee is able to reflect on the observations and gain information for the further care process.",
      path: "/optionquestionanswer/abilitytoreflect1",
    },
    {
      question:
        "The trainee can justify both his own reaction and the reaction of the persons to be cared for and adapt his actions if necessary.",
      path: "/optionquestionanswer/abilitytoreflect2",
    },
    {
      question:
        "The trainee can accept constructive criticism and implement it in his future nursing activities.",
      path: "/optionquestionanswer/abilitytoreflect3",
    },
  ],
  ethicalcompetence: [
    {
      question:
        "The trainee perceives the emotions and reactions of the person to be cared for and his own person as natural reactions of the person and can adapt the care situation-oriented.",
      path: "/optionquestionanswer/ethicalcompetence1",
    },
    {
      question:
        "The trainee acts according to the ICN Code of Ethics and is able to recognize and promote the self-determination rights of the person to be cared for in every situation/state.",
      path: "/optionquestionanswer/ethicalcompetence2",
    },
    {
      question:
        "The trainee acts according to the ethical principle of autonomy and is able to recognize and promote the self-determination rights of the person to be cared for in every situation/state",
      path: "/optionquestionanswer/ethicalcompetence3",
    },
    {
      question:
        "The trainee acts according to the ethical principle of justice and is able to respect the person as a whole. This image of man is reflected in the nursing care as well as in the team without conditions",
      path: "/optionquestionanswer/ethicalcompetence3",
    },
    {
      question:
        "The trainee acts according to the ethical principle of responsibility and is able to respect the person as a whole. This image of man is reflected in the nursing care as well as in the team without conditions.",
      path: "/optionquestionanswer/ethicalcompetence3",
    },
    {
      question:
        "The trainee acts according to the ethical principle of dialogue and is able to respect the person as a whole. This image of man is reflected in the nursing care as well as in the team without conditions.",
      path: "/optionquestionanswer/ethicalcompetence3",
    },
  ],
  selforganizedlearning: [
    {
      question:
        "The trainee researches specialist knowledge and can include the findings of the research in the nursing act.",
      path: "/optionquestionanswer/selforganizedlearning1",
    },
    {
      question:
        "he trainee is able to transfer the acquired specialist knowledge (anatomy and physiology of the movement technique) examplarically to the entire field of knowledge at the respective clinical picture and to justify his actions evidence-based.",
      path: "/optionquestionanswer/selforganizedlearning2",
    },
    {
      question:
        "The trainee can accept constructive criticism and implement it in his future nursing activities.",
      path: "/optionquestionanswer/selforganizedlearning3",
    },
  ],
  orientationmission1: [
    {
      question:
        "I.1 Responsibly plan, organize, design, implement, control and evaluate (evaluate) the care of people of all ages.",
      path: "/optionquestions/orientationmission11",
    },
    {
      question:
        "I.2 Planning, organizing, designing, implementing, controlling and evaluating (evaluating) nursing processes and nursing diagnostics for people of all ages with health problems under the special focus on health promotion and prevention.",
      path: "/optionquestions/orientationmission12",
    },
    {
      question:
        "I.3 Responsibly plan, organize, design, implement, control and evaluate nursing processes and care diagnostics of people of all ages in highly stressed and critical life situations.",
      path: "/optionquestions/orientationmission13",
    },
    {
      question:
        "I.4 Acting purposefully in life-threatening, crisis or disaster situations.",
      path: "/optionquestions/orientationmission14",
    },
    {
      question:
        "I.5 Support, accompany and advise people of all ages in shaping their lives",
      path: "/optionquestions/orientationmission15",
    },
    {
      question: "I.6 Promote development and autonomy throughout the lifespan.",
      path: "/optionquestions/orientationmission16",
    },
  ],
  orientationmission11: [
    {
      question:
        "Determine basic principles for the nursing concept of the provider of practical training and identify them in the care carried out at the place of use.",
      path: "/optionquestionanswer/abilitytoreflect1",
    },
    {
      question:
        "Understand the structure of the care documentation system used in the care facility (digital and/or analogue) in order to obtain targeted information about the need for care, the current situation and the planned care process for the care of the people to be cared for. In doing so, observe the requirements of data protection.",
      path: "/optionquestionanswer/abilitytoreflect1",
    },
    {
      question:
        "Support people to be cared for with a low degree of need for care in their mobility and self-sufficiency according to existing planning. For this purpose, an assessment of the need for care and the observation of changes in the state of health, adapt the planned care interventions, if necessary in coordination with the nurse, situationally. Include resources of the caregiver in the implementation of the planned care and document the care provided.",
      path: "/optionquestionanswer/abilitytoreflect1",
    },
  ],
};
