import axios from "axios";
import { BACKEND_API_URL } from "../helpers/variables";
import useHandleErrors from "./useHandleErrors";

const useAdmin = () => {
  const { handleErrors } = useHandleErrors();

  const addCategory = async data => {
    try {
      const response = await axios.post(
        `${BACKEND_API_URL}/admin/test/competencyCat`,
        data
      );
      return response.data;
    } catch (err) {
      handleErrors(err);
    }
  };

  const getCategories = async () => {
    try {
      const response = await axios.get(
        `${BACKEND_API_URL}/admin/test/competencyCat`
      );
      return response.data;
    } catch (err) {
      handleErrors(err);
    }
  };

  const addOption = async data => {
    try {
      const response = await axios.post(
        `${BACKEND_API_URL}/admin/test/competencyCatOption`,
        data
      );
      return response.data;
    } catch (err) {
      handleErrors(err);
    }
  };

  const getOptions = async _competencyCat => {
    try {
      const response = await axios.get(
        `${BACKEND_API_URL}/admin/test/competencyCatOption/${_competencyCat}`
      );
      return response.data;
    } catch (err) {
      handleErrors(err);
    }
  };

  const getOptionTests = async _competencyCategoryOption => {
    try {
      const response = await axios.get(
        `${BACKEND_API_URL}/admin/test/competencyCatOptionTest/${_competencyCategoryOption}`
      );
      return response.data;
    } catch (err) {
      handleErrors(err);
    }
  };

  const addTest = async data => {
    try {
      const response = await axios.post(
        `${BACKEND_API_URL}/admin/test/competencyCatOptionTest`,
        data
      );
      return response.data;
    } catch (err) {
      handleErrors(err);
    }
  };

  const getSubTests = async _test => {
    try {
      const response = await axios.get(
        `${BACKEND_API_URL}/admin/test/competencyCatOptionSubTest/${_test}`
      );
      return response.data;
    } catch (err) {
      handleErrors(err);
    }
  };

  const getJustSubTests = async () => {
    try {
      const response = await axios.get(
        `${BACKEND_API_URL}/admin/test/competencyCatOptionSubTest`
      );
      return response.data;
    } catch (err) {
      handleErrors(err);
    }
  };

  const addSubTest = async data => {
    try {
      const response = await axios.post(
        `${BACKEND_API_URL}/admin/test/competencyCatOptionSubTest`,
        data
      );
      return response.data;
    } catch (err) {
      handleErrors(err);
    }
  };

  const getSubTestLinks = async (_subTest, _test) => {
    try {
      const response = await axios.get(
        `${BACKEND_API_URL}/admin/test/subTestLinks/${_test}/${_subTest}`
      );

      return response.data;
    } catch (err) {
      handleErrors(err);
    }
  };

  const updateSubTestLinks = async data => {
    try {
      const response = await axios.put(
        `${BACKEND_API_URL}/admin/test/addSubTestLinks`,
        data
      );

      return response.data;
    } catch (err) {
      handleErrors(err);
    }
  };

  const getAssessmentCategries = async _student => {
    try {
      const response = await axios.get(
        `${BACKEND_API_URL}/admin/assessment/competencyAssessmentCategories/${_student}`
      );

      console.log(response);

      return response.data;
    } catch (err) {
      handleErrors(err);
    }
  };

  const getAllAssessments = async _student => {
    try {
      const response = await axios.get(
        `${BACKEND_API_URL}/admin/assessment/allAssessments/${_student}`
      );

      return response.data;
    } catch (err) {
      handleErrors(err);
    }
  };

  const getRequiredAssessments = async (
    _student,
    _competencyCatOptionTest,
    _competencySubTest
  ) => {
    try {
      const response = await axios.get(
        `${BACKEND_API_URL}/admin/assessment/requiredAssessments/${_student}/${_competencyCatOptionTest}/${_competencySubTest}`
      );

      return response.data;
    } catch (err) {
      handleErrors(err);
    }
  };

  const createAssessmentCategory = async data => {
    try {
      const response = await axios.post(
        `${BACKEND_API_URL}/admin/assessment/competencyAssessmentCategory`,
        data
      );

      return response.data;
    } catch (err) {
      handleErrors(err);
    }
  };

  const getAssessmentCategoryTests = async (
    _competencyAssessmentCat,
    _competencyCatOptionTest,
    _competencySubTest
  ) => {
    try {
      const response = await axios.get(
        `${BACKEND_API_URL}/admin/assessment/competencyAssessmentCatTest/${_competencyAssessmentCat}/${_competencyCatOptionTest}/${_competencySubTest}`
      );

      return response.data;
    } catch (err) {
      handleErrors(err);
    }
  };

  const createCategoryTest = async data => {
    try {
      const response = await axios.post(
        `${BACKEND_API_URL}/admin/assessment/competencyAssessmentCatTest`,
        data
      );

      return response.data;
    } catch (err) {
      handleErrors(err);
    }
  };

  const getAssessmentCategoryOptions =
    async _competencyAssessmentCategoryTest => {
      try {
        const response = await axios.get(
          `${BACKEND_API_URL}/admin/assessment/competencyAssessmentCategoryOptions/${_competencyAssessmentCategoryTest}`
        );

        return response.data;
      } catch (err) {
        handleErrors(err);
      }
    };

  const createCategoryOption = async data => {
    try {
      const response = await axios.post(
        `${BACKEND_API_URL}/admin/assessment/competencyAssessmentCategoryOption`,
        data
      );

      return response.data;
    } catch (err) {
      handleErrors(err);
    }
  };

  const getAssessmentTestNSubTest = async (_test, _subTest) => {
    try {
      const response = await axios.get(
        `${BACKEND_API_URL}/admin/assessment/assessmentTestNSubTest/${_test}/${_subTest}`
      );

      return response.data;
    } catch (err) {
      handleErrors(err);
    }
  };

  const createTrainingPortfolioCategory = async data => {
    try {
      const response = await axios.post(
        `${BACKEND_API_URL}/admin/trainingPortfolio/category`,
        data
      );

      return response.data;
    } catch (err) {
      handleErrors(err);
    }
  };

  const getTrainingPortfolioCategories = async () => {
    try {
      const response = await axios.get(
        `${BACKEND_API_URL}/admin/trainingPortfolio/categories`
      );

      return response.data;
    } catch (err) {
      handleErrors(err);
    }
  };

  const createTrainingPortfolioItem = async data => {
    try {
      const response = await axios.post(
        `${BACKEND_API_URL}/admin/trainingPortfolio/item`,
        data
      );

      return response.data;
    } catch (err) {
      handleErrors(err);
    }
  };

  const getTrainingPortfolioItems = async _category => {
    try {
      const response = await axios.get(
        `${BACKEND_API_URL}/admin/trainingPortfolio/items/${_category}`
      );

      return response.data;
    } catch (err) {
      handleErrors(err);
    }
  };

  const updateTrainingPortfolioItem = async (_item, data) => {
    try {
      const response = await axios.patch(
        `${BACKEND_API_URL}/admin/trainingPortfolio/items/${_item}`,
        data
      );

      return response.data;
    } catch (err) {
      handleErrors(err);
    }
  };

  const addLFCE = async data => {
    try {
      const response = await axios.post(
        `${BACKEND_API_URL}/admin/registerAnalysis/lfce`,
        data
      );

      return response.data;
    } catch (err) {
      handleErrors(err);
    }
  };

  const updateLFCE = async data => {
    try {
      const response = await axios.patch(
        `${BACKEND_API_URL}/admin/registerAnalysis/lfce`,
        data
      );

      return response.data;
    } catch (err) {
      handleErrors(err);
    }
  };

  const getLFCEs = async () => {
    try {
      const response = await axios.get(
        `${BACKEND_API_URL}/admin/registerAnalysis/lfce`
      );

      return response.data;
    } catch (err) {
      handleErrors(err);
    }
  };

  const getAllTrainingPortfolioItems = async () => {
    try {
      const response = await axios.get(
        `${BACKEND_API_URL}/admin/registerAnalysis/allTrainingPortfolioItems`
      );

      return response.data;
    } catch (err) {
      handleErrors(err);
    }
  };

  return {
    addCategory,
    getCategories,
    addOption,
    getOptions,
    addTest,
    getOptionTests,
    getSubTests,
    addSubTest,
    getSubTestLinks,
    updateSubTestLinks,
    getAssessmentCategries,
    createAssessmentCategory,
    getAssessmentCategoryTests,
    createCategoryTest,
    getAssessmentCategoryOptions,
    createCategoryOption,
    getJustSubTests,
    getAssessmentTestNSubTest,
    createTrainingPortfolioCategory,
    createTrainingPortfolioItem,
    getTrainingPortfolioCategories,
    getTrainingPortfolioItems,
    getAllAssessments,
    getRequiredAssessments,
    updateTrainingPortfolioItem,
    addLFCE,
    getLFCEs,
    getAllTrainingPortfolioItems,
    updateLFCE,
  };
};

export default useAdmin;
