export const listingOptions = {
  appraisal: [
    {
      title: "Competency Tests",
      path: "/assessment/competency/:_student",
    },
    {
      title: "Orientation Mission",
      path: "/options/orientationmission",
    },
    {
      title: "External Use",
      path: "",
    },
  ],
  talks: [
    {
      title: "Initial Consultation",
      path: `/talksform/initialconsultation`,
    },
    {
      title: "Interim Talk",
      path: "/talksform/intermtalk",
    },
    {
      title: "Final Talk",
      path: "/talksform/finaltalk",
    },
    {
      title: "Office Visits",
      path: "",
    },
  ],
  competency: [
    {
      title: "Arthrosis",
      path: "/listingOptions/arthrosis",
    },
    {
      title: "Choice 2",
      path: "",
    },
    {
      title: "Choice 3",
      path: "",
    },
    {
      title: "Choice 4",
      path: "",
    },
  ],
  arthrosis: [
    {
      title: "Communication Skills",
      path: "/optionquestions/communication",
    },
    {
      title: "Ability to Act",
      path: "/optionquestions/abilitytoact",
    },
    {
      title: "Ability to Reflect",
      path: "/optionquestions/abilitytoreflect",
    },
    {
      title: "Ethical Competence",
      path: "/optionquestions/ethicalcompetence",
    },
    {
      title: "Ability for self-organized learning",
      path: "/optionquestions/selforganizedlearning",
    },
    {
      title: "Competence Profile",
      path: "/competenceprofile",
    },
  ],

  logs: [
    {
      title: "Continuous text competency test",
      path: "",
    },
    {
      title: "Continuous text orientation insert",
      path: "",
    },
    {
      title: "Continuous text practice visit",
      path: "",
    },
  ],

  proofOfPractice: [
    {
      title: "Proof of Education",
      path: "/proofofpractice/proofOfEducation",
    },
    {
      title: "Proof of Education",
      path: "/proofofpractice/proofOfEducation",
    },
    {
      title: "Proof of Education",
      path: "/proofofpractice/proofOfEducation",
    },
  ],
  reflection: [
    {
      title: "Present meaning",
      path: "/",
    },
    {
      title: "Future significance",
      path: "/",
    },
    {
      title: "Exemplary",
      path: "/",
    },
  ],
};
