import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router";
import AdminPage from "../../../components/AdminPage";
import CSmButton from "../../../components/Form/CSmButton";
import useAdmin from "../../../hooks/useAdmin";
import AddModal from "./components/AddModal";
import SubTestLinksModal from "./components/SubTestLinksModal";

const Dashboard = () => {
  const navigate = useNavigate();
  const [categories, setCategories] = useState([]);
  const [options, setOptions] = useState([]);
  const [tests, setTests] = useState([]);
  const [subTests, setSubTests] = useState([]);

  const [selectedCat, setSelectedCat] = useState("");
  const [selectedOption, setSelectedOption] = useState("");
  const [selectedTest, setSelectedTest] = useState("");

  const [loading, setLoading] = useState(true);
  const [linksModalOpen, setLinksModalOpen] = useState(false);
  const [linksLoading, setLinksLoading] = useState(false);

  const [links, setLinks] = useState({
    videoLink: "",
    audioLink: "",
    pdfLink1: "",
    pdfLink2: "",
    _test: "",
    _subTest: "",
  });

  const {
    getCategories,
    getOptions,
    getOptionTests,
    getSubTestLinks,
    getJustSubTests,
  } = useAdmin();

  useEffect(() => {
    getInitialData();
  }, []);

  const getInitialData = async () => {
    const [cats, subtests] = await Promise.all([
      getCategories(),
      getJustSubTests(),
    ]);

    cats && setCategories([...cats.categories]);
    subtests && setSubTests([...subtests.subTests]);
    setLoading(false);
  };

  const getOptionsNow = async _competencyCat => {
    setSelectedCat(_competencyCat);
    setLoading(true);
    setOptions([]);
    const response = await getOptions(_competencyCat);
    if (response) {
      setOptions([...response.options]);
    }
    setLoading(false);
  };

  const getTestsNow = async _competencyCategoryOption => {
    setSelectedOption(_competencyCategoryOption);
    setLoading(true);
    setTests([]);
    const response = await getOptionTests(_competencyCategoryOption);
    if (response) {
      console.log(response);
      setTests([...response.tests]);
    }
    setLoading(false);
  };

  const selectTest = async _test => {
    setSelectedTest(_test);
    setLinks(pS => ({
      ...pS,
      _test,
    }));
  };

  const getSubTestLinksNow = async (_subTest, _test) => {
    if (!_test) return;

    setLinksLoading(true);
    setLinksModalOpen(true);
    const response = await getSubTestLinks(_subTest, _test);
    if (response?.links) setLinks({ ...response.links });
    else {
      setLinks(pS => ({
        ...pS,
        _subTest,
        _test,
      }));
    }
    setLinksLoading(false);
  };

  const navigateToAssessments = (_test, _subTest) => {
    if (!_test || !_subTest) return;
    navigate(`/admin/assessment/${_test}/${_subTest}`);
  };

  return (
    <AdminPage>
      <div className='dashboard'>
        <div className='dashboard__tables'>
          <DashboardTable
            title='Categories'
            items={categories}
            setItems={setCategories}
            onAction={getOptionsNow}
            _selected={selectedCat}
            loading={loading}
          />
          <DashboardTable
            title='Category Options'
            items={options}
            setItems={setOptions}
            onAction={getTestsNow}
            _selected={selectedOption}
            _parent={selectedCat}
            loading={loading}
          />
          <DashboardTable
            title='Tests'
            items={tests}
            setItems={setTests}
            _selected={selectedTest}
            _parent={selectedOption}
            onAction={selectTest}
            loading={loading}
          />
          <DashboardTable
            title='SubTests'
            items={subTests}
            setItems={setSubTests}
            _parent={selectedTest}
            loading={loading}
            onAction={getSubTestLinksNow}
            onSecondAction={navigateToAssessments}
          />
        </div>
      </div>
      <SubTestLinksModal
        open={linksModalOpen}
        setOpen={setLinksModalOpen}
        links={links}
        setLinks={setLinks}
        loading={linksLoading}
      />
    </AdminPage>
  );
};

const DashboardTable = ({
  title,
  items,
  setItems,
  onAction,
  _selected,
  _parent,
  loading,
  onSecondAction,
}) => {
  const [addModalOpen, setAddModalOpen] = useState(false);

  return (
    <div className='dashboard__tables__table'>
      <div className='dashboard__tables__table__add'>
        <CSmButton title='Add' onClick={() => setAddModalOpen(true)} />
      </div>
      {items.length > 0 ? (
        <>
          <h4>{title}</h4>

          <div className='dashboard__tables__table__headings'>
            <div className='dashboard__tables__table__headings__heading'>
              <b>Title</b>
            </div>
            <div className='dashboard__tables__table__headings__heading'></div>
          </div>
          {items.map(item => (
            <div className='dashboard__tables__table__item' key={item?._id}>
              <div className='dashboard__tables__table__item__col'>
                {item?.title}
              </div>

              <div className='dashboard__tables__table__item__col'>
                {title === "SubTests" ? (
                  <div style={{ display: "flex", gap: "0.25rem" }}>
                    <i
                      className='fa-solid fa-list-check'
                      style={
                        _selected === item?._id ||
                        (title === "SubTests" && !_parent)
                          ? { color: "gray" }
                          : { color: "#00C2FF", cursor: "pointer" }
                      }
                      onClick={() => onSecondAction(_parent, item._id)}
                    ></i>
                    <i
                      className='fa-solid fa-link'
                      style={
                        _selected === item?._id ||
                        (title === "SubTests" && !_parent)
                          ? { color: "gray" }
                          : { color: "#00C2FF", cursor: "pointer" }
                      }
                      onClick={() => onAction(item?._id, _parent)}
                    ></i>
                  </div>
                ) : (
                  <button
                    onClick={() => onAction(item?._id, _parent)}
                    style={
                      _selected === item?._id ||
                      (title === "SubTests" && !_parent)
                        ? { background: "gray" }
                        : {}
                    }
                  >
                    {title === "Categories"
                      ? "Options"
                      : title === "Category Options"
                      ? "Test"
                      : title === "Tests"
                      ? "Sub Tests"
                      : "View Links"}
                  </button>
                )}
              </div>
            </div>
          ))}
        </>
      ) : (
        <div className='dashboard__tables__table__message'>
          <h5> {loading ? "Loading..." : `No ${title} to show!`} </h5>
        </div>
      )}
      <AddModal
        open={addModalOpen}
        setOpen={setAddModalOpen}
        type={
          title === "Categories"
            ? "Category"
            : title === "Category Options"
            ? "Category Option"
            : title === "Tests"
            ? "Test"
            : "Sub-Test"
        }
        items={items}
        setItems={setItems}
        _parent={_parent}
      />
    </div>
  );
};
export default Dashboard;
