import React from 'react'
import OptionBox from '../../components/Common/OptionBox'
import Navbar from '../../components/Navbar'

const SkillIV = () => {
  return (
    <>
      <Navbar />
      <section className='skillsIV'>
        <header className='skillsIV__header'>
          <h3>Kompetenzen IV</h3>
        </header>
        <div className='skillsIV__body'>
          <OptionBox title='Inpatient acute care' path='/careForm' />
          <OptionBox title='Inpatient long-term care' path='/careForm' />
          <OptionBox
            title='Ambulatory short and long-term care'
            path='/careForm' />
        </div>
      </section >
    </>
  )
}

export default SkillIV