import React from "react";

const CSelectWithLabel = ({
  options,
  name,
  id,
  onChange,
  style,
  defaultValue,
  isNested,
  value,
  label,
}) => {
  return (
    <div className='cSelectWithLabel' style={{ ...style }}>
      {label && <label htmlFor={id}>{label}</label>}
      <select
        name={name}
        id={id}
        onChange={onChange}
        defaultValue={defaultValue}
        value={value}
      >
        {options?.map(op => {
          return isNested ? (
            <option value={op.value} key={op.value}>
              {op.title}
            </option>
          ) : (
            <option value={op} key={op}>
              {op}
            </option>
          );
        })}
      </select>
    </div>
  );
};

export default CSelectWithLabel;
