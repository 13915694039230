import axios from "axios";
import { BACKEND_API_URL } from "../helpers/variables";
import useHandleErrors from "./useHandleErrors";

const useGrade = () => {
  const { handleErrors } = useHandleErrors();

  const getAttendance = async (date, _grade) => {
    try {
      const response = await axios.get(
        `${BACKEND_API_URL}/grade/attendance/byDate/${date}/${_grade}`
      );
      return response.data;
    } catch (err) {
      handleErrors(err);
    }
  };

  const submitAttendance = async data => {
    try {
      const response = await axios.put(
        `${BACKEND_API_URL}/grade/attendance`,
        data
      );
      return response.data;
    } catch (err) {
      handleErrors(err);
    }
  };

  return { getAttendance, submitAttendance };
};

export default useGrade;
