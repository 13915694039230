import React from "react";

const CustomSelection = ({
  options,
  name,
  id,
  onChange,
  style,
  defaultValue,
  isNested,
  value,
}) => {
  return (
    <select
      name={name}
      id={id}
      onChange={onChange}
      defaultValue={defaultValue}
      value={value}
      className='signInContainer__fields__input'
      style={{
        width: "100%",
        ...style,
      }}
    >
      <option value=''>-Wählen-</option>
      {options?.map(op => {
        return isNested ? (
          <option value={op.value} key={op.value}>
            {op.title}
          </option>
        ) : (
          <option value={op} key={op}>
            {op}
          </option>
        );
      })}
    </select>
  );
};

export default CustomSelection;
