import React from 'react'

const RegisterAnalysisTable = () => {
  return (
    <section className='registerAnalysis__body__registerAnalysisTable'>
      <table className='registerAnalysis__body__registerAnalysisTable__table'>
        <thead className='registerAnalysis__body__registerAnalysisTable__table__tableHead'>
          <tr className='registerAnalysis__body__registerAnalysisTable__table__tableHead__row'>
            <th className='registerAnalysis__body__registerAnalysisTable__table__tableHead__row__head'>Date</th>
            <th className='registerAnalysis__body__registerAnalysisTable__table__tableHead__row__head'>Contents</th>
            <th className='registerAnalysis__body__registerAnalysisTable__table__tableHead__row__head'>Skill IV</th>
            <th className='registerAnalysis__body__registerAnalysisTable__table__tableHead__row__head'>Status</th>
          </tr>
        </thead>
        <tbody className='registerAnalysis__body__registerAnalysisTable__table__tableBody'>
          <tr className='registerAnalysis__body__registerAnalysisTable__table__tableBody__row'>
            <td className='registerAnalysis__body__registerAnalysisTable__table__tableBody__row__details'>
              XX.XX.XXXX
            </td>
            <td className='registerAnalysis__body__registerAnalysisTable__table__tableBody__row__details'>
              Companion should write here (topics)
            </td>
            <td className='registerAnalysis__body__registerAnalysisTable__table__tableBody__row__details'>
              Automatically filled based on learning field
            </td>
            <td className='registerAnalysis__body__registerAnalysisTable__table__tableBody__row__details'>

            </td>
          </tr>
          <tr className='registerAnalysis__body__registerAnalysisTable__table__tableBody__row'>
            <td className='registerAnalysis__body__registerAnalysisTable__table__tableBody__row__details'>

            </td>
            <td className='registerAnalysis__body__registerAnalysisTable__table__tableBody__row__details'>

            </td>
            <td className='registerAnalysis__body__registerAnalysisTable__table__tableBody__row__details'>

            </td>
            <td className='registerAnalysis__body__registerAnalysisTable__table__tableBody__row__details'>

            </td>
          </tr>
          <tr className='registerAnalysis__body__registerAnalysisTable__table__tableBody__row'>
            <td className='registerAnalysis__body__registerAnalysisTable__table__tableBody__row__details'>

            </td>
            <td className='registerAnalysis__body__registerAnalysisTable__table__tableBody__row__details'>

            </td>
            <td className='registerAnalysis__body__registerAnalysisTable__table__tableBody__row__details'>

            </td>
            <td className='registerAnalysis__body__registerAnalysisTable__table__tableBody__row__details'>

            </td>
          </tr>
          <tr className='registerAnalysis__body__registerAnalysisTable__table__tableBody__row'>
            <td className='registerAnalysis__body__registerAnalysisTable__table__tableBody__row__details'>

            </td>
            <td className='registerAnalysis__body__registerAnalysisTable__table__tableBody__row__details'>

            </td>
            <td className='registerAnalysis__body__registerAnalysisTable__table__tableBody__row__details'>

            </td>
            <td className='registerAnalysis__body__registerAnalysisTable__table__tableBody__row__details'>

            </td>
          </tr>
        </tbody>
      </table>
    </section>
  )
}

export default RegisterAnalysisTable