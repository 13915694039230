import React from "react";

const CustomInput = ({
  type,
  placeholder,
  name,
  onChange,
  value,
  style,
  required,
}) => {
  return (
    <input
      type={type}
      placeholder={placeholder}
      name={name}
      className='signInContainer__fields__input'
      value={value}
      onChange={onChange}
      style={{ width: "100%", ...style }}
      required={required}
    />
  );
};

export default CustomInput;
