import React from 'react'
import PdfLink from '../../components/Common/PdfLink';

const AccompanyingMaterial = () => {
  return (
    <>
      <section className='accompanyingMaterial'>
        <div className='accompanyingMaterial__body'>
          <header className='accompanyingMaterial__body__header'>
            <img src={require("../../assets/Logo.png")} alt="" />
            <h2>Begleitmaterialien</h2>
          </header>
          <div className='accompanyingMaterial__body__container'>
            <PdfLink name={"PDF-1"} path={"www.google.com"} />
            <PdfLink name={"PDF-2"} path={"www.google.com"} />
            <PdfLink name={"PDF-3"} path={"www.google.com"} />
          </div>
        </div>
      </section>
    </>
  )
}

export default AccompanyingMaterial;