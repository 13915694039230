import { Chip } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import CSmButton from "../../../components/Form/CSmButton";
import CustomSelection from "../../../components/Form/CustomSelection";
import { USER_STORAGE_KEY } from "../../../helpers/variables";
import useGrade from "../../../hooks/useGrade";

const ClassAttendance = ({ date }) => {
  const location = useLocation();
  const splitted = location.pathname.split("/");
  const _grade = splitted[splitted.length - 1];
  const { getAttendance, submitAttendance } = useGrade();

  const user = JSON.parse(localStorage.getItem(USER_STORAGE_KEY));

  const [students, setStudents] = useState([]);
  const [attendanceFetched, setAttendanceFetched] = useState(false);
  const [attendanceMarked, setAttendanceMarked] = useState(false);
  let [attendance, setAttendance] = useState({
    _grade,
    date,
    _teacher: user?.user?._id,
    attendance: {},
  });

  useEffect(() => {
    setAttendanceFetched(false);
    setAttendanceMarked(false);
    getAttendance(date, _grade).then(res => {
      setStudents([...res.students]);
      if (res.attendance) {
        setAttendance(res.attendance);
        setAttendanceMarked(true);
      } else {
        let tempAttendance = {};
        res?.students?.forEach(st => {
          tempAttendance[st._id] = {
            8: true,
            10: true,
            12: true,
            "13:45": true,
            reason: "",
          };
        });

        attendance = {
          _grade,
          date,
          _teacher: user?.user?._id,
          attendance: tempAttendance,
        };
        setAttendance({ ...attendance });
      }
      setAttendanceFetched(true);
    });
  }, [date]);

  const changeHandler = (event, _student, prop) => {
    const { checked, value, name } = event.target;

    let weekAgo = new Date(new Date().setDate(new Date().getDate() - 7));
    if (new Date(date) < weekAgo) return alert("Attendance cannot be updated!");

    attendance.attendance[_student][prop] = name === "reason" ? value : checked;
    setAttendance({ ...attendance });
  };

  const submitAttendanceNow = () => {
    if (user.userType === "supervisor") {
      if (!attendanceFetched) return;
      submitAttendance(attendance).then(res => {
        setAttendance({ ...res.attendance });
        alert("Anwesenheitsliste aktualisiert!");
      });
    } else {
      alert("Die Anwesenheitsliste kann nur von Lehrern aktualisiert werden!");
    }
  };

  const getStudentAttendance = (prop, _student) => {
    if (!attendance.attendance[_student]) return "";
    return attendance.attendance[_student][prop];
  };

  return (
    <div>
      <h3 style={{ textAlign: "center", marginTop: "2rem" }}>
        Anwesenheitsliste{" "}
        {attendanceMarked ? (
          <Chip label='Bearbeitet' color='success' />
        ) : (
          <Chip label='Nicht Bearbeitet' color='error' />
        )}{" "}
      </h3>
      <table
        className='classAnalysis__table attendanceTable'
        style={{ marginTop: "1rem" }}
      >
        <tr>
          <th style={{ width: "20%" }}>Auszubildender</th>
          <th style={{ width: "15%" }}>08:00 - 09:30 </th>
          <th style={{ width: "15%" }}>10:00 - 11:30 </th>
          <th style={{ width: "15%" }}>12:00 - 13:30 </th>
          <th style={{ width: "15%" }}>13:45 - 15:15 </th>
          <th style={{ width: "20%" }}>Zusatz </th>
        </tr>
        {students.map(student => (
          <tr key={student._id}>
            <td>{student.name}, {student.firstName}</td>
            <td>
              <input
                type='checkbox'
                onChange={e => changeHandler(e, student._id, "8")}
                checked={getStudentAttendance("8", student._id)}
              />
            </td>
            <td>
              <input
                type='checkbox'
                onChange={e => changeHandler(e, student._id, "10")}
                checked={getStudentAttendance("10", student._id)}
              />
            </td>
            <td>
              <input
                type='checkbox'
                onChange={e => changeHandler(e, student._id, "12")}
                checked={getStudentAttendance("12", student._id)}
              />
            </td>
            <td>
              <input
                type='checkbox'
                onChange={e => changeHandler(e, student._id, "13:45")}
                checked={getStudentAttendance("13:45", student._id)}
              />
            </td>
            <td>
              <CustomSelection
                options={["15 min zu spät erschienen", "30 min zu spät erschienen", "45 min zu spät erschienen", "60 min zu spät erschienen", "Entschuldigt", "15 min früher gegangen", "30 min früher gegangen", "45 min früher gegangen", "60 min früher gegangen"]}
                name='reason'
                onChange={e => changeHandler(e, student._id, "reason")}
                defaultValue={getStudentAttendance("reason", student._id)}
              />
            </td>
          </tr>
        ))}
      </table>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "flex-end",
          marginTop: "1rem",
        }}
      >
        <CSmButton title='Anwesenheit Bestätigen' onClick={submitAttendanceNow} />
      </div>
    </div>
  );
};

export default ClassAttendance;
