import { Button } from "@mui/material";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { Navigate, useLocation } from "react-router";
import { Link } from "react-router-dom";
import { KolibriStore } from "../../App";
import Navbar from "../../components/Navbar";
import { USER_STORAGE_KEY } from "../../helpers/variables";
import useStudent from "../../hooks/useStudent";
import useUser from "../../hooks/useUser";
import DatesModal from "./components/DatesModal";

const TalksForm = () => {
  const location = useLocation();
  const { getKilianTask, createTalk, getTalk, addStudentConfirmationToTalk } =
    useStudent();
  const { getUserById } = useUser();
  let splittedLoc = location.pathname.split("/");
  let _student = splittedLoc[splittedLoc.length - 1];
  let option = splittedLoc[splittedLoc.length - 2];

  const user = JSON.parse(localStorage.getItem(USER_STORAGE_KEY));

  const [datesModalOpen, setDatesModalOpen] = useState(false);
  const [student, setStudent] = useState();
  const [talk, setTalk] = useState({
    talkType: option,
    interviewDate: new Date(),
    intermWeek: 1,
    intermYear: 2024,
    finalWeek: 1,
    finalYear: 2024,
    _student,
    teacher:
      user?.userType === "teacher"
        ? user.user
        : user?.user?._grade?._teacher || "-",
    teacherType: "HPA",
    otherPerson: "",
    otherType: "SL",
    mainteraction_date: [""],
    fixedpoint_date: [""],
    competency_test_date: "",
    instructorConfirmation: false,
  });

  useEffect(() => {
    getUserById(_student).then(res => {
      setStudent(res.user);
    });

    getTalk(_student, option).then(res => {
      setTalk({
        ...res.talk,
        finalWeek: res.talk.finalMeeting.week,
        finalYear: res.talk.finalMeeting.year,
        intermWeek: res.talk.intermMeeting.week,
        intermYear: res.talk.intermMeeting.year,
        fixedpoint_date: res.talk.fixedpoint_date.map(date =>
          moment(new Date(date)).format("YYYY-MM-DD")
        ),
        mainteraction_date: res.talk.mainteraction_date.map(date =>
          moment(new Date(date)).format("YYYY-MM-DD")
        ),
        competency_test_date: moment(
          new Date(res.talk.competency_test_date)
        ).format("YYYY-MM-DD"),
        teacher:
          user?.userType === "teacher"
            ? user.user
            : user?.user?._grade?._teacher || "-",
      });
    });
  }, []);

  // const getKilianTaskNow = async () => {
  //   const response = await getKilianTask(option, _student);
  //   return response.kilian_task;
  // };

  const changeHandler = e => {
    const { name, value, checked } = e.target;

    console.log(name, value);

    setTalk(pS => {
      return {
        ...pS,
        [name]:
          name === "instructorConfirmation" || name === "studentConfirmation"
            ? checked
            : value,
      };
    });
  };

  const submitHandler = async () => {
    if (!talk.instructorConfirmation)
      return alert(
        "Bitte bestätigen Sie die Teilnahme an dem Gespräch und die Kenntnisnahme der Vereinbarungen!"
      );

    console.log(talk, "<----");
    const validated = validateTalk();
    if (!validated) return alert("Bitte alle benötigten Felder richtig ausfüllen!");

    let data = {
      ...talk,
      _teacher: talk?.teacher?._id,
      finalMeeting: {
        week: talk.finalWeek,
        year: talk.finalYear,
      },
      intermMeeting: {
        week: talk.intermWeek,
        year: talk.intermYear,
      },
    };

    const response = await createTalk(data);
    console.log(response);
    if (response) {
      alert("Gespräch erfolgreich eingereicht!");
    }

    // console.log(kilian_task);
    // console.log(talk);
  };

  const validateTalk = () => {
    let validated = true;
    for (let prop in talk) {
      console.log(prop, talk[prop], "<----");
      console.log(prop === "mainteraction_date" || prop === "fixedpoint_date");
      if (
        (prop === "mainteraction_date" || prop === "fixedpoint_date") &&
        (talk[prop].length < 1 || !talk[prop][0])
      )
        return false;
      else if (prop != "__v" && !talk[prop]) {
        console.log(prop);
        return false;
      }
    }

    return validated;
  };

  const addStudentConfirmation = async confirm => {
    if (!confirm) return;

    if (!talk._id) return;
    console.log(confirm);
    setTalk(pS => {
      return {
        ...pS,
        studentConfirmation: confirm,
      };
    });
    const response = await addStudentConfirmationToTalk(talk._id, true);

    console.log(response);

    if (response) alert("Vielen Dank für ihre bestätigung!");
  };

  return (
    <>
      <Navbar />
      <div className='talksform'>
        <h5>
          {option === "initialconsultation"
            ? "Erstgespräch"
            : option === "intermtalk"
            ? "Zwischengespräch"
            : "Abschlussgespräch"}
        </h5>

        <div className='talksform__table'>
          <div className='talksform__table__intro'>
            <div className='talksform__table__intro__left'>
            {option === "initialconsultation"
            ? <span>
            Das Erstgespräch fand statt am:{" "}
            <span>{moment().format("DD/MM/YYYY")}</span>
            </span>
            : option === "intermtalk"
            ? <span>
            Das Erstgespräch fand statt am:{" "}
            <span>{moment().format("DD/MM/YYYY")}</span>
            </span>
            :<span>
            Das Erstgespräch fand statt am:{" "}
            <span>{moment().format("DD/MM/YYYY")}</span>
            </span>}
            {option === "initialconsultation"
            ?  <span>
            Das Zwischengespräch ist geplant zur KW:
            <div>
              <input
                type='number'
                placeholder='Week(1-52)'
                value={talk?.finalWeek}
                name='finalWeek'
                onChange={changeHandler}
              />
              <input
                type='number'
                placeholder='Year'
                value={talk?.finalYear}
                name='finalYear'
                onChange={changeHandler}
              />
            </div>
          </span>
            : option === "intermtalk"
            ?<span>
            Das Zwischengespräch fand statt am:{" "}
            <span>{moment().format("DD/MM/YYYY")}</span>
            </span>
            :<span>
            Das Zwischengespräch fand statt am:{" "}
            <span>{moment().format("DD/MM/YYYY")}</span>
            </span>}
            {option === "initialconsultation"
            ?  <span>
            Das Abschlussgespräch ist geplant zur KW:
            <div>
              <input
                type='number'
                placeholder='Week(1-52)'
                value={talk?.finalWeek}
                name='finalWeek'
                onChange={changeHandler}
              />
              <input
                type='number'
                placeholder='Year'
                value={talk?.finalYear}
                name='finalYear'
                onChange={changeHandler}
              />
            </div>
          </span>
            : option === "intermtalk"
            ?<span>
            Das Abschlussgespräch ist geplant zur KW:
            <div>
              <input
                type='number'
                placeholder='Week(1-52)'
                value={talk?.finalWeek}
                name='finalWeek'
                onChange={changeHandler}
              />
              <input
                type='number'
                placeholder='Year'
                value={talk?.finalYear}
                name='finalYear'
                onChange={changeHandler}
              />
            </div>
          </span>
            :<span>
            Das Abschlussgespräch fand statt am:{" "}
            <span>{moment().format("DD/MM/YYYY")}</span>
            </span>}


            </div>
            <div className='talksform__table__intro__right'>
              <span>
                Der Kompetenztest ist geplant zum:{" "}
                <span>
                  {talk.competency_test_date
                    ? moment(new Date(talk.competency_test_date)).format(
                        "DD-MM-YYYY"
                      )
                    : "-"}
                </span>
              </span>
              <span>
                Der praktische besuch ist geplant zum: <span> 05-03-2023</span>
              </span>
            </div>
          </div>

          <div className='talksform__table__heading'>
            <b>Teilnehmer</b>
          </div>

          <div className='talksform__table__second'>
            <div className='talksform__table__second__row'>
              <span className='talksform__table__second__row__text'>
                <span>
                  <input type='checkbox' name='' id='' checked />
                  Auszubildende/r (Name):
                </span>
                <span style={{ margin: "0.5rem 0", fontWeight: "bold" }}>
                {student?.firstName} {student?.name}
                </span>
              </span>
            </div>
            <div className='talksform__table__second__row'>
              <span className='talksform__table__second__row__text'>
                <span>
                  <input type='checkbox' name='' id='' checked />
                  Praxisanleitung (name):
                </span>
                <span style={{ margin: "0.5rem 0", fontWeight: "bold" }}>
                {talk?.teacher?.firstName} {talk?.teacher?.name}
                </span>
              </span>

              <select
                name='teacherType'
                id='teacherType'
                value={talk.teacherType}
                onChange={changeHandler}
              >
                <option value='HPA'>HPA</option>
                <option value='PA'>PA</option>
              </select>
            </div>
            <div className='talksform__table__second__row'>
              <span className='talksform__table__second__row__text'>
                <span>
                  <input
                    type='checkbox'
                    name=''
                    id=''
                    value={talk.otherPerson}
                    checked={!!talk.otherPerson}
                  />
                  Weitere Person (Name):
                </span>
                <input
                  type='text'
                  name='otherPerson'
                  value={talk.otherPerson}
                  onChange={changeHandler}
                />
              </span>

              <select
                name='otherType'
                id='otherType'
                value={talk.otherType}
                onChange={changeHandler}
              >
                <option value='SL'>SL = Stationsleitung</option>
                <option value='PDL'>PDL = Pflegedienstleistung </option>
                <option value='WBL'>WBL = Wohnbereichsleistung </option>
                <option value='PB'>PB = Praxisbegleitung </option>
                <option value='PK'>PK = Pflegefachkraft </option>
              </select>
            </div>
          </div>

          <div className='talksform__table__heading'>
            <b>Dokumentation des Erstgespräches</b>
          </div>
          <div className='talksform__table__double'>
            <div className='talksform__table__double__left'>
              <a href='/registeranalysis/analysis' target='_blank'>
                Klassenbuchanalyse
              </a>
            </div>
            <div className='talksform__table__double__right'>
              <div className='talksform__table__double__right__top'>
                Erwartungen und Wünsche
              </div>
            </div>
          </div>
          <div className='talksform__table__heading'>
            <b>
             Kompetenzziele zu den praktischen Rahmenplänen nach § 53 PflBG
            </b>
          </div>
          <div className='talksform__table__double'>
            <div className='talksform__table__double__left'>
              <a href='/accompanyingmaterial' target='_blank'>
                Begleitmaterialien
              </a>
            </div>
            <div className='talksform__table__double__right'>
              <div className='talksform__table__double__right__top'>
                <a href='/skillsiv' target='_blank'>
                  Kompetenzen I-IV
                </a>
              </div>
            </div>
          </div>
          <div className='talksform__table__heading'>
            <b>Arbeits- und Lernaufgaben KILIAN-Prinzip</b>
          </div>
          <div className='talksform__table__double'>
            <div className='talksform__table__double__left'>
              <a href={`/nursingphenomena/${_student}`} target='_blank'>
                Arbeits- und Lernaufgaben / Kompetenzprüfungen
              </a>
            </div>
            <div className='talksform__table__double__right'>
              <div className='talksform__table__double__right__top'>
                <Link to={`/competenceprofile/${_student}`} target='_blank'>
                  KILIAN Kompetenz Modell
                </Link>
              </div>
            </div>
            {option === "intermtalk" || option === "finaltalk" ? (
              <div className='talksform__table__double__right'>
                <div className='talksform__table__double__right__top'>
                  <a href='/logsreviews' target='_blank'>
                    Protokolle und Beurteilungen
                  </a>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
          {option === "finaltalk" ? (
            <>
              <div className='talksform__table__heading'>
                <b>Beratung und Begleitung der KILIAN-Aufgaben</b>
              </div>
              <div className='talksform__table__double'>
                <a
                  href={`/listingOptions/kilianUploads/${_student}`}
                  target='_blank'
                  className='talksform__table__double__left'
                >
                  KILIAN Uploads
                </a>
                <div className='talksform__table__double__right'>
                  <div className='talksform__table__double__right__top'>
                    <a
                      href={`/listingOptions/trainingportfolio/${_student}`}
                      target='_blank'
                    >
                      Ausbildungsportfolio
                    </a>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className='talksform__table__heading'>
                <b>Beratung und Begleitung der KILIAN-Aufgaben</b>
              </div>
              <div className='talksform__table__double'>
                <div className='talksform__table__double__left'>
                  Begleittermine
                  <div className='talksform__table__double__left__row'>
                    <div
                      className='talksform__table__double__left__row__item'
                      onClick={() => setDatesModalOpen(true)}
                    >
                      Metainteraktion- und Fixpunkttermine festlegen
                    </div>

                    <div className='talksform__table__double__left__row__item'>
                      <div className='form__input'>
                        <label htmlFor='competency_test_date'>
                          Kompetenzprüfung festlegen
                        </label>
                        <input
                          type='date'
                          value={talk.competency_test_date}
                          name='competency_test_date'
                          id='competency_test_date'
                          onChange={changeHandler}
                          style={{ width: "100%" }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className='talksform__table__double__right'>
                  <div className='talksform__table__double__right__top'>
                    {option === "initialconsultation" ? (
                      <Link
                        to={`/listingOptions/trainingportfolio/${_student}`}
                      >
                        Ausbildungsportfolio
                      </Link>
                    ) : (
                      <a
                        href={`/listingOptions/trainingportfolio/${_student}`}
                        target='_blank'
                      >
                        Ausbildungsportfolio
                      </a>
                    )}
                  </div>
                </div>
              </div>
            </>
          )}
        </div>

        <div className='talksform__row'>
        Durch die nachfolgende Unterschrift werden die Teilnahme am Gespräch und die Kenntnisnahme der Vereinbarungen bestätigt.
        </div>

        {console.log(talk, "<---")}

        <div className='talksform__row centered'>
          <span style={{ display: "flex", flexFlow: "column" }}>
            {(user?.user?.userType === "teacher" &&
              talk?.instructorConfirmation) ||
            (user?.user?.userType === "student" &&
              talk?.studentConfirmation) ? (
              <img
                src={
                  user?.userType === "teacher"
                    ? user.user
                    : user?.user?._grade?._teacher?.signature
                }
                alt=''
                style={styles.signatureImage}
              />
            ) : null}

            {user?.user?.userType === "teacher" ? (
              <span>
                <input
                  type='checkbox'
                  name='instructorConfirmation'
                  id='instructorConfirmation'
                  checked={!!talk?.instructorConfirmation}
                  onChange={changeHandler}
                />
                <label htmlFor='instructorConfirmation'>
                  PAs
                </label>
              </span>
            ) : (
              <span>
                <input
                  type='checkbox'
                  name='studentConfirmation'
                  id='studentConfirmation'
                  checked={!!talk?.studentConfirmation}
                  onChange={e => addStudentConfirmation(e.target.checked)}
                />
                <label htmlFor='studentConfirmation'>Auszubildende/r</label>
              </span>
            )}
          </span>
        </div>
        {user?.user?.userType === "teacher" && (
          <div className='centered' style={{ marginTop: "1rem" }}>
            <button onClick={submitHandler} className='smButton'>
              Einreichen
            </button>
          </div>
        )}
      </div>
      <DatesModal
        open={datesModalOpen}
        setOpen={setDatesModalOpen}
        talk={talk}
        setTalk={setTalk}
        changeHandler={changeHandler}
      />
    </>
  );
};

const styles = {
  signatureImage: {
    width: "7.5rem",
    height: "5rem",
    borderRadius: "0.25rem",
    marginBottom: "1rem",
  },
};

export default TalksForm;
