import axios from "axios";
import { BACKEND_API_URL } from "../helpers/variables";
import useHandleErrors from "./useHandleErrors";

const useAssessment = () => {
  const { handleErrors } = useHandleErrors();

  const getAssessmentTests = async (
    _assessmentCat,
    _test,
    _subTest,
    _student
  ) => {
    try {
      const response = await axios.get(
        `${BACKEND_API_URL}/teacher/assessment/assessmentTests/${_assessmentCat}/${_test}/${_subTest}/${_student}`
      );

      return response.data;
    } catch (err) {
      handleErrors(err);
    }
  };

  const createAssessment = async data => {
    try {
      console.log("Creating Assessment...");
      const response = await axios.post(
        `${BACKEND_API_URL}/teacher/assessment/assessment`,
        data
      );
      return response.data;
    } catch (err) {
      handleErrors(err);
    }
  };

  const getAssessment = async (_competencyAssessmentCategoryTest, _student) => {
    try {
      const response = await axios.get(
        `${BACKEND_API_URL}/teacher/assessment/assessment/${_competencyAssessmentCategoryTest}/${_student}`
      );

      return response.data;
    } catch (err) {
      handleErrors(err);
    }
  };

  const getGradesByCategory = async _student => {
    try {
      const response = await axios.get(
        `${BACKEND_API_URL}/grade/assessmentGradesByCategories/${_student}`
      );

      return response.data;
    } catch (err) {
      handleErrors(err);
    }
  };

  return {
    getAssessmentTests,
    createAssessment,
    getAssessment,
    getGradesByCategory,
  };
};

export default useAssessment;
