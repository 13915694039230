import React, { useState, useEffect } from "react";
import AdminPage from "../../../components/AdminPage";
import CSmButton from "../../../components/Form/CSmButton";
import useAdmin from "../../../hooks/useAdmin";
import AddLFCEModal from "./components/AddLFCEModal";
import UpdateLFCEModal from "./components/UpdateLFCEModal";

const LFCE = () => {
  const { getLFCEs, getAllTrainingPortfolioItems } = useAdmin();

  const [loading, setLoading] = useState(true);
  const [lfces, setLFCEs] = useState([]);
  const [competencies, setCompetencies] = useState([]);
  const [competenciesLoading, setCompetenciesLoading] = useState(true);
  const [lfce, setLFCE] = useState();

  const [modalOpen, setModalOpen] = useState(false);
  const [updateModalOpen, setUpdateModalOpen] = useState(false);

  useEffect(() => {
    getInitialData();
  }, []);

  const getInitialData = async () => {
    const [lfceRes, competenciesResponse] = await Promise.all([
      getLFCEs(),
      getAllTrainingPortfolioItems(),
    ]);

    if (lfceRes) {
      console.log(lfceRes.lfces);
      setLFCEs([...lfceRes.lfces]);
    }

    if (competenciesResponse) {
      console.log(competenciesResponse.trainingPortfolioItems);

      const competenciesTemp = competenciesResponse.trainingPortfolioItems.map(
        item => ({
          value: item._id,
          label: item.title,
        })
      );

      setCompetencies([...competenciesTemp]);
      setCompetenciesLoading(false);
    }
    setLoading(false);
  };

  const selectLFCE = _item => {
    const selectedLFCE = lfces.find(item => item._id === _item);
    setLFCE(selectedLFCE);
    setUpdateModalOpen(true);
  };

  return (
    <AdminPage>
      <div className='dashboard'>
        <div className='dashboard__tables'>
          <DashboardTable
            title='LFCEs'
            items={lfces}
            setItems={setLFCEs}
            onAction={selectLFCE}
            // _selected={selectedCat}
            loading={loading}
            modalOpen={modalOpen}
            setModalOpen={setModalOpen}
          />
        </div>
      </div>
      <AddLFCEModal
        open={modalOpen}
        setOpen={setModalOpen}
        competencies={competencies}
        setLFCEs={setLFCEs}
        loading={competenciesLoading}
      />
      <UpdateLFCEModal
        open={updateModalOpen}
        setOpen={setUpdateModalOpen}
        competencies={competencies}
        setLFCEs={setLFCEs}
        lfce={lfce}
        setLFCE={setLFCE}
        loading={competenciesLoading}
      />
    </AdminPage>
  );
};

const DashboardTable = ({
  title,
  items,
  setItems,
  onAction,
  _selected,
  _parent,
  loading,
  modalOpen,
  setModalOpen,
  onSecondAction,
}) => {
  return (
    <div className='dashboard__tables__table'>
      <div className='dashboard__tables__table__add'>
        <CSmButton title='Add' onClick={() => setModalOpen(true)} />
      </div>
      {items.length > 0 ? (
        <>
          <h4>{title}</h4>

          <div className='dashboard__tables__table__headings'>
            <div className='dashboard__tables__table__headings__heading'>
              <b>Title</b>
            </div>
            <div className='dashboard__tables__table__headings__heading'></div>
          </div>
          {items.map(item => (
            <div className='dashboard__tables__table__item' key={item?._id}>
              <div className='dashboard__tables__table__item__col'>
                {item?.title}
              </div>

              <div className='dashboard__tables__table__item__col'>
                <button
                  onClick={() => onAction(item?._id, _parent)}
                  style={
                    _selected === item?._id ||
                    (title === "SubTests" && !_parent)
                      ? { background: "gray" }
                      : {}
                  }
                >
                  Update
                </button>
              </div>
            </div>
          ))}
        </>
      ) : (
        <div className='dashboard__tables__table__message'>
          <h5> {loading ? "Loading..." : `No ${title} to show!`} </h5>
        </div>
      )}
    </div>
  );
};

export default LFCE;
