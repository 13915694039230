import { Box, Modal } from "@mui/material";
import React, { useState } from "react";
import Select from "react-select";
import CSmButton from "../../../../components/Form/CSmButton";
import CustomInput from "../../../../components/Form/CustomInput";
import useAdmin from "../../../../hooks/useAdmin";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "none",
  borderRadius: "0.5rem",
  boxShadow: 24,
  outline: "none",
  p: 4,
};

const AddLFCEModal = ({ open, setOpen, competencies, loading, setLFCEs }) => {
  const { addLFCE } = useAdmin();
  const [lfce, setLFCE] = useState({
    title: "",
    _learningObjective: [],
    competencyIV: "",
  });

  const handleClose = () => {
    setOpen(false);
  };

  const submitHandler = async e => {
    e.preventDefault();

    const data = {
      ...lfce,
      _competencyIV: lfce._competencyIV || competencies[0].value,
    };
    console.log(data);

    const response = await addLFCE(data);
    if (response) {
      setLFCEs(pS => [...pS, response.lfce]);
      setOpen(false);
    }
  };

  const changeHandler = e => {
    console.log(e);
    const { name, value } = e.target;
    setLFCE(pS => ({
      ...pS,
      [name]: value,
    }));
  };

  const selectionChange = learningObjectives => {
    let objectives = learningObjectives.map(item => item.value);

    setLFCE(pS => ({
      ...pS,
      _learningObjectives: objectives,
    }));
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
      sx={{ background: "rgba(255, 255, 255, 0.15)" }}
    >
      <Box sx={style}>
        <div className='dashboardAddModal'>
          {loading ? (
            <h1>Loading...</h1>
          ) : (
            <>
              <h3>Add LFCE</h3>
              <form onSubmit={submitHandler}>
                <CustomInput
                  style={{ background: "#ECEFF7", padding: "0.5rem" }}
                  placeholder='Title'
                  name='title'
                  type='text'
                  onChange={changeHandler}
                  required={true}
                />
                <CustomInput
                  style={{ background: "#ECEFF7", padding: "0.5rem" }}
                  placeholder='Competency IV'
                  name='competencyIV'
                  type='text'
                  onChange={changeHandler}
                  required={true}
                />
                <Select
                  className='basic-single'
                  classNamePrefix='select'
                  defaultValue={[competencies[0]]}
                  isDisabled={false}
                  isLoading={false}
                  isMulti
                  isClearable={true}
                  isRtl={false}
                  isSearchable={true}
                  name='_competencyIV'
                  required={true}
                  options={competencies}
                  onChange={selectionChange}
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      textAlign: "start",
                      border: 0,
                      outline: "1px solid white",
                      // height: "1rem",
                      boxShadow: "none",
                      background: "#ECEFF7",
                      fontSize: "0.9rem",
                      marginBottom: "1rem",
                    }),
                  }}
                />
                <CSmButton title='Add' type='submit' />
              </form>
            </>
          )}
        </div>
      </Box>
    </Modal>
  );
};

export default AddLFCEModal;
