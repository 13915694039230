import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import Navbar from "../../components/Navbar";
import useAssessment from "../../hooks/useAssessment";
import useTalks from "../../hooks/useTalks";

const CompetencyProfile = () => {
  const location = useLocation();
  const splitted = location.pathname.split("/");
  const _student = splitted[splitted.length - 1];

  const { getGradesByCategory } = useAssessment();

  const [grades, setGrades] = useState([]);

  useEffect(() => {
    getGradesByCategory(_student).then(res => {
      console.log(res.scoresByComptencyCategoryOptionSubTest);
      setGrades([...res.scoresByComptencyCategoryOptionSubTest]);
    });
  }, []);

  return (
    <>
      <Navbar />
      <div className='competencyProfile'>
        <h4>
        Das KILIAN-Kompetenzmodell im ressourcenorientierten Lernprozess
        </h4>

        <div className='competencyProfile__results competencyProfile__results-1'>
          <div className='competencyProfile__results__left'>
            <div className='arrow'>
              <span>Disposition</span>
              <div className='arrow-2way'></div>
            </div>

            <Box
              title='Wissen'
              grade={
                grades?.find(grade => grade.title === "To know")?.grades || 0
              }
            />
            <Box
              title='Situationswahrnehmung und Analyse'
              grade={
                grades?.find(
                  grade => grade.title === "Situation perception and analysis"
                )?.grades || 0
              }
            />
            <Box
              title='Motivation'
              grade={
                grades?.find(grade => grade.title === "motivation")?.grades || 0
              }
            />
            <Box
              title='Handlungsoptionen'
              grade={
                grades?.find(grade => grade.title === "options for action")
                  ?.grades || 0
              }
            />
          </div>
          <CircleTriangle />
          <div className='competencyProfile__results__right'>
            <div className='arrow'>
              <span>Performanz</span>
              <div className='arrow-2way'></div>
            </div>

            <Box title='Action Planning and willingness to act' />
            <Box title='Acting in the situation' />
            <Box title='Analysis and evaluation' />
          </div>
        </div>

        <div className='competencyProfile__results competencyProfile__results-2'>
          <div className='competencyProfile__results__left competencyProfile__results__left-2'>
            <div className='arrow-1way arrow-up'></div>
          </div>
          <div className='competencyProfile__results__mid competencyProfile__results__mid-2'>
            6-Phasen-Entscheidungspyramide
          </div>
          <div className='competencyProfile__results__right competencyProfile__results__right-2'>
            <div className='arrow-1way arrow-up'></div>
          </div>
        </div>
        <div className='competencyProfile__message'>
          (Forschung) methodisch fundierte Praxis und Selbstreflexion
        </div>
      </div>
    </>
  );
};

const Box = ({ title, grade }) => {
  return (
    <div className='competencyProfile__box'>
      <span>{title}</span>
      {grade !== undefined && <span>Note: {grade}</span>}
    </div>
  );
};

const CircleTriangle = () => {
  return (
    <div className='competencyProfile__mid'>
      <h4>Evidenzbasierte Entscheidungsfindung</h4>
      <div className='competencyProfile__circle'>
        <img src={require("../../assets/triangle.png")} alt='' />
        <div className='competencyProfile__circle__triangleContent'>
          <div className='competencyProfile__circle__triangleContent__item'>
            Nachhaltigkeit
          </div>
          <div className='competencyProfile__circle__triangleContent__item'>
            Anwendung
          </div>
          <div className='competencyProfile__circle__triangleContent__item'>
            Implementation
          </div>
          <div className='competencyProfile__circle__triangleContent__item'>
            Lösungsfindung
          </div>
          <div className='competencyProfile__circle__triangleContent__item'>
            Information
          </div>
          <div className='competencyProfile__circle__triangleContent__item'>
            Konfrontation
          </div>
        </div>
        <div className='competencyProfile__circle__contemporay'>
          Gegenwartsbedeutung
        </div>
        <div className='competencyProfile__circle__significance'>
          Zukunftsbedeutung
        </div>
        <div className='competencyProfile__circle__exem'>Exemplare</div>

        {/* <div className='competencyProfile__triangle'>ss</div> */}
      </div>
    </div>
  );
};
export default CompetencyProfile;
