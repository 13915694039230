import React from 'react'
import RegisterAnalysisTable from '../../components/Common/RegisterAnalysisTable'
// import NewWindow from 'react-new-window'
import Navbar from '../../components/Navbar'
import ClassRegisterAnalysisTable from '../ClassRegisterAnalysis/Components/ClassRegisterAnalysisTable'
import { useLocation } from 'react-router'

const RegisterAnalysis = () => {

  const location = useLocation();
  let splittedLoc = location.pathname.split("/");
  let option = splittedLoc[splittedLoc.length - 1];

  return (
    <>
      <Navbar />
      <section className='registerAnalysis'>
        <div className='registerAnalysis__body'>
          {option === "analysis" ?
            <header className='registerAnalysis__body__header'>
              <button>AusbildungsPortfolio</button>
            </header>
            :
            <div>
              <input type='date' className='signInContainer__fields__input' />
            </div>
          }
          {option === "analysis" ?
            <RegisterAnalysisTable />
            :
            <div className='registerAnalysis__body__classAnalysisTable'>
              <ClassRegisterAnalysisTable />
            </div>
          }
        </div>
      </section>
    </>
  )
}

export default RegisterAnalysis