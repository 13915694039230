import React from "react";

const CInputWithLabel = ({
  style,
  label,
  onChange,
  id,
  name,
  required,
  placeholder,
  type,
  value,
}) => {
  return (
    <div className='cInputWithLabel' style={{ ...style }}>
      {label && <label htmlFor={id}>{label}</label>}
      <input
        type={type}
        id={id}
        name={name}
        required={required}
        onChange={onChange}
        placeholder={placeholder}
        value={value}
      />
    </div>
  );
};

export default CInputWithLabel;
