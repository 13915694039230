import React from "react";

const TopContent = ({ option }) => {
  if (option === "arthrosis")
    return (
      <div className='listingOptions__arthrosisTopContent'>
        <span>
          <b> Arthrosis competency test</b>
        </span>
        <div className='listingOptions__arthrosisTopContent__grade'>
          Achieved grade:5
        </div>
      </div>
    );

  return null;
};

export default TopContent;
