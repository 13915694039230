import React from 'react'

const CompetencyTestTable = () => {
  return (
    <section className='proofOfPractice__body__competencyTable'>
      <p className='proofOfPractice__body__competencyTable__heading'>Competency Tests</p>
      <table className='proofOfPractice__body__competencyTable__table'>
        <tbody className='proofOfPractice__body__competencyTable__table__tableBody'>
          <tr className='proofOfPractice__body__competencyTable__table__tableBody__row'>
            <td className='proofOfPractice__body__competencyTable__table__tableBody__row__details'>Date</td>
            <td className='proofOfPractice__body__competencyTable__table__tableBody__row__details'>Pain</td>
            <td className='proofOfPractice__body__competencyTable__table__tableBody__row__details'>Examination question 1</td>
            <td className='proofOfPractice__body__competencyTable__table__tableBody__row__details'>Note 1</td>
            <td className='proofOfPractice__body__competencyTable__table__tableBody__row__details'>
              <div className='proofOfPractice__body__competencyTable__table__tableBody__row__details__checkBox'>
                <input type="checkbox" />
                <label>Practice Instructor</label>
              </div>
            </td>
          </tr>
          <tr className='proofOfPractice__body__competencyTable__table__tableBody__row'>
            <td className='proofOfPractice__body__competencyTable__table__tableBody__row__details'>Date</td>
            <td className='proofOfPractice__body__competencyTable__table__tableBody__row__details'>Nourishment</td>
            <td className='proofOfPractice__body__competencyTable__table__tableBody__row__details'>Examination question 2</td>
            <td className='proofOfPractice__body__competencyTable__table__tableBody__row__details'>Grade 2</td>
            <td className='proofOfPractice__body__competencyTable__table__tableBody__row__details'>
              <div className='proofOfPractice__body__competencyTable__table__tableBody__row__details__checkBox'>
                <input type="checkbox" />
                <label>Practice Instructor</label>
              </div>
            </td>
          </tr>
          <tr className='proofOfPractice__body__competencyTable__table__tableBody__row'>
            <td className='proofOfPractice__body__competencyTable__table__tableBody__row__details'>Date</td>
            <td className='proofOfPractice__body__competencyTable__table__tableBody__row__details'>Mobility</td>
            <td className='proofOfPractice__body__competencyTable__table__tableBody__row__details'>Examination question 3</td>
            <td className='proofOfPractice__body__competencyTable__table__tableBody__row__details'>Note 3</td>
            <td className='proofOfPractice__body__competencyTable__table__tableBody__row__details'>
              <div className='proofOfPractice__body__competencyTable__table__tableBody__row__details__checkBox'>
                <input type="checkbox" />
                <label>Practice Instructor</label>
              </div>
            </td>
          </tr>
          <tr className='proofOfPractice__body__competencyTable__table__tableBody__row'>
            <td className='proofOfPractice__body__competencyTable__table__tableBody__row__details'>Date</td>
            <td className='proofOfPractice__body__competencyTable__table__tableBody__row__details'>Personal hygiene</td>
            <td className='proofOfPractice__body__competencyTable__table__tableBody__row__details'>Examination question 4</td>
            <td className='proofOfPractice__body__competencyTable__table__tableBody__row__details'>Grade 4</td>
            <td className='proofOfPractice__body__competencyTable__table__tableBody__row__details'>
              <div className='proofOfPractice__body__competencyTable__table__tableBody__row__details__checkBox'>
                <input type="checkbox" />
                <label>Practice Instructor</label>
              </div>
            </td>
          </tr>
          <tr className='proofOfPractice__body__competencyTable__table__tableBody__row'>
            <td className='proofOfPractice__body__competencyTable__table__tableBody__row__details'></td>
            <td className='proofOfPractice__body__competencyTable__table__tableBody__row__details'></td>
            <td className='proofOfPractice__body__competencyTable__table__tableBody__row__details'></td>
            <td className='proofOfPractice__body__competencyTable__table__tableBody__row__details'>Overall grade</td>
            <td className='proofOfPractice__body__competencyTable__table__tableBody__row__details'>
            </td>
          </tr>
        </tbody>
      </table>
    </section>
  )
}

export default CompetencyTestTable