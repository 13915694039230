import React from 'react'

function TrainingTable() {
  return (
    <div className='proofOfPractice__body__tableBox'>
      <table className='proofOfPractice__body__tableBox__table'>
        <thead className='proofOfPractice__body__tableBox__table__tableHead'>
          <tr className='proofOfPractice__body__tableBox__table__tableHead__row'>
            <th className='proofOfPractice__body__tableBox__table__tableHead__row__head'>Provider of the <br />practical training:</th>
            <th className='proofOfPractice__body__tableBox__table__tableHead__row__head'>Surname: <br /> First name:</th>
            <th className='proofOfPractice__body__tableBox__table__tableHead__row__head'>Course name:</th>
            <th className='proofOfPractice__body__tableBox__table__tableHead__row__head'>Total Hourly Rate<br /> /400h</th>
            <th className='proofOfPractice__body__tableBox__table__tableHead__row__head'>Current guidence time: <br />307/2400min <br /> (40h) </th>
          </tr>
        </thead>
        <tbody className='proofOfPractice__body__tableBox__table__tableBody'>
          <tr className='proofOfPractice__body__tableBox__table__tableBody__row'>
            <td className='proofOfPractice__body__tableBox__table__tableBody__row__details'>
              Practical use<br /> from:_____ <br /> to:______
            </td>
            <td className='proofOfPractice__body__tableBox__table__tableBody__row__details' colspan={4}>
              <div style={{ display: "flex", justifyContent: "flex-start  " }}>
                <div className='proofOfPractice__body__tableBox__table__tableBody__row__details__descBox__orientation' >
                  <div className='proofOfPractice__body__tableBox__table__tableBody__row__details__descBox__orientation__heading'>
                    <p>Orientation mission</p>
                  </div>
                  <div className='proofOfPractice__body__tableBox__table__tableBody__row__details__descBox__orientation__checkBox'>
                    <input type='checkbox' />
                    <label>Inpatient accute care</label>
                  </div>
                  <div className='proofOfPractice__body__tableBox__table__tableBody__row__details__descBox__orientation__checkBox'>
                    <input type='checkbox' />
                    <label>Inpatient long-term care</label>
                  </div>
                  <div className='proofOfPractice__body__tableBox__table__tableBody__row__details__descBox__orientation__checkBox'>
                    <input type='checkbox' />
                    <label>Outpatient long-term care</label>
                  </div>
                </div>
                <div className='proofOfPractice__body__tableBox__table__tableBody__row__details__descBox__orientation'>
                  <div className='proofOfPractice__body__tableBox__table__tableBody__row__details__descBox__orientation__heading'>
                    <p>Compulsory and in-depth assignment</p>
                  </div>
                  <div className='proofOfPractice__body__tableBox__table__tableBody__row__details__descBox__orientation__checkBox'>
                    <input type='checkbox' />
                    <label>Pediatric care</label>
                  </div>
                  <div className='proofOfPractice__body__tableBox__table__tableBody__row__details__descBox__orientation__checkBox'>
                    <input type='checkbox' />
                    <label>Inpatient accute care</label>
                  </div>
                  <div className='proofOfPractice__body__tableBox__table__tableBody__row__details__descBox__orientation__checkBox'>
                    <input type='checkbox' />
                    <label>Outpatient long-term care</label>
                  </div>
                  <div className='proofOfPractice__body__tableBox__table__tableBody__row__details__descBox__orientation__checkBox'>
                    <input type='checkbox' />
                    <label>Outpatient short-. long-term care</label>
                  </div>
                </div>
                <div className='proofOfPractice__body__tableBox__table__tableBody__row__details__descBox__orientation'>
                  <div className='proofOfPractice__body__tableBox__table__tableBody__row__details__descBox__orientation__heading'>
                    <p>(3rd third of training)</p>
                  </div>
                  <div className='proofOfPractice__body__tableBox__table__tableBody__row__details__descBox__orientation__checkBox'>
                    <input type='checkbox' />
                    <label>Care Advice</label>
                  </div>
                  <div className='proofOfPractice__body__tableBox__table__tableBody__row__details__descBox__orientation__checkBox'>
                    <input type='checkbox' />
                    <label>Rehabilitation</label>
                  </div>
                  <div className='proofOfPractice__body__tableBox__table__tableBody__row__details__descBox__orientation__checkBox'>
                    <input type='checkbox' />
                    <label>Pallative</label>
                  </div>
                  <div className='proofOfPractice__body__tableBox__table__tableBody__row__details__descBox__orientation__checkBox'>
                    <input type='checkbox' />
                    <label>Pediatric care</label>
                  </div>
                  <div className='proofOfPractice__body__tableBox__table__tableBody__row__details__descBox__orientation__checkBox'>
                    <input type='checkbox' />
                    <label>Other</label>
                  </div>
                </div>
              </div>
              <div className='proofOfPractice__body__tableBox__table__tableBody__row__absenteeism'>
                <p><strong>Total absenteeism:_________hours</strong> (from proof of employment) Max <span className='proofOfPractice__body__tableBox__table__tableBody__row__absenteeism__greater'>10%</span></p>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <div className='proofOfPractice__body__tableBox__explanation'>
        <strong>Legend/Explanation:</strong>
        <p>- The trainee keeps the training certificate for each practical phase and submits it to the practical instructions for signature and stamp confirmation.</p>
        <p>- Subsequent sheets must be arranged according to the number of sheets and overview and put together by trainees.</p>
        <p>- Trainees must submit the completed training certificate to the school after each practical phase.</p>
        <p>- A copy remains in the training folder.</p>
        <p>- 10% of the deployment time must be proven as instruction time.</p>
      </div>
    </div>
  )
}

export default TrainingTable;