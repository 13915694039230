import React from 'react';
import Table from '../../components/Common/Table';
import Navbar from '../../components/Navbar';
import { useLocation } from 'react-router';

export default function LearningGoals() {
    const location = useLocation();
    const {data} = location.state;
    let splittedLoc = location.pathname.split("/");
    let option = splittedLoc[splittedLoc.length - 1];
    console.warn(data)
  return (
    <>
        <Navbar />
        <div className='learningGoals'>
        <div className='learningGoals__top-content'>
                <h2>{option}</h2>
                <h2>{data}</h2>
        </div>
        <div className='learningGoals__table'>
            <Table/>
        </div>
        </div>
    </>
  )
}