import React from 'react'
import CareFormCheckBox from '../../components/Common/CareFormCheckBox'
import Navbar from '../../components/Navbar'

const CareForm = () => {
  return (
    <>
      <Navbar />
      <section className='careForm'>
        <header className='careForm__header'>
          <h2 className='careForm__header__heading'>Inpatient accute care</h2>
          <div className='careForm__header2'>
            <h2 className='careForm__header2__heading2'>Area of competence</h2>
            <select className='careForm__header2__select'>
              <option>I</option>
              <option>II</option>
              <option>III</option>
              <option>IV</option>
              <option>V</option>
            </select>
            <select className='careForm__header2__select'>
              <option>1</option>
              <option>2</option>
              <option>3</option>
              <option>4</option>
              <option>5</option>
              <option>6</option>
            </select>
          </div>
        </header>
        <div className='careForm__body'>
          <CareFormCheckBox text={"a"} />
          <CareFormCheckBox text={"b"} />
          <CareFormCheckBox text={"c"} />
          <CareFormCheckBox text={"d"} />
          <CareFormCheckBox text={"e"} />
          <CareFormCheckBox text={"f"} />
          <CareFormCheckBox text={"g"} />
          <CareFormCheckBox text={"h"} />
          <CareFormCheckBox text={"i"} />
          <CareFormCheckBox text={"j"} />
        </div>
      </section>
    </>

  )
}

export default CareForm